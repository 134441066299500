import React, { useState, Fragment, useRef, useEffect } from "react";
import _ from "lodash";
import Loader from "react-js-loader";
import { NavLink, useNavigate } from "react-router-dom";
import candidate from "../../Assets/candidate.svg";
import completed from "../../Assets/completed.svg";
import interviewers from "../../Assets/interviewers.svg";
import registered from "../../Assets/registered.svg";
import qoute from "../../Assets/quote.svg";
import rala_portal_logo from "../../Assets/rala_infotech_color.svg";
import { Form, Button, InputGroup, Alert } from "react-bootstrap";
import "./LoginScreen.css";
import { HiUserGroup, HiUser, HiUserAdd } from "react-icons/hi";
import {
  SUPER_ADMIN,
  SUB_ADMIN,
  INTERVIEWER,
  EMPLOYER_SUPER_ADMIN,
  EMPLOYER_SUB_ADMIN,
} from "./../../common/roles";
import {
  API_URL,
  USER_STATUS_APPROVE,
  addAuthUserToSessionStorage,
} from "../../common/constant";
import OTPInput from "react-otp-input";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Toast } from "primereact/toast";

const LoginScreen = () => {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [emailError, setIsEmailError] = useState("");
  const [passwordError, setIsPasswordError] = useState("");
  const [userTypeError, setIsUserTypeError] = useState("");
  const [loginWithPassword, setLoginWithPassword] = useState(true);
  const [mobileNumber, setMobileNumber] = useState("");
  const [user, setUserDetails] = useState({
    email: "",
    password: "",
    userType: "",
    rememberPassword: false,
  });
  const toast = useRef(null);
  const [isSendOtp, setIsSendOtp] = useState(false);
  const [otp, setOtp] = useState("0000");
  const [otpSendDate, setOtpSendDate] = useState(new Date());
  const [passwordType, setPasswordType] = useState("password");
  const [failedAttempts, setFailedAttempts] = useState(0);
  const [websiteStatistics, setWebsiteStatistics] = useState({
    registeredCandidates: 0,
    completedInterviews: 0,
    registeredCompanies: 0,
    interviewers: 0,
    activeTestimonial: null,
    testimonials: []
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "rememberPassword") {
      setUserDetails({
        ...user,
        [name]: e.target.checked
      });
    } else {
      setUserDetails({
        ...user,
        [name]: value,
      });
    }
  };
  const changeMobileNumberHandler = (e) => {
    const inputText = e.target.value.replace(/\D/g, "");
    const maxLength = 10;
    const truncatedText = inputText.slice(0, maxLength);
    setMobileNumber(truncatedText);
    setIsSendOtp(false);
    setOtpSendDate(new Date());
    setOtp("0000");
  };
  const changeOTPHandler = (e, index) => {
    var otps = otp;
    var otpsArray = otps.split("");
    otpsArray[index] = e.target.value;
    otps = otpsArray.join("");
    setOtp(otps);
  };
  const validateForm = () => {
    const emailregex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (
      _.isUndefined(user.email) ||
      _.isNull(user.email) ||
      user.email === "" ||
      user.email.trim() === ""
    ) {
      setIsEmailError("Email Address is required!");
    } else if (
      !_.isUndefined(user.email) &&
      !_.isNull(user.email) &&
      user.email !== "" &&
      user.email.trim() !== "" &&
      !emailregex.test(user.email)
    ) {
      setIsEmailError("Please enter a valid email address!");
    } else if (
      !_.isUndefined(user.email) &&
      !_.isNull(user.email) &&
      user.email !== "" &&
      user.email.trim() !== "" &&
      (user.email.trim().length < 15 || user.email.trim().length > 150)
    ) {
      setIsEmailError("Email address must be between 15 to 150 characters!");
    } else {
      setIsEmailError("");
    }
    if (
      _.isUndefined(user.password) ||
      _.isNull(user.password) ||
      user.password === "" ||
      user.password.trim() === ""
    ) {
      setIsPasswordError("Password is required!");
    } else if (
      !_.isUndefined(user.password) &&
      !_.isNull(user.password) &&
      user.password !== "" &&
      user.password.trim() !== "" &&
      (user.password.trim().length < 6 || user.password.trim().length > 25)
    ) {
      setIsPasswordError("Password must be between 6 to 25 characters!");
    } else {
      setIsPasswordError("");
    }

    const uppercasePattern = /[A-Z]/;
    const lowercasePattern = /[a-z]/;
    const numericPattern = /[0-9]/;
    const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;

    if (!uppercasePattern.test(user.password)) {
      setIsPasswordError(
        "Password must contain at least one uppercase letter, one lowercase letter, one numeric character, and one special character."
      );
      return false;
    }

    if (!lowercasePattern.test(user.password)) {
      setIsPasswordError(
        "Password must contain at least one uppercase letter, one lowercase letter, one numeric character, and one special character."
      );
      return false;
    }

    if (!numericPattern.test(user.password)) {
      setIsPasswordError(
        "Password must contain at least one uppercase letter, one lowercase letter, one numeric character, and one special character."
      );
      return false;
    }

    if (!specialCharPattern.test(user.password)) {
      setIsPasswordError(
        "Password must contain at least one uppercase letter, one lowercase letter, one numeric character, and one special character."
      );
      return false;
    }

    if (
      _.isUndefined(user.userType) ||
      _.isNull(user.userType) ||
      user.userType === "" ||
      user.userType.trim() === ""
    ) {
      setIsUserTypeError("User Type is required!");
    } else {
      setIsUserTypeError("");
    }
  };

  const checkedValidateForm = () => {
    const emailregex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (
      _.isUndefined(user.email) ||
      _.isNull(user.email) ||
      user.email === "" ||
      user.email.trim() === ""
    ) {
      return false;
    } else if (
      !_.isUndefined(user.email) &&
      !_.isNull(user.email) &&
      user.email !== "" &&
      user.email.trim() !== "" &&
      !emailregex.test(user.email)
    ) {
      return false;
    } else if (
      !_.isUndefined(user.email) &&
      !_.isNull(user.email) &&
      user.email !== "" &&
      user.email.trim() !== "" &&
      (user.email.trim().length < 15 || user.email.trim().length > 150)
    ) {
      return false;
    }
    if (
      _.isUndefined(user.password) ||
      _.isNull(user.password) ||
      user.password === "" ||
      user.password.trim() === ""
    ) {
      return false;
    } else if (
      !_.isUndefined(user.password) &&
      !_.isNull(user.password) &&
      user.password !== "" &&
      user.password.trim() !== "" &&
      (user.password.trim().length < 6 || user.password.trim().length > 25)
    ) {
      return false;
    }

    const uppercasePattern = /[A-Z]/;
    const lowercasePattern = /[a-z]/;
    const numericPattern = /[0-9]/;
    const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;

    if (!uppercasePattern.test(user.password)) {
      return false;
    }

    if (!lowercasePattern.test(user.password)) {
      return false;
    }

    if (!numericPattern.test(user.password)) {
      return false;
    }

    if (!specialCharPattern.test(user.password)) {
      return false;
    }

    if (
      _.isUndefined(user.userType) ||
      _.isNull(user.userType) ||
      user.userType === "" ||
      user.userType.trim() === ""
    ) {
      return false;
    }
    return true;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    validateForm();
    if (checkedValidateForm()) {
      setIsSubmit(true);
      let params = {
        email: user.email,
        password: user.password,
        account_type: user.userType,
      };
      fetch(`${API_URL}api/login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      })
        .then((response) => response.json())
        .then((response) => {
          setIsSubmit(false);
          if (
            !_.isUndefined(response) &&
            !_.isNull(response) &&
            !_.isUndefined(response.user) &&
            !_.isNull(response.user) &&
            !_.isEmpty(response.user) &&
            !_.isUndefined(response.status) &&
            !_.isNull(response.status) &&
            response.status === 200 &&
            !_.isUndefined(response.message) &&
            !_.isNull(response.message) &&
            response.message !== ""
          ) {
            if (
              response.user.user_status === USER_STATUS_APPROVE ||
              response.user.account_type === SUPER_ADMIN ||
              (response.user.account_type === EMPLOYER_SUPER_ADMIN &&
                response.user.company_id !== "") ||
              response.user.account_type === EMPLOYER_SUB_ADMIN
            ) {
              addAuthUserToSessionStorage(response.user);
              toast.current.show({
                severity: "success",
                summary: "Success",
                detail: response.message,
                life: 3000,
              });
              if (user.rememberPassword) {
                localStorage.setItem('rememberMeData', JSON.stringify(user));
              } else {
                localStorage.removeItem('rememberMeData');
              }
              if (
                !_.isUndefined(response.user.account_type) &&
                !_.isNull(response.user.account_type) &&
                response.user.account_type === INTERVIEWER
              ) {
                setTimeout(() => {
                  navigate("/interviewer-dashboard", { replace: true });
                }, 1000);
              } else if (
                !_.isUndefined(response.user.account_type) &&
                !_.isNull(response.user.account_type)
              ) {
                setTimeout(() => {
                  navigate("/dashboard", { replace: true });
                }, 1000);
              }
            } else {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail:
                  "Your profile is not approved. Please contact administrator.",
                life: 3000,
              });
            }
          } else if (
            !_.isUndefined(response) &&
            !_.isNull(response) &&
            !_.isUndefined(response.message) &&
            !_.isNull(response.message) &&
            response.message !== ""
          ) {
            setFailedAttempts((prev) => prev + 1);
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: response.message,
              life: 3000,
            });
          }
        })
        .catch((error) => {
          if (
            !_.isUndefined(error) &&
            !_.isNull(error) &&
            !_.isUndefined(error.message) &&
            !_.isNull(error.message) &&
            error.message !== ""
          ) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    }
  };
  const getRole = (role) => {
    return role === SUPER_ADMIN
      ? "Rala Super Admin"
      : role === SUB_ADMIN
        ? "Rala Sub Admin"
        : role.replaceAll("_", " ").replace(/\w+/g, function (w) {
          return w[0].toUpperCase() + w.slice(1).toLowerCase();
        });
  };

  const sendOtp = () => {
    setIsSubmit(true);
    fetch(`${API_URL}api/sendOtp`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mobile_number: mobileNumber,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status == 200) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: response.message,
            life: 3000,
          });
          setIsSendOtp(true);
          setOtpSendDate(new Date());
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: response.message,
            life: 3000,
          });
          setIsSendOtp(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (
          !_.isUndefined(error) &&
          !_.isNull(error) &&
          !_.isUndefined(error.message) &&
          !_.isNull(error.message) &&
          error.message !== ""
        ) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error.message,
            life: 3000,
          });
          setIsSendOtp(false);
          setIsSendOtp(false);
        }
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const verifyOtp = () => {
    const timeDifference = new Date().getTime() - otpSendDate.getTime();
    const secondsDifference = timeDifference / 1000;
    if (secondsDifference < 600) {
      setIsSubmit(true);
      fetch(`${API_URL}api/verifyOtp`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile_number: mobileNumber,
          otp: otp,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (
            !_.isUndefined(response) &&
            !_.isNull(response) &&
            !_.isUndefined(response.user) &&
            !_.isNull(response.user) &&
            !_.isEmpty(response.user) &&
            !_.isUndefined(response.status) &&
            !_.isNull(response.status) &&
            response.status === 200 &&
            !_.isUndefined(response.message) &&
            !_.isNull(response.message) &&
            response.message !== ""
          ) {
            if (
              response.user.user_status === USER_STATUS_APPROVE ||
              response.user.account_type == SUPER_ADMIN
            ) {
              addAuthUserToSessionStorage(response.user);
              setIsSendOtp(false);
              if (
                !_.isUndefined(response.user.account_type) &&
                !_.isNull(response.user.account_type) &&
                response.user.account_type === INTERVIEWER
              ) {
                navigate("/interviewer-dashboard", { replace: true });
              } else if (
                !_.isUndefined(response.user.account_type) &&
                !_.isNull(response.user.account_type)
              ) {
                navigate("/dashboard", { replace: true });
              }
            } else {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail:
                  "Your profile is not approved. Please contact administrator.",
                life: 3000,
              });
            }
          } else if (
            !_.isUndefined(response) &&
            !_.isNull(response) &&
            !_.isUndefined(response.message) &&
            !_.isNull(response.message) &&
            response.message !== ""
          ) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: response.message,
              life: 3000,
            });
          }
          setIsSubmit(false);
        })
        .catch((error) => {
          if (
            !_.isUndefined(error) &&
            !_.isNull(error) &&
            !_.isUndefined(error.message) &&
            !_.isNull(error.message) &&
            error.message !== ""
          ) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: error.message,
              life: 3000,
            });
          }
          setIsSubmit(false);
        });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "OTP is expired",
        life: 3000,
      });
    }
  };

  const getWebsiteStatistics = () => {
    fetch(`${API_URL}api/getWebsiteStatistics`)
      .then((response) => response.json())
      .then((response) => {
        if (response.data) {
          setWebsiteStatistics({
            registeredCandidates: response.data.registeredCandidates,
            completedInterviews: response.data.completedInterviews,
            registeredCompanies: response.data.registeredCompanies,
            interviewers: response.data.interviewers,
            activeTestimonial: response.data.testimonials.length > 0 ? response.data.testimonials[0] : [],
            testimonials: response.data.testimonials
          })
        }
      })
      .catch((error) => {
        setWebsiteStatistics([])
      });
  }

  const nextTestimonial = (index) => {
    const nextIndex = index < websiteStatistics.testimonials.length ? index : 0;
    setWebsiteStatistics((prevStats) => ({
      ...websiteStatistics,
      activeTestimonial: websiteStatistics.testimonials[nextIndex],
    }));
  };

  useEffect(() => {
    let rememberMeData = localStorage.getItem("rememberMeData");
    if (rememberMeData) {
      rememberMeData = JSON.parse(rememberMeData);
      setUserDetails({
        ...user,
        email: rememberMeData.email,
        password: rememberMeData.password,
        userType: rememberMeData.userType,
        rememberPassword: rememberMeData.rememberPassword
      })
      console.log(rememberMeData.userType)
      setTimeout(() => {
        console.log(user)
      }, 3000)
    }
    if (websiteStatistics.activeTestimonial === null) {
      getWebsiteStatistics();
    }
  }, []);

  return (
    <Fragment>
      <Toast ref={toast} />
      {isSubmit && (
        <div className="spinner-loader">
          <Loader
            type="spinner-circle"
            bgColor="#2F2F74"
            color="#2F2F74"
            size={100}
          />
        </div>
      )}
      <div className="d-flex align-items-md-center h-100">
        <div className="container-fluid h-100">
          <div className="row d-flex h-100">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 text-center d-none d-lg-block dynamic_hiring">
              {/* <img src={dynamic_hiring} alt="Faster Hiring" className="w-75" /> */}
              <div className="d-flex justify-content-center flex-column h-100 px-4">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-start pt-5 tilt">
                    <div className="icon-wrap">
                      <img src={candidate} alt="candidate-icon" />
                    </div>
                    <div className="fw-bold display-5 text-white text-uppercase mt-4">
                      {websiteStatistics.registeredCandidates}+
                    </div>
                    <div className="fw-bold text-uppercase text_highlite mt-3">
                      CANDIDATES REGISTERED
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-start pt-5 tilt">
                    <div className="icon-wrap">
                      <img src={completed} alt="completed-icon" />
                    </div>
                    <div className="fw-bold display-5 text-white text-uppercase mt-4">
                      {websiteStatistics.completedInterviews}+
                    </div>
                    <div className="fw-bold text-uppercase text_highlite mt-3">
                      COMPLETED INTERVIEWS
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-start pt-5 tilt">
                    <div className="icon-wrap">
                      <img src={registered} alt="registered-icon" />
                    </div>
                    <div className="fw-bold display-5 text-white text-uppercase mt-4">
                      {websiteStatistics.registeredCompanies}+
                    </div>
                    <div className="fw-bold text-uppercase text_highlite mt-3">
                      COMPANIES REGISTERED
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-start pt-5 tilt">
                    <div className="icon-wrap">
                      <img src={interviewers} alt="interviewers-icon" />
                    </div>
                    <div className="fw-bold display-5 text-white text-uppercase mt-4">
                      {websiteStatistics.interviewers}+
                    </div>
                    <div className="fw-bold text-uppercase text_highlite mt-3">
                      INTERVIEWERS
                    </div>
                  </div>
                </div>
                <div className="row testimonials pt-2">
                  {
                    websiteStatistics && websiteStatistics.activeTestimonial && (
                      <>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-16 text-start pt-5">
                          <img src={qoute} className="mb-4" alt="Quote" />
                          <p className="text_highlite">{websiteStatistics.activeTestimonial.message}</p>
                          <div className="text-white text-uppercase fw-bold">{websiteStatistics.activeTestimonial.author}</div>
                          <div className="text_highlite_t">{websiteStatistics.activeTestimonial.designation}</div>
                        </div>
                        <div className="carousel-bullets mt-3">
                          {
                            websiteStatistics.testimonials.map((item, index) => {
                              return <div onClick={() => nextTestimonial(index)} className={item._id === websiteStatistics.activeTestimonial._id ? 'bullet active' : 'bullet'}></div>
                            })
                          }
                          {/* <div className="bullet active"></div>
                          <div className="bullet"></div>
                          <div className="bullet"></div> */}
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 m-auto">
              <div className="row justify-content-md-center">
                <div className="col-sm-12 col-md-8 col-lg-9 col-xl-7 col-xxl-6">
                  <div className="d-flex justify-content-center logo mb-5">
                    <img src={rala_portal_logo} alt="Rala Portal" />
                  </div>
                  <div className="row mt-4">
                    <div className="col-6 text-center">
                      <Button
                        className={
                          loginWithPassword
                            ? " logn-tab-active-button"
                            : " logn-tab-inactive-button"
                        }
                        onClick={() => setLoginWithPassword(true)}
                      >
                        Login with password
                      </Button>
                    </div>
                    <div className="col-6 text-center">
                      <Button
                        className={
                          loginWithPassword
                            ? " logn-tab-inactive-button"
                            : " logn-tab-active-button"
                        }
                        onClick={() => setLoginWithPassword(false)}
                      >
                        Login with OTP
                      </Button>
                    </div>
                  </div>
                  {loginWithPassword ? (
                    <Form className="mt-4" autoComplete="off">
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="email"
                          id="email"
                          name="email"
                          value={user.email}
                          placeholder="Email Address"
                          onChange={changeHandler}
                          maxLength={150}
                          autoComplete="off"
                        />
                        {!_.isUndefined(emailError) &&
                          !_.isNull(emailError) &&
                          emailError !== "" && (
                            <Alert variant="danger" className="mt-2">
                              {emailError}
                            </Alert>
                          )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <InputGroup className="mb-3">
                          <Form.Control
                            type={passwordType}
                            id="password"
                            name="password"
                            value={user.password}
                            placeholder="Password"
                            onChange={changeHandler}
                            maxLength={25}
                            autoComplete="off"
                          />
                          <InputGroup.Text
                            onClick={() =>
                              setPasswordType(
                                passwordType === "password" ? "text" : "password"
                              )
                            }
                            id="basic-addon1"
                          >
                            {passwordType == "password" ? (
                              <FaEyeSlash />
                            ) : (
                              <FaEye />
                            )}{" "}
                          </InputGroup.Text>
                        </InputGroup>
                        {!_.isUndefined(passwordError) &&
                          !_.isNull(passwordError) &&
                          passwordError !== "" && (
                            <Alert variant="danger" className="mt-2">
                              {passwordError}
                            </Alert>
                          )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Select
                          aria-label="Choose User Type"
                          id="userType"
                          name="userType"
                          placeholder="Choose User Type"
                          onChange={changeHandler}
                        >
                          <option>Choose User Type</option>
                          <option selected={user.userType === "INTERVIEWER"} value={INTERVIEWER}>
                            {getRole(INTERVIEWER)}
                          </option>
                          <option selected={user.userType === "EMPLOYER_SUB_ADMIN"} value={EMPLOYER_SUB_ADMIN}>
                            {getRole(EMPLOYER_SUB_ADMIN)}
                          </option>
                          <option selected={user.userType === "EMPLOYER_SUPER_ADMIN"} value={EMPLOYER_SUPER_ADMIN}>
                            {getRole(EMPLOYER_SUPER_ADMIN)}
                          </option>
                          <option selected={user.userType === "SUB_ADMIN"} value={SUB_ADMIN}>
                            {getRole(SUB_ADMIN)}
                          </option>
                          <option selected={user.userType === "SUPER_ADMIN"} value={SUPER_ADMIN}>
                            {getRole(SUPER_ADMIN)}
                          </option>
                        </Form.Select>
                        {!_.isUndefined(userTypeError) &&
                          !_.isNull(userTypeError) &&
                          userTypeError !== "" && (
                            <Alert variant="danger" className="mt-2">
                              {userTypeError}
                            </Alert>
                          )}
                      </Form.Group>
                      <div className="row mb-3">
                        <div className="col-7">
                          <Form.Group>
                            <Form.Check
                              inline
                              label="Remember password"
                              name="rememberPassword"
                              checked={user.rememberPassword}
                              onChange={changeHandler}
                              type="checkbox"
                              id="rememberPassword"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-5 text-end">
                          <NavLink
                            to="/forget-password"
                            className="forget-password"
                          >
                            Forget password?
                          </NavLink>
                        </div>
                      </div>
                      <Form.Group className="mb-3">
                        <Button
                          type="submit"
                          className="btn btn-primary w-100"
                          onClick={submitHandler}
                        >
                          Login
                        </Button>
                      </Form.Group>
                    </Form>
                  ) : (
                    <Form className="mt-4" autoComplete="off">
                      <Form.Group className="mb-4">
                        <InputGroup>
                          <Form.Control
                            aria-describedby="mobileNumber"
                            type="number"
                            id="mobileNumber"
                            name="mobileNumber"
                            placeholder="Mobile Number"
                            value={mobileNumber}
                            onChange={changeMobileNumberHandler}
                            maxLength={10}
                            autoComplete="off"
                          />
                          <Button
                            variant="outline-secondary"
                            className="logn-tab-active-button"
                            onClick={sendOtp}
                          >
                            Send OTP
                          </Button>
                        </InputGroup>
                      </Form.Group>
                      <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        containerStyle="gap-3"
                        renderInput={(props) => (
                          <input
                            {...props}
                            className="form-control"
                            style={{
                              width: "25%",
                              textAlign: "center",
                              padding: "5px 14px",
                            }}
                          />
                        )}
                      />
                      <div className="row mb-3">
                        <div className="col-12 text-end">
                          <Button
                            variant="link"
                            className="resendOTP"
                            onClick={sendOtp}
                          >
                            Resend OTP
                          </Button>
                        </div>
                      </div>
                      <Form.Group className="mb-3">
                        {isSendOtp &&
                          mobileNumber.length === 10 &&
                          otp.length === 4 &&
                          otp !== "0000" && (
                            <Button
                              className="login-form-submit-button"
                              onClick={verifyOtp}
                            >
                              Verify OTP
                            </Button>
                          )}
                      </Form.Group>
                    </Form>
                  )}
                  <div className="row">
                    <div className="col-12 text-center">
                      <p className="dontHaveAnAccount">
                        Don't have an account ?
                      </p>
                    </div>
                    <div className="col-12 text-center">
                      <p className="signUpAs">Sign up as</p>
                    </div>
                    <div className="col-12 text-center">
                      <NavLink
                        to="/create-administrator-account"
                        className="create-account-link"
                      >
                        <div className="create-account-icon">
                          <HiUserAdd size={24} />
                        </div>
                        Administrator
                      </NavLink>
                      <NavLink
                        to="/create-interviewer-account"
                        className="create-account-link mx-3 mx-md-5 mx-lg-5"
                      >
                        <div className="create-account-icon">
                          <HiUser size={24} />
                        </div>
                        Interviewer
                      </NavLink>
                      <NavLink
                        to="/create-employer-account"
                        className="create-account-link"
                      >
                        <div className="create-account-icon">
                          <HiUserGroup size={24} />
                        </div>
                        Employer
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment >
  );
};
export default LoginScreen;
