
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import Loader from "react-js-loader";
import { API_URL } from '../../common/constant';
import { Button, Form } from 'react-bootstrap';
import { Toast } from "primereact/toast";
import "./CodingScreen.css";

const CodingScreen = () => {

    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [interviewData, setInterviewData] = useState(null);
    const [interviewQuestions, setInterviewQuestions] = useState(null);
    const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false)
    const [isError, setIsError] = useState(false)
    const [requestData, setRequestData] = useState({
        code_by_candidate: "",
        code_output_by_candidate: ""
    });
    const [reviewData, setReviewData] = useState({
        interview_feedback: '',
        interview_rating: 0
    })
    const toast = useRef(null);
    useEffect(() => {
        // const fetchData = () => {
        //     getInterviewDetails(params.id);
        // };
        // fetchData();
        // const intervalId = setInterval(fetchData, 2000);
        // return () => clearInterval(intervalId);
        getInterviewDetails(params.id);
    }, [params.id]);

    const getInterviewDetails = (id) => {
        fetch(`${API_URL}api/getCodingQuestion/${id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data)) {
                    if (response.data.code_by_candidate) {
                        setIsFeedbackSubmitted(true)
                        setReviewData({
                            interview_feedback: response.data.interview_feedback,
                            interview_rating: response.data.interview_rating
                        })
                    } else {
                        setTimeout(() => {
                            getInterviewDetails(id)
                        }, 2000);
                        setRequestData({
                            code_by_candidate: "import java.util.*;\n\npublic class Main {\n    public static void main(String[] args) {\n      System.out.println(\"Hello, World !\");\n  }\n}",
                            code_output_by_candidate: ""
                        })
                    }
                    setInterviewQuestions(response.data.coding_questions);
                    setInterviewData(response.data)
                } else {
                    setIsError(true)
                }
            })
            .catch((err) => {
                setInterviewQuestions({});
                setIsError(true)
            });
    }

    const saveCode = () => {
        if (requestData.code_by_candidate) {
            setIsLoading(true)
            fetch(`${API_URL}api/submtCodeTest/${params.id}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            })
                .then((response) => response.json())
                .then((response) => {
                    setIsLoading(false)
                    if (!_.isUndefined(response) && !_.isNull(response) && response.success) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                        getInterviewDetails(params.id);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
                });
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Plase write your code first.', life: 3000 });
        }
    }

    const submitCodingFeedback = (e) => {
        e.preventDefault();
        setIsLoading(true)
        fetch(`${API_URL}api/submitCodingFeedback/${params.id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(reviewData)
        })
            .then((response) => response.json())
            .then((response) => {
                setIsLoading(false)
                if (!_.isUndefined(response) && !_.isNull(response) && response.success) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    getInterviewDetails(params.id);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                }
            })
            .catch((err) => {
                setIsLoading(false)
                toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
            });
    }

    useEffect(() => {
        const handleMessage = (e) => {
            if (e.data && e.data.language) {
                // if (e.data.result && e.data.result.success) {
                //     setRequestData({
                //         code_by_candidate: e.data.files[0].content,
                //         code_output_by_candidate: e.data.result.output
                //     })
                // }
                if (e.data.files && e.data.files.length > 0) {
                    setRequestData({
                        code_by_candidate: e.data.files[0].content,
                        code_output_by_candidate: e.data.result ? e.data.result.output : ""
                    })
                }
            }
        };
        // Attach the event listener when the component mounts
        window.addEventListener('message', handleMessage);
        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const reviewHandleSubmit = (e) => {
        const { name, value } = e.target;
        setReviewData({
            ...reviewData,
            [name]: value
        })
    }

    return (
        <Fragment>
            <Toast ref={toast} />
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            {
                isError ? (
                    <div className="container d-flex align-items-center justify-content-center vh-100">
                        <div className="text-center">
                            <h1 className="display-4 text-danger">Error</h1>
                            <p className="lead">{"Something went wrong"}</p>
                        </div>
                    </div>
                ) : isFeedbackSubmitted ? (
                    interviewData.interview_rating === 0 ?
                        <div className="d-flex justify-content-center align-items-center vh-100">
                            <div className="card p-4 shadow col-md-6">
                                <h2 className="text-center mb-4">How was your Interview?</h2>
                                <Form autoComplete="off">
                                    <Form.Group className='text-center'>
                                        <div className="rating">
                                            {[1, 2, 3, 4, 5].map((star) => (
                                                <span
                                                    key={star}
                                                    className={`star ${star <= reviewData.interview_rating ? "selected" : ""}`}
                                                    onClick={() => {
                                                        setReviewData({
                                                            ...reviewData,
                                                            interview_rating: star
                                                        })
                                                    }}
                                                >
                                                    ★
                                                </span>
                                            ))}
                                        </div>
                                    </Form.Group>
                                    <h4 className="text-center my-4">Share your review:</h4>
                                    <Form.Group controlId="formComment" className="mt-3 text-center">
                                        <Form.Control
                                            as="textarea"
                                            name="interview_feedback"
                                            rows={4}
                                            value={reviewData.interview_feedback}
                                            onChange={(e) => reviewHandleSubmit(e)}
                                            placeholder="Enter your feedback"
                                        />
                                    </Form.Group>
                                    <Button onClick={submitCodingFeedback} disabled={reviewData.interview_rating === null || reviewData.interview_rating === 0} variant="primary" type="submit" className="mt-3 w-100">
                                        Submit Feedback
                                    </Button>
                                </Form>
                            </div>
                        </div> :
                        <div className="container d-flex align-items-center justify-content-center vh-100">
                            <div className="text-center">
                                <h1 className="display-4 text-success">Success</h1>
                                <p className="lead">{"Feedback already submitted"}</p>
                            </div>
                        </div>
                ) : (
                    <div className='row w-100'>
                        <div className='col-9' style={{ textAlign: 'right' }}>
                            <Button className='my-2' onClick={saveCode}>Submit Test</Button>
                            <iframe
                                title="coding-iframe"
                                src="https://onecompiler.com/embed?codeChangeEvent=true&listenToEvents=true"
                                width="100%"
                                style={{
                                    height: '85vh'
                                }}
                            ></iframe>
                        </div>
                        <div className='col-3 my-3'>
                            <div className="mt-3" dangerouslySetInnerHTML={{ __html: interviewQuestions ? interviewQuestions : "" }}></div>
                        </div>
                    </div>
                )
            }
        </Fragment>
    );
}

export default CodingScreen;