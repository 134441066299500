import React, { Fragment, useState } from 'react';
import './NotFoundScreen.css';
import HeaderScreen from '../../Components/HeaderScreen/HeaderScreen';
import { Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getAuthUserFromSessionStorage } from '../constant';
import { INTERVIEWER } from '../roles';
import _ from 'lodash';

const NotFoundScreen = () => {
  const navigate = useNavigate();
  const [userDetails, setUserType] = useState(getAuthUserFromSessionStorage());
  const goToHomePage = () => {
    if (_.isUndefined(userDetails) || _.isNull(userDetails) || _.isEmpty(userDetails)) {
        navigate("/sign-in", { replace: true });
    } else if (userDetails.account_type === INTERVIEWER) {
      navigate("/interviewer-dashboard")
    } else {
        navigate("/dashboard")
    }
  }

  return (
    <Fragment>
      <div className="container-fluid calendar-container fixed-padding">
        {
          userDetails && (
            <div className="row">
              <div className="col-12 px-0">
                <HeaderScreen />
              </div>
            </div>
          )
        }
        <div className="row calendar-feednback-row">
          <div className="col-12">
            <Card>
              <Card.Body>
                <Button variant="outline-primary" onClick={() => goToHomePage(false)}>Go to Home page</Button>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NotFoundScreen;
