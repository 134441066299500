import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Papa from "papaparse";
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { Toast } from "primereact/toast";
import { HiChartPie } from "react-icons/hi";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Alert, Button, Card, DropdownButton, Form, Modal, Nav, NavDropdown, OverlayTrigger, Tab, Tooltip } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsFillCalendarWeekFill } from "react-icons/bs";
import { CiFilter } from "react-icons/ci";
import { IoIosCloseCircle, IoMdTrash } from "react-icons/io";
import { IoClose, IoDocumentTextOutline, IoPauseOutline } from "react-icons/io5";
import { LuPencilLine } from "react-icons/lu";
import { MdReport } from "react-icons/md";
import { SlOptionsVertical, SlUser } from "react-icons/sl";
import Loader from "react-js-loader";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Sample_CSV_format from "../../Assets/Sample_CSV_format.csv";
import { API_URL, JOB_STATUS_CLOSED, JOB_STATUS_ON_HOLD, JOB_STATUS_OPEN, allowedResumeTypes, capitalizeFirstLetter, commonDateFormat, getAuthUserFromSessionStorage, resumeFileTypeErrorMessage } from "../../common/constant";
import { EMPLOYER_SUB_ADMIN, EMPLOYER_SUPER_ADMIN, SUB_ADMIN, SUPER_ADMIN } from "../../common/roles";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import "./DashboardScreen.css";

const DashboardScreen = () => {
    const navigate = useNavigate();

    const [userDetails, setUserType] = useState(getAuthUserFromSessionStorage());
    const [jobList, setJobList] = useState([]);
    const [openJobList, setOpenJobList] = useState([]);
    const [closedJobList, setClosedJobList] = useState([]);
    const [onHoldJobList, setOnHoldJobList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [profileIsLoading, setProfileIsLoading] = useState(false);
    const [showJobJD, setShowJobJD] = useState(false);
    const [jobDetails, setJobDetails] = useState({});
    const [companyList, setCompanyList] = useState([]);
    const [companyUsers, setCompanyUsers] = useState([]);
    const [selectedFields, setSelectedFields] = useState({
        companyId: null,
        employerSuperAdminId: null,
        employerSubAdminId: null
    });

    const browserResumesFileRef = useRef(null);
    const browserCSVFileRef = useRef(null);

    const [uploadedCandidateCSVFileName, setUploadedCandidateCSVFileName] = useState(null);
    const [uploadedCandidatebulkFileName, setUploadedCandidatebulkFileName] = useState(null);

    useEffect(() => {
        const fetchAllJobs = async () => {
            try {
                // Define an array of statuses to loop through
                const statuses = ['', JOB_STATUS_OPEN, JOB_STATUS_CLOSED, JOB_STATUS_ON_HOLD];

                // Loop through the statuses and call getAllJobs for each status
                for (const status of statuses) {
                    await getAllJobs(status);
                }

                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching jobs:', error);
                // Handle error, if needed
            }
        };

        if (isLoading) {
            fetchAllJobs();
        }

        if (companyList.length === 0) {
            if (userDetails.account_type === SUB_ADMIN) {
                getRalaSubAdminCompanyList(userDetails.id)
            } else if (userDetails.account_type === SUPER_ADMIN) {
                getCompanyList()
            } else if (userDetails.account_type === EMPLOYER_SUPER_ADMIN && userDetails.company_id === "") {
                getCompantUsers(userDetails.id)
            }
        }
    }, [isLoading]);

    // get all jobs
    const getAllJobs = async (status = '') => {
        try {
            let apiUrl = `${API_URL}api/jobs`;

            let filterParams = {
                ...selectedFields
            }

            if (userDetails.account_type === EMPLOYER_SUPER_ADMIN && userDetails.company_id === "") {
                filterParams.companyId = userDetails.id
            }

            const params = JSON.stringify({
                status: status,
                ...selectedFields
            });

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: params
            });

            const result = await response.json();

            // Use switch instead of multiple if-else for better readability
            switch (status) {
                case JOB_STATUS_CLOSED:
                    setClosedJobList(result.data);
                    break;
                case JOB_STATUS_OPEN:
                    setOpenJobList(result.data);
                    break;
                case JOB_STATUS_ON_HOLD:
                    setOnHoldJobList(result.data);
                    break;
                default:
                    setJobList(result.data);
                    break;
            }
        } catch (error) {
            switch (status) {
                case JOB_STATUS_CLOSED:
                    setClosedJobList([]);
                    break;
                case JOB_STATUS_OPEN:
                    setOpenJobList([]);
                    break;
                case JOB_STATUS_ON_HOLD:
                    setOnHoldJobList([]);
                    break;
                default:
                    setJobList([]);
                    break;
            }
        }
    };

    const getCompanyList = () => {
        fetch(`${API_URL}api/companies`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && (response.data.length > 0)) {
                    setCompanyList(response.data);
                }
            })
            .catch((err) => {
                setCompanyList([]);
            });
    }

    const getRalaSubAdminCompanyList = (ralaSubAdminId) => {
        fetch(`${API_URL}api/getSubAdminUsers/${ralaSubAdminId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                console.log("response", response)
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data) && (response.data.length > 0)) {
                    setCompanyList(response.data)
                } else {
                    setCompanyList([])
                }
            })
            .catch((err) => {
                setCompanyList([]);
            });
    }

    const openJDModal = (jobDetails) => {
        setJobDetails(jobDetails);
        setShowJobJD(true);
    }


    const [dialogHeader, setDialogHeader] = useState('');
    const [dialogShow, setDialogShow] = useState(false);
    const [dialogJobStatus, setDialogJobStatus] = useState('')
    const toast = useRef(null);

    const openJobStatusModal = (jobDetails, status, statusModalHeaderText) => {
        setJobDetails(jobDetails);
        setDialogJobStatus(status);
        setDialogHeader(statusModalHeaderText);
        setDialogShow(true);
    }

    const changeJobStatusApiCall = () => {
        setIsLoading(true)
        fetch(`${API_URL}api/changeJobStatus`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify({
                jobId: jobDetails._id,
                status: dialogJobStatus
            })
        })
            .then((response) => response.json())
            .then((response) => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                setDialogShow(false);
                const statuses = ['', JOB_STATUS_OPEN, JOB_STATUS_CLOSED, JOB_STATUS_ON_HOLD];
                // Loop through the statuses and call getAllJobs for each status
                for (const status of statuses) {
                    getAllJobs(status);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("error", err);
                setIsLoading(false)
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            });
    }


    const openDeleteJobModal = (id) => {
        setDeleteJobModal(true);
        setDeleteJobId(id)
    }

    const closeDeleteJobModal = () => {
        setDeleteJobModal(false);
        setDeleteJobId(null)
    }

    const [deleteJobModal, setDeleteJobModal] = useState(false);
    const [deleteJobId, setDeleteJobId] = useState(null);

    const deleteJobModalfooterContent = (
        <div>
            <Button variant="primary" onClick={() => deleteJobApiCall()} >Yes</Button> {" "}{" "}
            <Button variant="outline-primary" onClick={() => closeDeleteJobModal()}>Cancel</Button>
        </div>
    );

    const deleteJobApiCall = () => {
        setIsLoading(true)
        fetch(`${API_URL}api/deleteJob/${deleteJobId}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                closeDeleteJobModal();
                const statuses = ['', JOB_STATUS_OPEN, JOB_STATUS_CLOSED, JOB_STATUS_ON_HOLD];
                // Loop through the statuses and call getAllJobs for each status
                for (const status of statuses) {
                    getAllJobs(status);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("error", err);
                closeDeleteJobModal();
                setIsLoading(false)
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            });
    }

    const statusChangeModalfooterContent = (
        <div>
            <Button variant="primary" onClick={() => changeJobStatusApiCall()} >Yes</Button> {" "}{" "}
            <Button variant="outline-primary" onClick={() => setDialogShow(false)}>Cancel</Button>
        </div>
    );

    const [errors, setErrors] = useState([]);
    const [showUploadProfiles, setShowUploadProfiles] = useState(false);
    const [candidateProfileData, setCandidateProfileData] = useState([]);
    const resumeUploadRef = useRef(null);

    const uploadProfileModalfooterContent = (
        <div style={{ marginTop: '30px', marginBottom: '40px' }}>
            <Button variant="primary" onClick={() => uploadProfileApiCall()} >Save</Button> {" "}{" "}
            <Button variant="outline-primary" onClick={() => setShowUploadProfiles(false)}>Cancel</Button>
        </div>
    );

    const uploadCSV = (event) => {
        if (event.target.files.length > 0 && event.target.files[0].type === "text/csv") {
            setUploadedCandidateCSVFileName(event.target.files[0].name)
            Papa.parse(event.target.files[0], {
                header: true,
                skipEmptyLines: true,
                transformHeader: header => header.trim(),
                complete: function (results) {
                    if (results.data.length > 25) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Maximum 25 profile can be uploaded at once', life: 3000 });
                    }
                    else if (results.data.length === 0) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'CSV file is empty', life: 3000 });
                    }
                    else {
                        let newInterviewerCandidateDetails = [];
                        _.map(results.data, (candidate, candidateIndex) => {
                            if (!_.isUndefined(candidate['Candidate name']) && !_.isNull(candidate['Candidate name']) && (candidate['Candidate name'] !== '')) {
                                if (candidate["available time"] != "") {
                                    const dateString = candidate["available time"];
                                    const parts = dateString.split(/[- :]/);
                                    const dateObject = new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4]);
                                    if (isNaN(dateObject.getTime())) {
                                        candidate["available time"] = new Date();
                                    } else {
                                        candidate["available time"] = dateObject;
                                    }
                                } else {
                                    candidate["available time"] = new Date();
                                }
                                newInterviewerCandidateDetails.push({
                                    uniqueId: new Date().valueOf() + candidateIndex,
                                    job_id: jobDetails._id,
                                    candidate_name: candidate['Candidate name'],
                                    candidate_mobile_number: candidate["Phone number"],
                                    candidate_email: candidate["E-mail"],
                                    available_on: new Date(candidate["available time"]),
                                    resume: ""
                                });
                            }
                        })
                        if (newInterviewerCandidateDetails.length > 0)
                            setCandidateProfileData(newInterviewerCandidateDetails);
                    }
                    clearFile(browserCSVFileRef);
                },
            });
        } else {
            clearFile(browserCSVFileRef);
            setUploadedCandidateCSVFileName(null);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please upload only csv file', life: 3000 });
        }
    }

    const clearFile = (fileInput) => {
        if (fileInput.current) {
            fileInput.current.value = ''; // Reset file input
        }
    }

    const uploadFiles = async (event) => {
        const files = event.target.files;
        if (files.length > 25) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Maximum 25 profile can be uploaded at once', life: 3000 });
            clearFile(browserResumesFileRef);
            return;
        }
        if (files.length === 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please select at least one profile', life: 3000 });
            clearFile(browserResumesFileRef);
            return;
        }

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (!(allowedResumeTypes.includes(file.type))) {
                setUploadedCandidateCSVFileName(null);
                toast.current.show({ severity: 'error', summary: 'Error', detail: resumeFileTypeErrorMessage, life: 3000 });
                return false;
            }
        }

        setCandidateProfileData([]);
        let newInterviewerCandidateDetails = [];
        setProfileIsLoading(true)
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            var filePath = await uploadCandidateFile(file);
            newInterviewerCandidateDetails.push({
                uniqueId: new Date().valueOf() + i,
                job_id: jobDetails._id,
                candidate_name: "",
                candidate_mobile_number: "",
                candidate_email: "",
                available_on: new Date(),
                resume: filePath
            });
            setUploadedCandidatebulkFileName(file.name)
        }
        setCandidateProfileData(newInterviewerCandidateDetails)
        setProfileIsLoading(false)
        clearFile(browserResumesFileRef);
    }

    const uploadCandidateFile = async (file) => {
        try {
            const data = new FormData();
            data.append('file', file);
            const response = await fetch(`${API_URL}api/uploadFiles`, {
                method: 'POST',
                body: data
            });
            const responseData = await response.json();
            return responseData.filepath;
        } catch (error) {
            return "";
        }
    };

    const profileChangeHandler = (index, event) => {
        let newCandidateProfileData = [...candidateProfileData];

        const { name, value } = event.target;
        if (name === "candidate_mobile_number") {
            const numericInput = value.replace(/\D/g, '');
            const truncatedInput = numericInput.slice(0, 10);
            newCandidateProfileData[index][name] = truncatedInput;
            setCandidateProfileData(newCandidateProfileData);
        } else {
            newCandidateProfileData[index][event.target.name] = event.target.value;
            setCandidateProfileData(newCandidateProfileData);
        }
    };

    const changeDateHandler = (index, date) => {
        let newCandidateProfileData = [...candidateProfileData];
        newCandidateProfileData[index].available_on = date;
        setCandidateProfileData(newCandidateProfileData);
    };

    const removeCandidateProfile = (index) => {
        let newCandidateProfileData = [...candidateProfileData];
        newCandidateProfileData.splice(index, 1);
        setCandidateProfileData(newCandidateProfileData);
    }

    const uploadResumeHandler = async (index, event) => {
        if (event && event.files && event.files.length > 0) {
            setIsLoading(true)
            const file = event.files[0];
            const data = new FormData();
            data.append('file', file);
            fetch(`${API_URL}api/uploadFiles`, {
                method: 'POST',
                body: data
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.status == 200) {
                        let newCandidateProfileData = [...candidateProfileData];
                        newCandidateProfileData[index].resume = response.filepath;
                        setCandidateProfileData(newCandidateProfileData);
                        if (resumeUploadRef.current) {
                            resumeUploadRef.current.clear();
                        }
                        toast.current.show({ severity: 'success', summary: 'Success', detail: "Resume added successfully", life: 3000 });
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    }
                    setIsLoading(false)
                })
                .catch((err) => {
                    setIsLoading(false)
                    console.log("error", err);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                });
        } else {
            if (resumeUploadRef.current) {
                resumeUploadRef.current.clear();
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: resumeFileTypeErrorMessage, life: 3000 });
        }
    };

    const getProfileErrorMessage = (uniqueId, key) => {
        const error = errors.find(err => err.key === key && err.uniqueId === uniqueId);
        return error ? error.errorMessage : null;
    }

    const validateProfileData = () => {
        const newErrors = [];
        const requiredFields = ['job_id', 'candidate_name', 'candidate_mobile_number', 'candidate_email', 'resume', 'available_on'];
        for (const obj of candidateProfileData) {
            for (const field of requiredFields) {
                const fieldLabel = capitalizeFirstLetter(field.replaceAll("_", " "));
                if (!obj.hasOwnProperty(field) || obj[field] === null || obj[field] === undefined || obj[field] === '') {
                    newErrors.push({
                        uniqueId: obj['uniqueId'],
                        key: field,
                        errorMessage: `${fieldLabel} is required!`
                    })
                } else {
                    if (field === "candidate_mobile_number" && /^\d{10}$/.test(obj[field]) === false) {
                        newErrors.push({
                            uniqueId: obj['uniqueId'],
                            key: field,
                            errorMessage: `Enter valid mobile number!`
                        })
                    }
                    if (field === "candidate_email") {
                        const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(obj[field]);
                        if (!isValid) {
                            newErrors.push({
                                uniqueId: obj['uniqueId'],
                                key: field,
                                errorMessage: `Enter valid email address!`
                            })
                        }
                    }
                }
            }
        }

        setErrors(newErrors);

        console.log("newErrors", newErrors)

        if (newErrors.length > 0) {
            return false;
        }

        const uniqueEmails = new Set();
        const uniqueMobileNumbers = new Set();
        let repeatedEntries = [];

        candidateProfileData.forEach(entry => {
            if (uniqueEmails.has(entry.candidate_email) || uniqueMobileNumbers.has(entry.candidate_mobile_number)) {
                repeatedEntries.push({
                    ...entry,
                    key: uniqueEmails.has(entry.candidate_email) ? 'candidate_email' : 'candidate_mobile_number',
                    label: uniqueEmails.has(entry.candidate_email) ? 'Candidate email' : 'Candidate mobile number'
                });
            } else {
                uniqueEmails.add(entry.candidate_email);
                uniqueMobileNumbers.add(entry.candidate_mobile_number);
            }
        });

        if (repeatedEntries.length > 0) {
            for (var j = 0; j < repeatedEntries.length; repeatedEntries++) {
                newErrors.push(
                    {
                        uniqueId: repeatedEntries[j]['uniqueId'],
                        key: repeatedEntries[j]['key'],
                        errorMessage: `${repeatedEntries[j]['label']} is duplicate!`
                    }
                )
            }
        }

        setErrors(newErrors);


        if (newErrors.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    const uploadProfileApiCall = () => {
        if (candidateProfileData.length > 0) {
            if (validateProfileData()) {
                setIsLoading(true)
                fetch(`${API_URL}api/addCandidateProfileData`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userDetails.token}`,
                    },
                    body: JSON.stringify({
                        candidateProfileData: candidateProfileData
                    })
                })
                    .then((response) => response.json())
                    .then((response) => {
                        if (response.success) {
                            toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                            setShowUploadProfiles(false);
                            setCandidateProfileData([]);
                            setTimeout(() => {
                                navigate(`/job/${jobDetails._id}/scheduled-profile`)
                            }, 2000)
                        } else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                        }
                        setIsLoading(false)

                    })
                    .catch((err) => {
                        setIsLoading(false)
                        console.log("error", err);
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    });
            }
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please upload records first', life: 3000 });
        }
    }

    const [editConfirmHeaderText, setEditConfirmHeaderText] = useState("");
    const [editConfirmDialogShow, setEditConfirmDialogShow] = useState(false);

    const editConfirmModalfooterContent = (
        <div>
            <Button variant="primary" onClick={() => setEditConfirmDialogShow(false)} >Ok</Button>
        </div>
    );

    const clearFilter = () => {
        setSelectedFields({
            companyId: null,
            employerSuperAdminId: null,
            employerSubAdminId: null
        })
    }

    const filterOnChange = (e) => {
        const { name, value } = e.target
        if (name === "companyId") {
            if (value) {
                getCompantUsers(value)
            } else {
                setCompanyUsers([])
            }
            setSelectedFields({
                [name]: value,
                employerSuperAdminId: null,
                employerSubAdminId: null,
            })
        } else {
            setSelectedFields({
                ...selectedFields,
                [name]: value
            })
        }
    }

    // getSubAdminUsers

    const getCompantUsers = (companyId) => {
        fetch(`${API_URL}api/getCompanyUser/${companyId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data) && (response.data.length > 0)) {
                    setCompanyUsers(response.data)
                } else {
                    setCompanyUsers([])
                }
            })
            .catch((err) => {
                setCompanyUsers([]);
            });
    }

    useEffect(() => {
        setIsLoading(true)
    }, [selectedFields])

    return (
        <Fragment>
            <Toast ref={toast} />
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            {
                profileIsLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <div className="container-fluid employer-dashboard-container fixed-padding">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div class="row mx-1"><div class="col-12 dashboard-title pageTitle_fixed"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 20 20" aria-hidden="true" class="header-icon" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg>DASHBOARD</div></div>
                <div className="pageTitle_fixed brd-0">
                    <div className="col-12">
                        <p className="employer-dashboard-jobs-posted mb-0">
                            <div className='d-flex align-items-center gap-2'><HiChartPie size={18} />Jobs posted</div>
                        </p>
                    </div>
                </div>
                <div className="row job-container">
                    <div className="col-12 employer-dashboard-title dashboard-jobs ">

                        {
                            (userDetails.account_type === SUPER_ADMIN || (userDetails.account_type === EMPLOYER_SUPER_ADMIN && userDetails.company_id === "")) && (
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>Statistics</Tooltip>}
                                >
                                    <div className="bar-dashboard" >
                                        <Link to={{
                                            pathname: "/state"
                                        }} style={{ color: 'white' }}>
                                            <FontAwesomeIcon icon={faBars} />
                                        </Link>
                                    </div>
                                </OverlayTrigger>
                            )
                        }

                        <div className="report-filter-section job-filter repos" style={{ width: '50px' }}>
                            {
                                (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN || (userDetails.account_type === EMPLOYER_SUPER_ADMIN && userDetails.company_id === "")) && (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Filters</Tooltip>}
                                    >
                                        <DropdownButton id="report-filter" title={<CiFilter style={{ width: '25px', height: '25px' }} />} style={{ backgroundImage: 'none' }}>
                                            <div className="px-2 pb-2 pt-3">
                                                {
                                                    userDetails && (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && (
                                                        <Form.Select value={selectedFields.companyId ?? ""} name="companyId" onChange={filterOnChange} aria-label="Default select example">
                                                            <option value="">Select Company</option>
                                                            {companyList && companyList.map((item, index) => (
                                                                <option value={item.id} className="filter-item">{item.company_name}</option>
                                                            ))}
                                                        </Form.Select>
                                                    )
                                                }
                                            </div>
                                            <div className="px-2 py-2">
                                                {
                                                    userDetails && (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN || (userDetails.account_type === EMPLOYER_SUPER_ADMIN && userDetails.company_id === "")) && (
                                                        <Form.Select value={selectedFields.companyId ?? ""} name="companyId" onChange={filterOnChange} aria-label="Default select example">
                                                            <option value="">Select Employer Super Admin</option>
                                                            {companyUsers && companyUsers
                                                                .filter((item) => item.account_type === EMPLOYER_SUPER_ADMIN)
                                                                .map((item, index) => (
                                                                    <option key={index} value={item.id} className="filter-item">
                                                                        {`${item.first_name} ${item.last_name}`}
                                                                    </option>
                                                                ))}
                                                        </Form.Select>
                                                    )
                                                }
                                            </div>
                                            <div className="px-2 py-2">
                                                {
                                                    userDetails && (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN || (userDetails.account_type === EMPLOYER_SUPER_ADMIN && userDetails.company_id === "")) && (
                                                        <Form.Select value={selectedFields.employerSubAdminId ?? ""} name="employerSubAdminId" onChange={filterOnChange} aria-label="Default select example">
                                                            <option value="">Select Employer Sub Admin</option>
                                                            {companyUsers && companyUsers
                                                                .filter((item) => item.account_type === EMPLOYER_SUB_ADMIN)
                                                                .map((item, index) => (
                                                                    <option key={index} value={item.id} className="filter-item">
                                                                        {`${item.first_name} ${item.last_name}`}
                                                                    </option>
                                                                ))}
                                                        </Form.Select>
                                                    )
                                                }
                                            </div>
                                            <div className="my-2" style={{ textAlign: 'center' }}>
                                                <Button onClick={clearFilter} style={{
                                                    marginTop: '0px',
                                                    background: '#FFFBFE',
                                                    color: 'black',
                                                    border: 'none'
                                                }} className="employer-dashboard-interview-join">
                                                    Reset
                                                </Button>
                                            </div>
                                        </DropdownButton>
                                    </OverlayTrigger>
                                )
                            }
                        </div>

                        <Tab.Container defaultActiveKey="openJobs">

                            {
                                (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN) && (
                                    <div className="row">
                                        <div className="col-12">
                                            <Nav fill variant="tabs">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="openJobs" onClick={() => getAllJobs(JOB_STATUS_OPEN)}>Open Jobs <span className='open-job'>{openJobList && openJobList.length}</span></Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="closedJobs" onClick={() => getAllJobs(JOB_STATUS_CLOSED)}>Closed Jobs <span className='closed-job'>{closedJobList && closedJobList.length}</span></Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="jobsOnHold" onClick={() => getAllJobs(JOB_STATUS_ON_HOLD)}>Jobs On-hold <span className='onHold-job'>{onHoldJobList && onHoldJobList.length}</span></Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="allJobsPosting" onClick={() => getAllJobs()}>All Jobs Posting <span className='list-job'>{jobList && jobList.length}</span></Nav.Link>
                                                </Nav.Item>
                                                {
                                                    (
                                                        (userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN) && (
                                                            <Nav.Item>
                                                                <NavLink className="nav-link" to="/create-job">
                                                                    Create a new job
                                                                </NavLink>
                                                            </Nav.Item>
                                                        )
                                                    )
                                                }
                                            </Nav>
                                        </div>
                                    </div>
                                )
                            }

                            <div className="row">
                                <div className="col-12">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="openJobs">
                                            {
                                                !_.isUndefined(openJobList) && !_.isNull(openJobList) && openJobList.length == 0 && (userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN) && (<Card className="employer-dashboard-interview" >
                                                    <Card.Body>
                                                        <div className="d-flex align-items-center justify-content-center" style={{ height: '70vh' }}>
                                                            <div className="text-center fs-6">
                                                                <p>NO JOBS CREATED &nbsp;
                                                                    <Link className="text-primary text-decoration-none" to="/create-job">CREATED A NEW JOB</Link>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>)
                                            }
                                            {
                                                !_.isUndefined(openJobList) && !_.isNull(openJobList) &&
                                                _.map(openJobList, (job, index) => {
                                                    return <Card className="employer-dashboard-interview" key={"employer-dashboard-interview-openJobs-" + index}>
                                                        <Card.Body className='pt-5 pt-lg-4'>
                                                            <div className="row">
                                                                <div className="col-12 col-md-12 col-lg-7" style={{ cursor: 'pointer' }} onClick={() => navigate(`/job/${job._id}/scheduled-profile`)}>
                                                                    <div className="row">
                                                                        <div className="col-12 col-md-12 col-lg-7">
                                                                            <p className="employer-dashboard-interview-job-title">Job Title &nbsp; <span>{job.job_title}</span></p>
                                                                        </div>
                                                                        <div className="col-12 col-md-12 col-lg-5">
                                                                            <p className="employer-dashboard-interview-job-title">Exp &nbsp; <span>{job.experience_min_year}-{job.experience_max_year} Years</span></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12 col-lg-5 text-end jd_sche">
                                                                    <Button className="employer-dashboard-interview-join" onClick={() => openJDModal(job)}>
                                                                        <IoDocumentTextOutline size={16} /><span>JD</span>
                                                                    </Button>
                                                                    {
                                                                        // job.status === JOB_STATUS_OPEN && 
                                                                        (userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN) ? (
                                                                            <>
                                                                                <Button className="employer-dashboard-interview-join"
                                                                                    onClick={() => { setCandidateProfileData([]); setJobDetails(job); setUploadedCandidatebulkFileName(null); setUploadedCandidateCSVFileName(null); setShowUploadProfiles(true) }}
                                                                                >
                                                                                    <BsFillCalendarWeekFill size={16} /><span>
                                                                                        Upload profiles
                                                                                    </span>
                                                                                </Button>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Button className="employer-dashboard-interview-join"
                                                                                    onClick={() => job.status == JOB_STATUS_OPEN && navigate("/job/" + job._id + "/scheduled-profile")}
                                                                                >
                                                                                    <BsFillCalendarWeekFill size={16} /><span>Schedule profiles</span>
                                                                                </Button>
                                                                            </>
                                                                        )
                                                                    }
                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        overlay={<Tooltip>Actions</Tooltip>}
                                                                    >
                                                                        <NavDropdown className="employer-dashboard-interview-action" title={<SlOptionsVertical size={20} className="employer-dashboard-interview-action-icon" />}>
                                                                            {
                                                                                (userDetails.account_type === EMPLOYER_SUPER_ADMIN || (
                                                                                    userDetails.account_type === EMPLOYER_SUB_ADMIN && userDetails.id === job.job_created_by_id
                                                                                )) && (
                                                                                    <>
                                                                                        <NavDropdown.Item onClick={() => navigate(`/edit-job/${job._id}`)} > <LuPencilLine size={20} /> Edit</NavDropdown.Item>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            {
                                                                                userDetails.account_type === EMPLOYER_SUPER_ADMIN && (
                                                                                    <>

                                                                                        <NavDropdown.Item onClick={() => openJobStatusModal(job, JOB_STATUS_CLOSED, "Close the Job?")} > <IoIosCloseCircle size={20} /> Close job</NavDropdown.Item>
                                                                                        <NavDropdown.Item onClick={() => openJobStatusModal(job, JOB_STATUS_ON_HOLD, "Put the Job on-hold?")} > <IoPauseOutline size={20} /> On hold</NavDropdown.Item>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            <NavDropdown.Item onClick={() => navigate("/job/" + job._id + "/report")} > <MdReport size={20} /> Report</NavDropdown.Item>
                                                                        </NavDropdown>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ cursor: 'pointer' }} onClick={() => navigate(`/job/${job._id}/scheduled-profile`)}>
                                                                <div className="col-12">
                                                                    <p className="employer-dashboard-interview-job-title">Created by &nbsp; <span>{job.job_created_by_name}</span></p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ cursor: 'pointer' }} onClick={() => navigate(`/job/${job._id}/scheduled-profile`)}>
                                                                <div className="col-12">
                                                                    <p className="employer-dashboard-interview-job-title">Company &nbsp; <span>{job.company_name}</span></p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12 col-lg-5 col-12" style={{ cursor: 'pointer' }} onClick={() => navigate(`/job/${job._id}/scheduled-profile`)}>
                                                                    <p className="employer-dashboard-interview-job-title">BU / PO code &nbsp; <span>{job.bu_code}</span></p>
                                                                </div>
                                                                <div className="col-md-12 col-lg-7 col-12" style={{
                                                                    marginTop: '-8px'
                                                                }}>
                                                                    <div className="row">
                                                                        <div className="col-md-5 col-12 request-for-interviewer">
                                                                            <Button onClick={() => job.status == JOB_STATUS_OPEN && (
                                                                                (userDetails.account_type == EMPLOYER_SUPER_ADMIN || userDetails.account_type == EMPLOYER_SUB_ADMIN) ? job.proposedInterviews.length > 0 : true) && navigate("/job/" + job._id + "/proposed-interviewers")} variant="outline-primary">
                                                                                <SlUser size={16} className='me-2' /><span>Request for interviewer</span>
                                                                            </Button>
                                                                        </div>
                                                                        <div className="col-md-5 col-12 text-center my-auto timestamp">
                                                                            <p className="employer-dashboard-interview-time">Created on {commonDateFormat(job.createdAt)}</p>
                                                                            <p className="employer-dashboard-interview-time">Modified on {commonDateFormat(job.updatedAt)}</p>
                                                                            <p className="employer-dashboard-interview-time">Modified By {job.job_modified_by_name}</p>
                                                                        </div>
                                                                        <div className="col-md-2 col-12 text-center my-auto">
                                                                            <p className="employer-dashboard-interview-today">{job.status}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                })
                                            }
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="closedJobs">
                                            {
                                                !_.isUndefined(closedJobList) && !_.isNull(closedJobList) &&
                                                _.map(closedJobList, (job, index) => {
                                                    return <Card className="employer-dashboard-interview" key={"employer-dashboard-interview-closeJobs-" + index}>
                                                        <Card.Body className='pt-5 pt-lg-4'>
                                                            <div className="row">
                                                                <div className="col-12 col-md-12 col-lg-7" style={{ cursor: 'pointer' }} onClick={() => navigate(`/job/${job._id}/scheduled-profile`)}>
                                                                    <div className="row">
                                                                        <div className="col-12 col-md-12 col-lg-7">
                                                                            <p className="employer-dashboard-interview-job-title">Job Title &nbsp; <span>{job.job_title}</span></p>
                                                                        </div>
                                                                        <div className="col-12 col-md-12 col-lg-5">
                                                                            <p className="employer-dashboard-interview-job-title">Exp &nbsp; <span>{job.experience_min_year}-{job.experience_max_year} Years</span></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12 col-lg-5 text-end jd_sche">
                                                                    <Button className="employer-dashboard-interview-join" onClick={() => openJDModal(job)}>
                                                                        <IoDocumentTextOutline size={16} /><span>JD</span>
                                                                    </Button>
                                                                    <Button className="employer-dashboard-interview-join button-disable"
                                                                        onClick={() => job.status == JOB_STATUS_OPEN && navigate("/job/" + job._id + "/scheduled-profile")}
                                                                    >
                                                                        <BsFillCalendarWeekFill size={16} /><span>
                                                                            {
                                                                                (userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN) ? "Upload profiles" : "Schedule profiles"
                                                                            }
                                                                        </span>
                                                                    </Button>
                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        overlay={<Tooltip>Action</Tooltip>}
                                                                    >
                                                                        <NavDropdown className="employer-dashboard-interview-action" title={<SlOptionsVertical size={20} className="employer-dashboard-interview-action-icon" />}>
                                                                            {
                                                                                (userDetails.account_type === EMPLOYER_SUPER_ADMIN || (
                                                                                    userDetails.account_type === EMPLOYER_SUB_ADMIN && userDetails.id === job.job_created_by_id
                                                                                )) && (
                                                                                    <>
                                                                                        <NavDropdown.Item onClick={() => {
                                                                                            setEditConfirmHeaderText("You can not edit close job!");
                                                                                            setEditConfirmDialogShow(true);
                                                                                        }} > <LuPencilLine size={20} /> Edit</NavDropdown.Item>
                                                                                        <NavDropdown.Item onClick={() => openDeleteJobModal(job._id)} > <IoMdTrash size={20} /> Delete job</NavDropdown.Item>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            <NavDropdown.Item onClick={() => navigate("/job/" + job._id + "/report")}> <MdReport size={20} /> Report</NavDropdown.Item>
                                                                        </NavDropdown>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ cursor: 'pointer' }} onClick={() => navigate(`/job/${job._id}/scheduled-profile`)}>
                                                                <div className="col-12">
                                                                    <p className="employer-dashboard-interview-job-title">Created by &nbsp; <span>{job.job_created_by_name}</span></p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ cursor: 'pointer' }} onClick={() => navigate(`/job/${job._id}/scheduled-profile`)}>
                                                                <div className="col-12">
                                                                    <p className="employer-dashboard-interview-job-title">Company &nbsp; <span>{job.company_name}</span></p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12 col-lg-5 col-12" style={{ cursor: 'pointer' }} onClick={() => navigate(`/job/${job._id}/scheduled-profile`)}>
                                                                    <p className="employer-dashboard-interview-job-title">BU / PO code &nbsp; <span>{job.bu_code}</span></p>
                                                                </div>
                                                                <div className="col-md-12 col-lg-7 col-12" style={{
                                                                    marginTop: '-8px'
                                                                }}>
                                                                    <div className="row">
                                                                        <div className="col-md-5 col-12 request-for-interviewer">
                                                                            <Button onClick={() => job.status == JOB_STATUS_OPEN && job.proposedInterviews.length > 0 && navigate("/job/" + job._id + "/proposed-interviewers")} variant="outline-primary" disabled >
                                                                                <SlUser size={16} className='me-2' /><span>Request for interviewer</span>
                                                                            </Button>
                                                                        </div>
                                                                        <div className="col-md-5 col-12 text-center my-auto timestamp">
                                                                            <p className="employer-dashboard-interview-time">Created on {commonDateFormat(job.createdAt)}</p>
                                                                            <p className="employer-dashboard-interview-time">Modified on {commonDateFormat(job.updatedAt)}</p>
                                                                            <p className="employer-dashboard-interview-time">Modified By {job.job_modified_by_name}</p>
                                                                        </div>
                                                                        <div className="col-md-2 col-12 text-center my-auto">
                                                                            <p className="employer-dashboard-interview-today button-disable closed-job">{job.status}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                })
                                            }
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="jobsOnHold">
                                            {
                                                !_.isUndefined(onHoldJobList) && !_.isNull(onHoldJobList) &&
                                                _.map(onHoldJobList, (job, index) => {
                                                    return <Card className="employer-dashboard-interview" key={"employer-dashboard-interview-jobOnHold-" + index}>
                                                        <Card.Body className='pt-5 pt-lg-4'>
                                                            <div className="row">
                                                                <div className="col-12 col-md-12 col-lg-7" style={{ cursor: 'pointer' }} onClick={() => navigate(`/job/${job._id}/scheduled-profile`)}>
                                                                    <div className="row">
                                                                        <div className="col-12 col-md-12 col-lg-7">
                                                                            <p className="employer-dashboard-interview-job-title">Job Title &nbsp; <span>{job.job_title}</span></p>
                                                                        </div>
                                                                        <div className="col-12 col-md-12 col-lg-5">
                                                                            <p className="employer-dashboard-interview-job-title">Exp &nbsp; <span>{job.experience_min_year}-{job.experience_max_year} Years</span></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12 col-lg-5 text-end jd_sche">
                                                                    <Button className="employer-dashboard-interview-join" onClick={() => openJDModal(job)}>
                                                                        <IoDocumentTextOutline size={16} /><span>JD</span>
                                                                    </Button>
                                                                    <Button className="employer-dashboard-interview-join button-disable"
                                                                        onClick={() => job.status == JOB_STATUS_OPEN && navigate("/job/" + job._id + "/scheduled-profile")}
                                                                    >
                                                                        <BsFillCalendarWeekFill size={16} /><span>
                                                                            {
                                                                                (userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN) ? "Upload profiles" : "Schedule profiles"
                                                                            }
                                                                        </span>
                                                                    </Button>
                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        overlay={<Tooltip>Action</Tooltip>}
                                                                    >
                                                                        <NavDropdown className="employer-dashboard-interview-action" title={<SlOptionsVertical size={20} className="employer-dashboard-interview-action-icon" />}>
                                                                            {
                                                                                userDetails.account_type === EMPLOYER_SUPER_ADMIN && (
                                                                                    <>

                                                                                        <NavDropdown.Item onClick={() => openJobStatusModal(job, JOB_STATUS_CLOSED, "Close the Job?")} > <IoIosCloseCircle size={20} /> Close job</NavDropdown.Item>
                                                                                        <NavDropdown.Item onClick={() => openJobStatusModal(job, JOB_STATUS_OPEN, "Reopen Job?")} > <IoPauseOutline size={20} /> Reopen</NavDropdown.Item>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            {
                                                                                (userDetails.account_type === EMPLOYER_SUPER_ADMIN || (
                                                                                    userDetails.account_type === EMPLOYER_SUB_ADMIN && userDetails.id === job.job_created_by_id
                                                                                )) && (
                                                                                    <>
                                                                                        <NavDropdown.Item onClick={() => {
                                                                                            setEditConfirmHeaderText("You can not edit this job Reopen the job to edit");
                                                                                            setEditConfirmDialogShow(true);
                                                                                        }} > <LuPencilLine size={20} /> Edit</NavDropdown.Item>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            <NavDropdown.Item onClick={() => navigate("/job/" + job._id + "/report")}> <MdReport size={20} /> Report</NavDropdown.Item>

                                                                        </NavDropdown>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ cursor: 'pointer' }} onClick={() => navigate(`/job/${job._id}/scheduled-profile`)}>
                                                                <div className="col-12">
                                                                    <p className="employer-dashboard-interview-job-title">Created by &nbsp; <span>{job.job_created_by_name}</span></p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ cursor: 'pointer' }} onClick={() => navigate(`/job/${job._id}/scheduled-profile`)}>
                                                                <div className="col-12">
                                                                    <p className="employer-dashboard-interview-job-title">Company &nbsp; <span>{job.company_name}</span></p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12 col-lg-5 col-12" style={{ cursor: 'pointer' }} onClick={() => navigate(`/job/${job._id}/scheduled-profile`)}>
                                                                    <p className="employer-dashboard-interview-job-title">BU / PO code &nbsp; <span>{job.bu_code}</span></p>
                                                                </div>
                                                                <div className="col-md-12 col-lg-7 col-12" style={{
                                                                    marginTop: '-8px'
                                                                }}>
                                                                    <div className="row">
                                                                        <div className="col-md-5 col-12 request-for-interviewer">
                                                                            <Button onClick={() => job.status == JOB_STATUS_OPEN && job.proposedInterviews.length > 0 && navigate("/job/" + job._id + "/proposed-interviewers")} variant="outline-primary" disabled >
                                                                                <SlUser size={16} className='me-2' /><span>Request for interviewer</span>
                                                                            </Button>
                                                                        </div>
                                                                        <div className="col-md-5 col-12 text-center my-auto timestamp">
                                                                            <p className="employer-dashboard-interview-time">Created on {commonDateFormat(job.createdAt)}</p>
                                                                            <p className="employer-dashboard-interview-time">Modified on {commonDateFormat(job.updatedAt)}</p>
                                                                            <p className="employer-dashboard-interview-time">Modified By {job.job_modified_by_name}</p>
                                                                        </div>
                                                                        <div className="col-md-2 col-12 text-center my-auto">
                                                                            <p className="employer-dashboard-interview-on-hold holdjob">{job.status}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                })
                                            }
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="allJobsPosting">
                                            {
                                                !_.isUndefined(jobList) && !_.isNull(jobList) &&
                                                _.map(jobList, (job, index) => {
                                                    return <Card className="employer-dashboard-interview" key={"employer-dashboard-interview-allJobs-" + index}>
                                                        <Card.Body className='pt-5 pt-lg-4'>
                                                            <div className="row">
                                                                <div className="col-12 col-md-12 col-lg-7" style={{ cursor: 'pointer' }} onClick={() => navigate(`/job/${job._id}/scheduled-profile`)}>
                                                                    <div className="row">
                                                                        <div className="col-12 col-md-12 col-lg-7">
                                                                            <p className="employer-dashboard-interview-job-title">Job Title &nbsp; <span>{job.job_title}</span></p>
                                                                        </div>
                                                                        <div className="col-12 col-md-12 col-lg-5">
                                                                            <p className="employer-dashboard-interview-job-title">Exp &nbsp; <span>{job.experience_min_year}-{job.experience_max_year} Years</span></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12 col-lg-5 text-end jd_sche">
                                                                    <Button className="employer-dashboard-interview-join" onClick={() => openJDModal(job)}>
                                                                        <IoDocumentTextOutline size={16} /><span>JD</span>
                                                                    </Button>
                                                                    {
                                                                        job.status !== JOB_STATUS_OPEN ? (
                                                                            (userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN) ? (
                                                                                <>
                                                                                    <Button className="employer-dashboard-interview-join"
                                                                                        onClick={() => { setCandidateProfileData([]); setJobDetails(job); setUploadedCandidatebulkFileName(null); setUploadedCandidateCSVFileName(null); setShowUploadProfiles(true) }}
                                                                                    >
                                                                                        <BsFillCalendarWeekFill size={16} /><span>
                                                                                            Upload profiles
                                                                                        </span>
                                                                                    </Button>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <Button className={job.status === JOB_STATUS_OPEN ? 'employer-dashboard-interview-join' : 'employer-dashboard-interview-join button-disable'}
                                                                                        onClick={() => job.status == JOB_STATUS_OPEN && navigate("/job/" + job._id + "/scheduled-profile")}
                                                                                    >
                                                                                        <BsFillCalendarWeekFill size={16} /><span>Schedule profiles</span>
                                                                                    </Button>
                                                                                </>
                                                                            )
                                                                        ) : (
                                                                            (userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN) ? (
                                                                                <>
                                                                                    <Button className="employer-dashboard-interview-join"
                                                                                        onClick={() => { setCandidateProfileData([]); setJobDetails(job); setUploadedCandidatebulkFileName(null); setUploadedCandidateCSVFileName(null); setShowUploadProfiles(true) }}
                                                                                    >
                                                                                        <BsFillCalendarWeekFill size={16} /><span>
                                                                                            Upload profiles
                                                                                        </span>
                                                                                    </Button>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <Button className={job.status === JOB_STATUS_OPEN ? 'employer-dashboard-interview-join' : 'employer-dashboard-interview-join button-disable'}
                                                                                        onClick={() => job.status === JOB_STATUS_OPEN && navigate("/job/" + job._id + "/scheduled-profile")}
                                                                                    >
                                                                                        <BsFillCalendarWeekFill size={16} /><span>Schedule profiles</span>
                                                                                    </Button>
                                                                                </>
                                                                            )
                                                                        )
                                                                    }
                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        overlay={<Tooltip>Action</Tooltip>}
                                                                    >
                                                                        <NavDropdown className="employer-dashboard-interview-action" title={<SlOptionsVertical size={20} className="employer-dashboard-interview-action-icon" />}>

                                                                            {
                                                                                job.status === JOB_STATUS_OPEN && (userDetails.account_type === EMPLOYER_SUPER_ADMIN || (userDetails.account_type === EMPLOYER_SUB_ADMIN && userDetails.id === job.job_created_by_id)) && (
                                                                                    <>
                                                                                        <NavDropdown.Item onClick={() => navigate(`/edit-job/${job._id}`)}> <LuPencilLine size={20} /> Edit</NavDropdown.Item>
                                                                                    </>
                                                                                )
                                                                            }

                                                                            {
                                                                                userDetails.account_type === EMPLOYER_SUPER_ADMIN && job.status === JOB_STATUS_OPEN && (
                                                                                    <>
                                                                                        <NavDropdown.Item onClick={() => openJobStatusModal(job, JOB_STATUS_CLOSED, "Close the Job?")} > <IoIosCloseCircle size={20} /> Close job</NavDropdown.Item>
                                                                                        <NavDropdown.Item onClick={() => openJobStatusModal(job, JOB_STATUS_ON_HOLD, "Put the Job on-hold?")} > <IoPauseOutline size={20} /> On hold</NavDropdown.Item>
                                                                                    </>
                                                                                )
                                                                            }

                                                                            {
                                                                                job.status === JOB_STATUS_CLOSED && (userDetails.account_type === EMPLOYER_SUPER_ADMIN || (userDetails.account_type === EMPLOYER_SUB_ADMIN && userDetails.id === job.job_created_by_id)) && (
                                                                                    <>
                                                                                        <NavDropdown.Item onClick={() => {
                                                                                            setEditConfirmHeaderText("You can not edit close job!");
                                                                                            setEditConfirmDialogShow(true);
                                                                                        }} > <LuPencilLine size={20} /> Edit</NavDropdown.Item>
                                                                                        <NavDropdown.Item onClick={() => openDeleteJobModal(job._id)} > <IoMdTrash size={20} /> Delete job</NavDropdown.Item>
                                                                                    </>
                                                                                )
                                                                            }

                                                                            {
                                                                                userDetails.account_type === EMPLOYER_SUPER_ADMIN && job.status === JOB_STATUS_ON_HOLD && (
                                                                                    <>
                                                                                        <NavDropdown.Item onClick={() => openJobStatusModal(job, JOB_STATUS_CLOSED, "Close the Job?")} > <IoIosCloseCircle size={20} /> Close job</NavDropdown.Item>
                                                                                        <NavDropdown.Item onClick={() => openJobStatusModal(job, JOB_STATUS_OPEN, "Reopen Job?")} > <IoPauseOutline size={20} /> Reopen</NavDropdown.Item>
                                                                                    </>
                                                                                )
                                                                            }

                                                                            {
                                                                                job.status === JOB_STATUS_ON_HOLD && (userDetails.account_type === EMPLOYER_SUPER_ADMIN || (userDetails.account_type === EMPLOYER_SUB_ADMIN && userDetails.id === job.job_created_by_id)) && (
                                                                                    <>
                                                                                        <NavDropdown.Item onClick={() => {
                                                                                            setEditConfirmHeaderText("You can not edit this job Reopen the job to edit");
                                                                                            setEditConfirmDialogShow(true);
                                                                                        }} > <LuPencilLine size={20} /> Edit</NavDropdown.Item>
                                                                                    </>
                                                                                )
                                                                            }

                                                                            <NavDropdown.Item onClick={() => navigate("/job/" + job._id + "/report")}> <MdReport size={20} /> Report</NavDropdown.Item>

                                                                        </NavDropdown>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ cursor: 'pointer' }} onClick={() => navigate(`/job/${job._id}/scheduled-profile`)}>
                                                                <div className="col-12">
                                                                    <p className="employer-dashboard-interview-job-title">Created by &nbsp; <span>{job.job_created_by_name}</span></p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ cursor: 'pointer' }} onClick={() => navigate(`/job/${job._id}/scheduled-profile`)}>
                                                                <div className="col-12">
                                                                    <p className="employer-dashboard-interview-job-title">Company &nbsp; <span>{job.company_name}</span></p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12 col-lg-5 col-12" style={{ cursor: 'pointer' }} onClick={() => navigate(`/job/${job._id}/scheduled-profile`)}>
                                                                    <p className="employer-dashboard-interview-job-title">BU / PO code &nbsp; <span>{job.bu_code}</span></p>
                                                                </div>
                                                                <div className="col-md-12 col-lg-7 col-12" style={{
                                                                    marginTop: '-8px'
                                                                }}>
                                                                    <div className="row">
                                                                        <div className="col-md-5 col-12 request-for-interviewer">
                                                                            <Button   onClick={() => job.status == JOB_STATUS_OPEN && (
                                                                                (userDetails.account_type == EMPLOYER_SUPER_ADMIN || userDetails.account_type == EMPLOYER_SUB_ADMIN) ? job.proposedInterviews.length > 0 : true) && navigate("/job/" + job._id + "/proposed-interviewers")} className={`employer-dashboard-interview-contact alljob ${job.status != JOB_STATUS_OPEN ? 'button-disable' : ''}`}>
                                                                                <SlUser size={16} variant="outline-primary" /><span>Request for interviewer</span>
                                                                            </Button>
                                                                        </div>
                                                                        <div className="col-md-5 col-12 text-center my-auto timestamp">
                                                                            <p className="employer-dashboard-interview-time">Created on {commonDateFormat(job.createdAt)}</p>
                                                                            <p className="employer-dashboard-interview-time">Modified on {commonDateFormat(job.updatedAt)}</p>
                                                                            <p className="employer-dashboard-interview-time">Modified By {job.job_modified_by_name}</p>
                                                                        </div>
                                                                        <div className="col-md-2 col-12 text-center my-auto">
                                                                            <p className={` ${job.status == JOB_STATUS_OPEN ? 'employer-dashboard-interview-today' : (job.status == JOB_STATUS_ON_HOLD ? 'employer-dashboard-interview-on-hold holdjob' : 'employer-dashboard-interview-closed closed-job')}`}>{job.status}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                })
                                            }
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>



                    </div>
                </div>
            </div>

            {
                showJobJD && !_.isUndefined(jobDetails) && !_.isNull(jobDetails) && !_.isUndefined(jobDetails._id) && !_.isNull(jobDetails._id) && (jobDetails._id !== '') &&
                <Modal centered show={showJobJD} onHide={() => { setShowJobJD(false) }} animation={false} size="xl">
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <Modal.Body>
                        <div className="row mt-4">
                            <div className="col-12 mb-2"><b>Job title: </b><span>{jobDetails.job_title}</span></div>
                            <div className="col-12 mb-2"><b>Primary Skill: </b><span>{jobDetails.primary_skill.join(", ")}</span></div>
                            <div className="col-12 mb-2"><b>Job created date: </b><span>{commonDateFormat(jobDetails.createdAt)}</span></div>
                            <div className="col-12 mb-2"><b>Job created by: </b><span>{jobDetails.job_created_by_name}</span></div>
                            {
                                jobDetails.upload_jd && jobDetails.upload_jd.includes('.doc') ?
                                    <iframe key="iframe-doc" style={{
                                        width: '100%',
                                        height: '80vh'
                                    }} src={`https://docs.google.com/gview?url=${jobDetails.upload_jd}&embedded=true`}></iframe> :
                                    <object key="object-other" data={jobDetails.upload_jd} style={{
                                        width: '100%',
                                        height: '80vh'
                                    }}></object>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            }
            {/* Edit job confirm Dialog */}
            <Dialog header={editConfirmHeaderText} visible={editConfirmDialogShow} style={{ width: '350px', textAlign: 'center' }} pt={{
                root: { className: 'dialog-root' },
                header: { className: 'dialog-header' },
                content: { className: 'dialog-content' },
                footer: { className: 'dialog-footer' },
                closeButton: { className: 'hide' }
            }} footer={editConfirmModalfooterContent} onHide={() => setEditConfirmDialogShow(false)} dismissableMask="true"></Dialog>
            {/* Status change dialog */}
            <Dialog header={dialogHeader} visible={dialogShow} style={{ width: '350px', textAlign: 'center' }} pt={{
                root: { className: 'dialog-root' },
                header: { className: 'dialog-header' },
                content: { className: 'dialog-content' },
                footer: { className: 'dialog-footer' },
                closeButton: { className: 'hide' }
            }} footer={statusChangeModalfooterContent} onHide={() => setDialogShow(false)} dismissableMask="true">
            </Dialog>
            {
                showUploadProfiles && !_.isUndefined(jobDetails) && !_.isNull(jobDetails) && !_.isUndefined(jobDetails._id) && !_.isNull(jobDetails._id) && (jobDetails._id !== '') &&

                <Dialog visible={showUploadProfiles} style={{ width: '90vw', textAlign: 'left', fontSize: '16px' }} pt={{
                    root: { className: 'dialog-root' },
                    header: { className: 'dialog-header' },
                    content: { className: 'dialog-content' },
                    footer: { className: 'dialog-footer' },
                    closeButton: { className: 'hide' }
                }} footer={uploadProfileModalfooterContent} onHide={() => setShowUploadProfiles(false)} dismissableMask="true">

                    <div className="row mt-4">
                        <div className="col-12 my-2 my-md-0 col-md-12 col-lg-6 col-xl-6">
                            <div className="row">
                                <div className="col-12 mb-2"><small>Job title: </small><span className='fs-5 fw-bold'>{jobDetails.job_title}</span></div>
                                <div className="col-12 mb-2"><small>Primary Skill: </small><span className='fs-5 fw-bold mx-2'>{jobDetails.primary_skill}</span></div>
                                <div className="col-12 mb-2"><small>Job created date: </small><span className='fs-5 fw-bold'>{commonDateFormat(jobDetails.createdAt)}</span></div>
                                <div className="col-12 mb-2"><small>Job created by: </small><span className='fs-5 fw-bold'>{jobDetails.job_created_by_name}</span></div>
                            </div>
                        </div>
                        <div className="col-12 my-2 my-md-0 col-md-12 col-lg-6 col-xl-6 ">
                            <div className='upload-profile-resume-cv'>
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                                        <input style={{ display: 'none' }} type="file" id="upload_profile" name="upload_profile" onChange={uploadFiles} placeholder="Upload Profile" multiple ref={browserResumesFileRef} />
                                        <div className="file-upload-container mt-2" onClick={() => browserResumesFileRef.current.click()}>
                                            <div className="custom-file-upload">
                                                Choose file
                                            </div>
                                            <div className="file-name">{uploadedCandidatebulkFileName ?? "No file chosen"}</div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                                        <input style={{ display: 'none' }} type="file" id="upload_csv" name="upload_csv" onChange={uploadCSV} placeholder="CSV" accept=".csv" ref={browserCSVFileRef} />
                                        <div className="file-upload-container mt-2" onClick={() => browserCSVFileRef.current.click()}>
                                            <div className="custom-file-upload">
                                                Choose file
                                            </div>
                                            <div className="file-name">{uploadedCandidateCSVFileName ?? "No file chosen"}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <small style={{ fontSize: '12px' }}>*Maximum 25 profile can be uploaded at once</small>
                                    </div>
                                    <div className="col-6">
                                        <small style={{ fontSize: '12px' }}>*Upload CSV in a specific format <a href={Sample_CSV_format} download>Sample CSV format</a></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 col-lg-2">
                            <small className='fs-5'>Candidate Name</small>
                        </div>
                        <div className="col-12 col-lg-2">
                            <small className='fs-5'>Phone Number</small>
                        </div>
                        <div className="col-12 col-lg-2">
                            <small className='fs-5'>Email Address</small>
                        </div>
                        <div className="col-12 col-lg-2">
                            <small className='fs-5'>Available on</small>
                        </div>
                        <div className="col-12 col-lg-2">
                            <small className='fs-5'>Resume</small>
                        </div>
                        <div className="col-1">
                            <small className='fs-5'>Delete</small>
                        </div>
                    </div>

                    {
                        _.map(candidateProfileData, (candidate, index) => {
                            return <div className="row mb-3" key={"candidateProfileData_" + index}>
                                <div className="col-2">
                                    <InputText style={{
                                        width: '100%'
                                    }} name="candidate_name" value={candidate.candidate_name} placeholder="Candidate Name" onChange={(event) => profileChangeHandler(index, event)} maxLength={50} autoComplete="off" keyfilter={/^[A-Za-z\s]*$/} />
                                    {
                                        !_.isUndefined(errors) && !_.isNull(errors) && errors.length > 0 && (
                                            getProfileErrorMessage(candidate.uniqueId, 'candidate_name') ?
                                                <Alert variant="danger" className="mt-2">
                                                    <small>{getProfileErrorMessage(candidate.uniqueId, 'candidate_name')}</small>
                                                </Alert> :
                                                ""
                                        )
                                    }
                                </div>
                                <div className="col-2">
                                    <InputText style={{
                                        width: '100%'
                                    }} name="candidate_mobile_number" value={candidate.candidate_mobile_number} placeholder="Mobile Number" onChange={(event) => profileChangeHandler(index, event)} minLength={10} maxLength={10} autoComplete="off" keyfilter="int" />
                                    {
                                        !_.isUndefined(errors) && !_.isNull(errors) && errors.length > 0 && (
                                            getProfileErrorMessage(candidate.uniqueId, 'candidate_mobile_number') ?
                                                <Alert variant="danger" className="mt-2">
                                                    <small>{getProfileErrorMessage(candidate.uniqueId, 'candidate_mobile_number')}</small>
                                                </Alert> :
                                                ""
                                        )
                                    }
                                </div>
                                <div className="col-3">
                                    <InputText style={{
                                        width: '100%'
                                    }} name="candidate_email" value={candidate.candidate_email} placeholder="Email Address" onChange={(event) => profileChangeHandler(index, event)} maxLength={50} autoComplete="off" />
                                    {
                                        !_.isUndefined(errors) && !_.isNull(errors) && errors.length > 0 && (
                                            getProfileErrorMessage(candidate.uniqueId, 'candidate_email') ?
                                                <Alert variant="danger" className="mt-2">{getProfileErrorMessage(candidate.uniqueId, 'candidate_email')}</Alert> :
                                                ""
                                        )
                                    }
                                </div>
                                <div className="col-2">
                                    <DatePicker className="form-control" placeholderText="dd/MM/yyyy hh:mm a" showTimeSelect selected={new Date(candidate.available_on)} minDate={new Date()} dateFormat="dd/MM/yyyy hh:mm a" onChange={(date) => changeDateHandler(index, date)} />
                                    {
                                        !_.isUndefined(errors) && !_.isNull(errors) && errors.length > 0 && (
                                            getProfileErrorMessage(candidate.uniqueId, 'available_on') ?
                                                <Alert variant="danger" className="mt-2">{getProfileErrorMessage(candidate.uniqueId, 'candidate_email')}</Alert> :
                                                ""
                                        )
                                    }
                                </div>
                                <div className="col-2">
                                    {
                                        candidate.resume == "" ? (
                                            <FileUpload ref={resumeUploadRef} mode="basic" chooseLabel="Upload Resume" name="resume" accept="application/*" maxFileSize={1000000} customUpload onSelect={(event) => uploadResumeHandler(index, event)} />
                                        ) : <div>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>Candidate Resume</Tooltip>}
                                            >
                                                <a target="_blank" href={candidate.resume} download>
                                                    {(new URL(candidate.resume).pathname.split('/').pop().replaceAll("%20", " "))}
                                                </a>
                                            </OverlayTrigger>
                                            &nbsp;&nbsp;
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>Candidate Resume</Tooltip>}
                                            >
                                                <button className='btn'>
                                                    <IoClose style={{
                                                        cursor: 'pointer',
                                                        color: 'red'
                                                    }} size={30} onClick={() => {
                                                        let newCandidateProfileData = [...candidateProfileData];
                                                        newCandidateProfileData[index]['resume'] = "";
                                                        setCandidateProfileData(newCandidateProfileData);
                                                    }} />
                                                </button>
                                            </OverlayTrigger>
                                        </div>
                                    }
                                    {
                                        !_.isUndefined(errors) && !_.isNull(errors) && errors.length > 0 && (
                                            getProfileErrorMessage(candidate.uniqueId, 'resume') ?
                                                <Alert variant="danger" className="mt-2">{getProfileErrorMessage(candidate.uniqueId, 'resume')}</Alert> :
                                                ""
                                        )
                                    }
                                </div>
                                <div className="col-1 text-center">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Remove Profile</Tooltip>}
                                    >
                                        <div style={{
                                            background: '#f3ebe9',
                                            borderRadius: '50%',
                                            width: '48px',
                                            textAlign: 'center',
                                            paddingTop: '12px',
                                            height: '48px'
                                        }}>
                                            <i style={{
                                                cursor: 'pointer',
                                                fontSize: '1.5rem'
                                            }} onClick={() => removeCandidateProfile(index)} className="pi pi-times" ></i>
                                        </div>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        })
                    }


                </Dialog>
            }
            {/* delete job dialog */}
            <Dialog header="Do you want to delete the job?" visible={deleteJobModal} style={{ width: '350px', textAlign: 'center' }} pt={{
                root: { className: 'dialog-root' },
                header: { className: 'dialog-header' },
                content: { className: 'dialog-content' },
                footer: { className: 'dialog-footer' },
                closeButton: { className: 'hide' }
            }} footer={deleteJobModalfooterContent} onHide={() => closeDeleteJobModal()} dismissableMask="true">
            </Dialog>
        </Fragment>
    )
}
export default DashboardScreen;