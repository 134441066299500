import React, { useState, useEffect, Fragment, useRef } from "react";
import _ from "lodash";
import Loader from "react-js-loader";
import { NavLink, useNavigate } from "react-router-dom";
import faster_hiring from "../../Assets/faster_hiring.png";
import candidate from "../../Assets/candidate.svg";
import completed from "../../Assets/completed.svg";
import interviewers from "../../Assets/interviewers.svg";
import registered from "../../Assets/registered.svg";
import qoute from "../../Assets/quote.svg";
import rala_portal_logo from "../../Assets/rala_infotech_color.svg";
import { Form, Button, Modal, Alert, InputGroup } from "react-bootstrap";
import Select from "react-select";
import "./InterviewerAccountScreen.css";
import {
  API_URL,
  allowedResumeTypes,
  hasNumber,
  resumeFileTypeErrorMessage,
} from "../../common/constant";
import { INTERVIEWER } from "../../common/roles";
import { ReactTags } from "react-tag-autocomplete";
import { Toast } from "primereact/toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const InterviewerAccountScreen = () => {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [showTermsConditions, setShowTermsConditions] = useState(false);
  const [firstNameError, setIsFirstNameError] = useState("");
  const [lastNameError, setIsLastNameError] = useState("");
  const [emailError, setIsEmailError] = useState("");
  const [passwordError, setIsPasswordError] = useState("");
  const [confirmPasswordError, setIsConfirmPasswordError] = useState("");
  const [mobileNumberError, setIsMobileNumberError] = useState("");
  const [designationError, setIsDesignationError] = useState("");
  const [primarySkillError, setIsPrimarySkillError] = useState("");
  const [secondarySkillError, setIsSecondarySkillError] = useState("");
  const [countryError, setIsCountryError] = useState("");
  const [stateError, setIsStateError] = useState("");
  const [cityError, setIsCityError] = useState("");
  const [fileError, setIsFileError] = useState("");
  const [termsConditionsError, setIsTermsConditionsError] = useState("");
  const [countryCodes, setCountryCodes] = useState("+91");

  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);

  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  const [user, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobileNumber: "",
    designation: "",
    primarySkill: [],
    secondarySkill: [],
    country: 0,
    state: 0,
    city: 0,
    file: "",
    termsConditions: false,
  });

  const toast = useRef(null);
  useEffect(() => {
    if (
      !_.isUndefined(countryOptions) &&
      !_.isNull(countryOptions) &&
      countryOptions.length === 0
    ) {
      getCountryList();
      getPositions();
      getSkills();
    }
  }, [countryOptions]);

  const getCountryList = () => {
    fetch(`${API_URL}api/countries`)
      .then((response) => response.json())
      .then((response) => {
        if (
          !_.isUndefined(response) &&
          !_.isNull(response) &&
          !_.isUndefined(response.data) &&
          !_.isNull(response.data) &&
          response.data.length > 0
        ) {
          const transformedOptions = response.data.map((option) => ({
            value: option.country_id,
            label: option.name,
            country_code: option.country_code,
          }));
          setCountryOptions(transformedOptions);
        }
      })
      .catch((err) => {
        setCountryOptions([]);
      });
  };

  const getStateList = (country_id) => {
    fetch(`${API_URL}api/states/${country_id}`)
      .then((response) => response.json())
      .then((response) => {
        if (
          !_.isUndefined(response) &&
          !_.isNull(response) &&
          !_.isUndefined(response.data) &&
          !_.isNull(response.data) &&
          response.data.length > 0
        ) {
          const transformedOptions = response.data.map((option) => ({
            value: option.state_id,
            label: option.name,
          }));
          setStateOptions(transformedOptions);
        }
      })
      .catch((err) => {
        setStateOptions([]);
      });
  };

  const getCityList = (state_id) => {
    fetch(`${API_URL}api/cities/${state_id}`)
      .then((response) => response.json())
      .then((response) => {
        if (
          !_.isUndefined(response) &&
          !_.isNull(response) &&
          !_.isUndefined(response.data) &&
          !_.isNull(response.data) &&
          response.data.length > 0
        ) {
          const transformedOptions = response.data.map((option) => ({
            value: option.city_id,
            label: option.name,
          }));
          setCityOptions(transformedOptions);
        }
      })
      .catch((err) => {
        setCityOptions([]);
      });
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "termsConditions") {
      setUserDetails({
        ...user,
        termsConditions: e.target.checked,
      });
    } else {
      setUserDetails({
        ...user,
        [name]: value,
      });
    }
  };

  const selectChangeHandler = (e, name) => {
    const { value } = e;
    if (name == "designation") {
      const { label, value } = e;
      setUserDetails({
        ...user,
        [name]: label,
      });
    } else {
      setUserDetails({
        ...user,
        [name]: value,
      });
    }
    if (name === "country") {
      getStateList(value);
      const item = countryOptions.find((item) => item.value == value);
      if (item) {
        setCountryCodes(item.country_code);
      }
    }
    if (name === "state") {
      getCityList(value);
    }
  };
  const validateForm = () => {
    const emailregex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const textregex = /[A-Za-z]/;
    const numberregex = /[0-9]/;
    if (
      _.isUndefined(user.firstName) ||
      _.isNull(user.firstName) ||
      user.firstName === "" ||
      user.firstName.trim() === ""
    ) {
      setIsFirstNameError("First Name is required!");
    } else if (
      !_.isUndefined(user.firstName) &&
      !_.isNull(user.firstName) &&
      user.firstName !== "" &&
      user.firstName.trim() !== "" &&
      hasNumber(user.firstName)
    ) {
      setIsFirstNameError("First Name is only allow alphabets!");
    } else if (
      !_.isUndefined(user.firstName) &&
      !_.isNull(user.firstName) &&
      user.firstName !== "" &&
      user.firstName.trim() !== "" &&
      (user.firstName.trim().length < 4 || user.firstName.trim().length > 50)
    ) {
      setIsFirstNameError("First Name must be between 4 to 11 characters!");
    } else {
      setIsFirstNameError("");
    }
    if (
      _.isUndefined(user.lastName) ||
      _.isNull(user.lastName) ||
      user.lastName === "" ||
      user.lastName.trim() === ""
    ) {
      setIsLastNameError("Last Name is required!");
    } else if (
      !_.isUndefined(user.lastName) &&
      !_.isNull(user.lastName) &&
      user.lastName !== "" &&
      user.lastName.trim() !== "" &&
      hasNumber(user.lastName)
    ) {
      setIsLastNameError("Last Name is only allow alphabets!");
    } else if (
      !_.isUndefined(user.lastName) &&
      !_.isNull(user.lastName) &&
      user.lastName !== "" &&
      user.lastName.trim() !== "" &&
      (user.lastName.trim().length < 4 || user.lastName.trim().length > 50)
    ) {
      setIsLastNameError("Last Name must be between 4 to 11 characters!");
    } else {
      setIsLastNameError("");
    }
    if (
      _.isUndefined(user.email) ||
      _.isNull(user.email) ||
      user.email === "" ||
      user.email.trim() === ""
    ) {
      setIsEmailError("Email Address is required!");
    } else if (
      !_.isUndefined(user.email) &&
      !_.isNull(user.email) &&
      user.email !== "" &&
      user.email.trim() !== "" &&
      !emailregex.test(user.email)
    ) {
      setIsEmailError("Please enter a valid email address!");
    } else if (
      !_.isUndefined(user.email) &&
      !_.isNull(user.email) &&
      user.email !== "" &&
      user.email.trim() !== "" &&
      (user.email.trim().length < 15 || user.email.trim().length > 150)
    ) {
      setIsEmailError("Email address must be between 15 to 150 characters!");
    } else {
      setIsEmailError("");
    }
    if (
      _.isUndefined(user.password) ||
      _.isNull(user.password) ||
      user.password === "" ||
      user.password.trim() === ""
    ) {
      setIsPasswordError("Password is required!");
    } else if (
      !_.isUndefined(user.password) &&
      !_.isNull(user.password) &&
      user.password !== "" &&
      user.password.trim() !== "" &&
      (user.password.trim().length < 6 || user.password.trim().length > 25)
    ) {
      setIsPasswordError("Password must be between 6 to 25 characters!");
    } else {
      setIsPasswordError("");
    }

    const uppercasePattern = /[A-Z]/;
    const lowercasePattern = /[a-z]/;
    const numericPattern = /[0-9]/;
    const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;

    if (!uppercasePattern.test(user.password)) {
      setIsPasswordError(
        "Password must contain at least one uppercase letter, one lowercase letter, one numeric character, and one special character."
      );
      return false;
    }

    if (!lowercasePattern.test(user.password)) {
      setIsPasswordError(
        "Password must contain at least one uppercase letter, one lowercase letter, one numeric character, and one special character."
      );
      return false;
    }

    if (!numericPattern.test(user.password)) {
      setIsPasswordError(
        "Password must contain at least one uppercase letter, one lowercase letter, one numeric character, and one special character."
      );
      return false;
    }

    if (!specialCharPattern.test(user.password)) {
      setIsPasswordError(
        "Password must contain at least one uppercase letter, one lowercase letter, one numeric character, and one special character."
      );
      return false;
    }

    if (
      _.isUndefined(user.confirmPassword) ||
      _.isNull(user.confirmPassword) ||
      user.confirmPassword === "" ||
      user.confirmPassword.trim() === ""
    ) {
      setIsConfirmPasswordError("Confirm Password is required!");
    } else if (
      !_.isUndefined(user.confirmPassword) &&
      !_.isNull(user.confirmPassword) &&
      user.confirmPassword !== "" &&
      user.confirmPassword.trim() !== "" &&
      (user.confirmPassword.trim().length < 6 ||
        user.confirmPassword.trim().length > 25)
    ) {
      setIsConfirmPasswordError(
        "Confirm Password must be between 6 to 25 characters!"
      );
    } else if (user.password !== user.confirmPassword) {
      setIsConfirmPasswordError(
        "Confirm Password does not match with Password!"
      );
    } else {
      setIsConfirmPasswordError("");
    }
    if (
      _.isUndefined(user.mobileNumber) ||
      _.isNull(user.mobileNumber) ||
      user.mobileNumber === "" ||
      user.mobileNumber.trim() === ""
    ) {
      setIsMobileNumberError("Mobile Number is required!");
    } else if (
      !_.isUndefined(user.mobileNumber) &&
      !_.isNull(user.mobileNumber) &&
      user.mobileNumber !== "" &&
      user.mobileNumber.trim() !== "" &&
      !numberregex.test(user.mobileNumber)
    ) {
      setIsMobileNumberError("Mobile Number is only allow digit!");
    } else if (
      !_.isUndefined(user.mobileNumber) &&
      !_.isNull(user.mobileNumber) &&
      user.mobileNumber !== "" &&
      user.mobileNumber.trim() !== "" &&
      (user.mobileNumber.trim().length < 10 ||
        user.mobileNumber.trim().length > 10)
    ) {
      setIsMobileNumberError("Mobile Number must be 10 digit!");
    } else {
      setIsMobileNumberError("");
    }
    if (
      _.isUndefined(user.designation) ||
      _.isNull(user.designation) ||
      user.designation === "" ||
      user.designation.trim() === ""
    ) {
      setIsDesignationError("Designation is required!");
    } else {
      setIsDesignationError("");
    }
    if (
      _.isUndefined(user.primarySkill) ||
      _.isNull(user.primarySkill) ||
      user.primarySkill === "" ||
      user.primarySkill.length == 0
    ) {
      setIsPrimarySkillError("Primary Skill is required!");
    } else {
      setIsPrimarySkillError("");
    }
    if (
      _.isUndefined(user.secondarySkill) ||
      _.isNull(user.secondarySkill) ||
      user.secondarySkill === "" ||
      user.secondarySkill.length == 0
    ) {
      setIsSecondarySkillError("Secondary Skill is required!");
    } else {
      setIsSecondarySkillError("");
    }
    if (
      _.isUndefined(user.country) ||
      _.isNull(user.country) ||
      user.country === 0
    ) {
      setIsCountryError("Country is required!");
    } else {
      setIsCountryError("");
    }
    if (_.isUndefined(user.state) || _.isNull(user.state) || user.state === 0) {
      setIsStateError("State is required!");
    } else {
      setIsStateError("");
    }
    if (_.isUndefined(user.city) || _.isNull(user.city) || user.city === 0) {
      setIsCityError("City is required!");
    } else {
      setIsCityError("");
    }
    if (_.isUndefined(user.file) || _.isNull(user.file) || user.file === "") {
      setIsFileError("File is required!");
    } else {
      setIsFileError("");
    }
    if (
      _.isUndefined(user.termsConditions) ||
      _.isNull(user.termsConditions) ||
      user.termsConditions === false
    ) {
      setIsTermsConditionsError("Terms & Conditions is required!");
    } else {
      setIsTermsConditionsError("");
    }
  };

  const checkedValidateForm = () => {
    const emailregex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const textregex = /[A-Za-z]/;
    const numberregex = /[0-9]/;
    if (
      _.isUndefined(user.firstName) ||
      _.isNull(user.firstName) ||
      user.firstName === "" ||
      user.firstName.trim() === ""
    ) {
      return false;
    } else if (
      !_.isUndefined(user.firstName) &&
      !_.isNull(user.firstName) &&
      user.firstName !== "" &&
      user.firstName.trim() !== "" &&
      hasNumber(user.firstName)
    ) {
      return false;
    } else if (
      !_.isUndefined(user.firstName) &&
      !_.isNull(user.firstName) &&
      user.firstName !== "" &&
      user.firstName.trim() !== "" &&
      (user.firstName.trim().length < 4 || user.firstName.trim().length > 50)
    ) {
      return false;
    }
    if (
      _.isUndefined(user.lastName) ||
      _.isNull(user.lastName) ||
      user.lastName === "" ||
      user.lastName.trim() === ""
    ) {
      return false;
    } else if (
      !_.isUndefined(user.lastName) &&
      !_.isNull(user.lastName) &&
      user.lastName !== "" &&
      user.lastName.trim() !== "" &&
      hasNumber(user.lastName)
    ) {
      return false;
    } else if (
      !_.isUndefined(user.lastName) &&
      !_.isNull(user.lastName) &&
      user.lastName !== "" &&
      user.lastName.trim() !== "" &&
      (user.lastName.trim().length < 4 || user.lastName.trim().length > 50)
    ) {
      return false;
    }
    if (
      _.isUndefined(user.email) ||
      _.isNull(user.email) ||
      user.email === "" ||
      user.email.trim() === ""
    ) {
      return false;
    } else if (
      !_.isUndefined(user.email) &&
      !_.isNull(user.email) &&
      user.email !== "" &&
      user.email.trim() !== "" &&
      !emailregex.test(user.email)
    ) {
      return false;
    } else if (
      !_.isUndefined(user.email) &&
      !_.isNull(user.email) &&
      user.email !== "" &&
      user.email.trim() !== "" &&
      (user.email.trim().length < 15 || user.email.trim().length > 150)
    ) {
      return false;
    }
    if (
      _.isUndefined(user.password) ||
      _.isNull(user.password) ||
      user.password === "" ||
      user.password.trim() === ""
    ) {
      return false;
    } else if (
      !_.isUndefined(user.password) &&
      !_.isNull(user.password) &&
      user.password !== "" &&
      user.password.trim() !== "" &&
      (user.password.trim().length < 6 || user.password.trim().length > 25)
    ) {
      return false;
    }

    const uppercasePattern = /[A-Z]/;
    const lowercasePattern = /[a-z]/;
    const numericPattern = /[0-9]/;
    const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;

    if (!uppercasePattern.test(user.password)) {
      return false;
    }

    if (!lowercasePattern.test(user.password)) {
      return false;
    }

    if (!numericPattern.test(user.password)) {
      return false;
    }

    if (!specialCharPattern.test(user.password)) {
      return false;
    }

    if (
      _.isUndefined(user.confirmPassword) ||
      _.isNull(user.confirmPassword) ||
      user.confirmPassword === "" ||
      user.confirmPassword.trim() === ""
    ) {
      return false;
    } else if (
      !_.isUndefined(user.confirmPassword) &&
      !_.isNull(user.confirmPassword) &&
      user.confirmPassword !== "" &&
      user.confirmPassword.trim() !== "" &&
      (user.confirmPassword.trim().length < 6 ||
        user.confirmPassword.trim().length > 25)
    ) {
      return false;
    } else if (user.password !== user.confirmPassword) {
      return false;
    }
    if (
      _.isUndefined(user.mobileNumber) ||
      _.isNull(user.mobileNumber) ||
      user.mobileNumber === "" ||
      user.mobileNumber.trim() === ""
    ) {
      return false;
    } else if (
      !_.isUndefined(user.mobileNumber) &&
      !_.isNull(user.mobileNumber) &&
      user.mobileNumber !== "" &&
      user.mobileNumber.trim() !== "" &&
      !numberregex.test(user.mobileNumber)
    ) {
      return false;
    } else if (
      !_.isUndefined(user.mobileNumber) &&
      !_.isNull(user.mobileNumber) &&
      user.mobileNumber !== "" &&
      user.mobileNumber.trim() !== "" &&
      (user.mobileNumber.trim().length < 10 ||
        user.mobileNumber.trim().length > 10)
    ) {
      return false;
    }
    if (
      _.isUndefined(user.designation) ||
      _.isNull(user.designation) ||
      user.designation === "" ||
      user.designation.trim() === ""
    ) {
      return false;
    }
    if (
      _.isUndefined(user.primarySkill) ||
      _.isNull(user.primarySkill) ||
      user.primarySkill === "" ||
      user.primarySkill.length === 0
    ) {
      return false;
    }
    if (
      _.isUndefined(user.secondarySkill) ||
      _.isNull(user.secondarySkill) ||
      user.secondarySkill === "" ||
      user.secondarySkill.length === 0
    ) {
      return false;
    }
    if (
      _.isUndefined(user.country) ||
      _.isNull(user.country) ||
      user.country === 0
    ) {
      return false;
    }
    if (_.isUndefined(user.state) || _.isNull(user.state) || user.state === 0) {
      return false;
    }
    if (_.isUndefined(user.city) || _.isNull(user.city) || user.city === 0) {
      return false;
    }
    if (_.isUndefined(user.file) || _.isNull(user.file) || user.file === "") {
      return false;
    }
    if (
      _.isUndefined(user.termsConditions) ||
      _.isNull(user.termsConditions) ||
      user.termsConditions === false
    ) {
      return false;
    }
    return true;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    validateForm();
    if (checkedValidateForm()) {
      setIsSubmit(true);
      let params = {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        password: user.password,
        mobile_number: user.mobileNumber,
        country_code: countryCodes,
        account_type: INTERVIEWER,
        country_id: user.country,
        state_id: user.state,
        city_id: user.city,
        designation: user.designation,
        primary_skill:
          user.primarySkill.length > 0
            ? user.primarySkill.map((item) => item.value)
            : [],
        secondary_skill:
          user.secondarySkill.length > 0
            ? user.secondarySkill.map((item) => item.value)
            : [],
        terms_and_conditions: user.termsConditions,
        resume: user.file,
      };
      fetch(`${API_URL}api/register`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      })
        .then((response) => response.json())
        .then((response) => {
          setIsSubmit(false);
          if (
            !_.isUndefined(response) &&
            !_.isNull(response) &&
            !_.isUndefined(response.status) &&
            !_.isNull(response.status) &&
            response.status === 200 &&
            !_.isUndefined(response.message) &&
            !_.isNull(response.message) &&
            response.message !== ""
          ) {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: response.message,
              life: 2000,
            });
            setTimeout(() => {
              navigate("/sign-in");
            }, 2000);
          } else if (
            !_.isUndefined(response) &&
            !_.isNull(response) &&
            !_.isUndefined(response.message) &&
            !_.isNull(response.message) &&
            response.message !== ""
          ) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: response.message,
              life: 3000,
            });
          }
        })
        .catch((error) => {
          setIsSubmit(false);
          if (
            !_.isUndefined(error) &&
            !_.isNull(error) &&
            !_.isUndefined(error.message) &&
            !_.isNull(error.message) &&
            error.message !== ""
          ) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    }
  };
  const uploadResumeHandler = (event) => {
    if (allowedResumeTypes.includes(event.target.files[0].type)) {
      setIsSubmit(true);
      const data = new FormData();
      data.append("file", event.target.files[0]);
      fetch(`${API_URL}api/uploadFiles`, {
        method: "POST",
        body: data,
      })
        .then((response) => response.json())
        .then((response) => {
          if (
            !_.isUndefined(response) &&
            !_.isNull(response) &&
            !_.isUndefined(response.status) &&
            !_.isNull(response.status) &&
            response.status === 200 &&
            !_.isUndefined(response.message) &&
            !_.isNull(response.message) &&
            response.message !== ""
          ) {
            setUserDetails({
              ...user,
              file: response.filepath,
            });
          } else if (
            !_.isUndefined(response) &&
            !_.isNull(response) &&
            !_.isUndefined(response.message) &&
            !_.isNull(response.message) &&
            response.message !== ""
          ) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: response.message,
              life: 3000,
            });
          }
        })
        .catch((err) => {
          console.log("error", err);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: err.message,
            life: 3000,
          });
        })
        .finally(() => {
          setIsSubmit(false);
        });
    } else {
      event.target.value = "";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: resumeFileTypeErrorMessage,
        life: 3000,
      });
    }
  };

  const getPositions = () => {
    fetch(`${API_URL}api/getPositions`)
      .then((response) => response.json())
      .then((response) => {
        const transformedOptions = response.data.map((option) => ({
          name: option.name,
          value: option.name,
          label: option.name,
        }));
        setDesignationOptions(transformedOptions);
      })
      .catch((error) => {
        setDesignationOptions([]);
      });
  };

  const [skillList, setSkillList] = useState([]);

  const getSkills = () => {
    fetch(`${API_URL}api/getSkills`)
      .then((response) => response.json())
      .then((response) => {
        var allSkills = [];
        response.data.map((item) => {
          allSkills.push({
            name: item.name,
            value: item.name,
            label: item.name,
          });
        });
        setSkillList(allSkills);
      })
      .catch((error) => {
        setSkillList([]);
      });
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      {isSubmit && (
        <div className="spinner-loader">
          <Loader
            type="spinner-circle"
            bgColor="#2F2F74"
            color="#2F2F74"
            size={100}
          />
        </div>
      )}
      <div className="d-flex align-items-md-center h-100">
        <div className="container-fluid h-100">
          <div className="row d-flex h-100">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-center d-none d-lg-block dynamic_hiring">
              <div className="d-flex justify-content-center flex-column h-100 px-4">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-start pt-5 tilt">
                    <div className="icon-wrap">
                      <img src={candidate} alt="candidate-icon" />
                    </div>
                    <div className="fw-bold display-5 text-white text-uppercase mt-4">
                      600+
                    </div>
                    <div className="fw-bold text-uppercase text_highlite mt-3">
                      CANDIDATES REGISTERED
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-start pt-5 tilt">
                    <div className="icon-wrap">
                      <img src={completed} alt="completed-icon" />
                    </div>
                    <div className="fw-bold display-5 text-white text-uppercase mt-4">
                      12000+
                    </div>
                    <div className="fw-bold text-uppercase text_highlite mt-3">
                      COMPLETED INTERVIEWS
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-start pt-5 tilt">
                    <div className="icon-wrap">
                      <img src={registered} alt="registered-icon" />
                    </div>
                    <div className="fw-bold display-5 text-white text-uppercase mt-4">
                      18+
                    </div>
                    <div className="fw-bold text-uppercase text_highlite mt-3">
                      COMPANIES REGISTERED
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-start pt-5 tilt">
                    <div className="icon-wrap">
                      <img src={interviewers} alt="interviewers-icon" />
                    </div>
                    <div className="fw-bold display-5 text-white text-uppercase mt-4">
                      1856+
                    </div>
                    <div className="fw-bold text-uppercase text_highlite mt-3">
                      INTERVIEWERS
                    </div>
                  </div>
                </div>
                <div className="row testimonials pt-2">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-16 text-start pt-5">
                    <img src={qoute} className="mb-4" />
                    <p className="text_highlite">
                      The technical interviews conducted by Rala Infotech were thorough and insightful. Their panel’s expertise in Java and microservices was particularly valuable, and their feedback helped us make informed hiring decisions. The free mock interviews were a great way to experience their quality firsthand
                    </p>
                    <div className="text-white text-uppercase fw-bold">
                      Sreenath Prabhu
                    </div>
                    <div className="text_highlite_t">Accion Labs India</div>
                  </div>
                  <div className="carousel-bullets mt-3">
                    <div className="bullet active"></div>
                    <div className="bullet"></div>
                    <div className="bullet"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 m-auto">
              <div className="row justify-content-md-center">

                <div className="col-sm-12 col-md-8 col-lg-9 col-xl-7 col-xxl-6">
                  <div className="d-flex justify-content-center logo mb-5"><img
                    src={rala_portal_logo}
                    alt="Rala Portal"

                  /></div>
                  <Form className="mt-4" autoComplete="off">
                    <div className="row">
                      <div className="col-10">
                        <p className="page-title">Sign Up as Interviewer</p>
                      </div>
                      <div className="col-2 text-end">
                        <NavLink to="/sign-in" className="login">
                          Back
                        </NavLink>
                      </div>
                    </div>
                    <Form.Group className="mb-3" controlId="firstName">
                      <Form.Control
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        onChange={changeHandler}
                        maxLength={50}
                        autoComplete="off"
                        pattern="[A-Za-z]"
                      />
                      {!_.isUndefined(firstNameError) &&
                        !_.isNull(firstNameError) &&
                        firstNameError !== "" && (
                          <Alert variant="danger" className="mt-2">
                            {firstNameError}
                          </Alert>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="lastName">
                      <Form.Control
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        onChange={changeHandler}
                        maxLength={50}
                        autoComplete="off"
                        pattern="[A-Za-z]"
                      />
                      {!_.isUndefined(lastNameError) &&
                        !_.isNull(lastNameError) &&
                        lastNameError !== "" && (
                          <Alert variant="danger" className="mt-2">
                            {lastNameError}
                          </Alert>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="email">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        onChange={changeHandler}
                        maxLength={150}
                        autoComplete="off"
                      />
                      {!_.isUndefined(emailError) &&
                        !_.isNull(emailError) &&
                        emailError !== "" && (
                          <Alert variant="danger" className="mt-2">
                            {emailError}
                          </Alert>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="password">
                      <InputGroup className="mb-3">
                        <Form.Control
                          type={passwordType}
                          id="password"
                          name="password"
                          placeholder="Password"
                          onChange={changeHandler}
                          maxLength={25}
                          autoComplete="off"
                        />
                        <InputGroup.Text
                          onClick={() =>
                            setPasswordType(
                              passwordType == "password" ? "text" : "password"
                            )
                          }
                          id="basic-addon1"
                        >
                          {passwordType == "password" ? (
                            <FaEyeSlash />
                          ) : (
                            <FaEye />
                          )}{" "}
                        </InputGroup.Text>
                      </InputGroup>
                      {!_.isUndefined(passwordError) &&
                        !_.isNull(passwordError) &&
                        passwordError !== "" && (
                          <Alert variant="danger" className="mt-2">
                            {passwordError}
                          </Alert>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="confirmPassword">
                      <InputGroup className="mb-3">
                        <Form.Control
                          type={confirmPasswordType}
                          id="confirmPassword"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          onChange={changeHandler}
                          maxLength={25}
                          autoComplete="off"
                        />
                        <InputGroup.Text
                          onClick={() =>
                            setConfirmPasswordType(
                              confirmPasswordType == "password"
                                ? "text"
                                : "password"
                            )
                          }
                          id="basic-addon1"
                        >
                          {confirmPasswordType == "password" ? (
                            <FaEyeSlash />
                          ) : (
                            <FaEye />
                          )}{" "}
                        </InputGroup.Text>
                      </InputGroup>
                      {!_.isUndefined(confirmPasswordError) &&
                        !_.isNull(confirmPasswordError) &&
                        confirmPasswordError !== "" && (
                          <Alert variant="danger" className="mt-2">
                            {confirmPasswordError}
                          </Alert>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="mobileNumber">
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon3">
                          {countryCodes}
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          name="mobileNumber"
                          placeholder="Mobile Number"
                          onChange={changeHandler}
                          maxLength={10}
                          autoComplete="off"
                          pattern="[0-9]"
                        />
                      </InputGroup>
                      {!_.isUndefined(mobileNumberError) &&
                        !_.isNull(mobileNumberError) &&
                        mobileNumberError !== "" && (
                          <Alert variant="danger" className="mt-2">
                            {mobileNumberError}
                          </Alert>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="designation">
                      <ReactTags
                        labelText="Select designation"
                        selected={user.designation === "" || user.designation === null ? [] : [{ name: user.designation, value: user.designation, label: user.designation }]}
                        suggestions={designationOptions}
                        allowNew={true}
                        onAdd={(selectedList) => {
                          setUserDetails({
                            ...user,
                            designation: selectedList.value
                          });
                        }}
                        onDelete={(selectedList) => {
                          setUserDetails({
                            ...user,
                            designation: ""
                          });
                        }}
                        placeholderText="Designation"
                        noOptionsText="No Matching designation"
                      />
                      {!_.isUndefined(designationError) &&
                        !_.isNull(designationError) &&
                        designationError !== "" && (
                          <Alert variant="danger" className="mt-2">
                            {designationError}
                          </Alert>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="primarySkill">
                      <ReactTags
                        labelText="Select Primary Skills"
                        selected={user.primarySkill}
                        suggestions={skillList}
                        allowNew={true}
                        onAdd={(selectedList) => {
                          setUserDetails({
                            ...user,
                            primarySkill: [...user.primarySkill, selectedList],
                          });
                        }}
                        onDelete={(selectedList) => {
                          setUserDetails({
                            ...user,
                            primarySkill: user.primarySkill.filter(
                              (_, i) => i !== selectedList
                            ),
                          });
                        }}
                        placeholderText="Primary Skill"
                        noOptionsText="No Matching Primary Skill"
                      />
                      {!_.isUndefined(primarySkillError) &&
                        !_.isNull(primarySkillError) &&
                        primarySkillError !== "" && (
                          <Alert variant="danger" className="mt-2">
                            {primarySkillError}
                          </Alert>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="secondarySkill">
                      <ReactTags
                        labelText="Select Secondary Skills"
                        selected={user.secondarySkill}
                        suggestions={skillList}
                        allowNew={true}
                        onAdd={(selectedList) => {
                          setUserDetails({
                            ...user,
                            secondarySkill: [
                              ...user.secondarySkill,
                              selectedList,
                            ],
                          });
                        }}
                        onDelete={(selectedList) => {
                          setUserDetails({
                            ...user,
                            secondarySkill: user.secondarySkill.filter(
                              (_, i) => i !== selectedList
                            ),
                          });
                        }}
                        placeholderText="Seconadry Skill"
                        noOptionsText="No Matching Seconadry Skill"
                      />
                      {!_.isUndefined(secondarySkillError) &&
                        !_.isNull(secondarySkillError) &&
                        secondarySkillError !== "" && (
                          <Alert variant="danger" className="mt-2">
                            {secondarySkillError}
                          </Alert>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="country">
                      <Select
                        options={countryOptions}
                        onChange={(e) => selectChangeHandler(e, "country")}
                        placeholder="Choose Country"
                        isSearchable={true}
                      />
                      {!_.isUndefined(countryError) &&
                        !_.isNull(countryError) &&
                        countryError !== "" && (
                          <Alert variant="danger" className="mt-2">
                            {countryError}
                          </Alert>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="state">
                      <Select
                        options={stateOptions}
                        onChange={(e) => selectChangeHandler(e, "state")}
                        placeholder="Choose State"
                        isSearchable={true}
                      />
                      {!_.isUndefined(stateError) &&
                        !_.isNull(stateError) &&
                        stateError !== "" && (
                          <Alert variant="danger" className="mt-2">
                            {stateError}
                          </Alert>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="city">
                      <Select
                        options={cityOptions}
                        onChange={(e) => selectChangeHandler(e, "city")}
                        placeholder="Choose City"
                        isSearchable={true}
                      />
                      {!_.isUndefined(cityError) &&
                        !_.isNull(cityError) &&
                        cityError !== "" && (
                          <Alert variant="danger" className="mt-2">
                            {cityError}
                          </Alert>
                        )}
                    </Form.Group>
                    <Form.Group controlId="file" className="mb-3">
                      <Form.Label>
                        Please upload your latest updated resume in pdf, doc
                      </Form.Label>
                      <Form.Control
                        type="file"
                        name="file"
                        onChange={uploadResumeHandler}
                        accept=".pdf, .doc, .docx"
                      />
                      {!_.isUndefined(fileError) &&
                        !_.isNull(fileError) &&
                        fileError !== "" && (
                          <Alert variant="danger" className="mt-2">
                            {fileError}
                          </Alert>
                        )}
                    </Form.Group>
                    <div className="row mb-3">
                      <div className="col-12">
                        <Form.Check.Input
                          type="checkbox"
                          name="termsConditions"
                          onChange={changeHandler}
                        />
                        <Form.Check.Label className="termsConditionsLabel">
                          I've read and agreed to the{" "}
                          <span
                            className="terms-conditions"
                            onClick={() => setShowTermsConditions(true)}
                          >
                            Terms & Conditions
                          </span>
                        </Form.Check.Label>
                        {!_.isUndefined(termsConditionsError) &&
                          !_.isNull(termsConditionsError) &&
                          termsConditionsError !== "" && (
                            <Alert variant="danger" className="mt-2">
                              {termsConditionsError}
                            </Alert>
                          )}
                      </div>
                    </div>
                    <Form.Group
                      className="mb-3"
                      controlId="sign-up-form-submit-button"
                    >
                      <Button
                        type="submit"
                        className="sign-up-form-submit-button"
                        onClick={submitHandler}
                      >
                        Sign Up
                      </Button>
                    </Form.Group>
                  </Form>
                  <div className="row">
                    <div className="col-12 text-center">
                      <p className="alreadyHaveAnAccount">
                        Already Registered ?{" "}
                        <NavLink to="/sign-in" className="login">
                          Login
                        </NavLink>
                      </p>
                    </div>
                  </div>
                  {showTermsConditions && (
                    <Modal
                      show={showTermsConditions}
                      onHide={() => setShowTermsConditions(false)}
                      dialogClassName="modal-90w"
                      aria-labelledby="example-custom-modal-styling-title"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title> Terms & Conditions </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>
                          Welcome to Rala Infotech India Pvt. Ltd. ("Company",
                          "we", "us", "our"). These Terms and Conditions
                          ("Terms") govern your use of our website and services,
                          including the provision of Interview As A Service
                          ("Service") available at{" "}
                          <a target="_blank" href="https://ralainfotech.com/">
                            https://ralainfotech.com/
                          </a>{" "}
                          ("Site"). By accessing or using our Site and Services,
                          you agree to be bound by these Terms. If you do not
                          agree with these Terms, please do not use our Site or
                          Services.
                        </p>

                        <h5>Access Control</h5>
                        <p>
                          The platform offers three types of controls:<br></br>
                          Platform Owner: Has technical control rights but only
                          exercises them upon customer request or as per defined
                          policies.<br></br>
                          Customer: Has ownership of content and can grant
                          access to interviewers.<br></br>
                          Interviewers: Have limited permissions for reading and
                          writing content.<br></br>
                        </p>
                        <h5>User Responsibilities</h5>
                        <p>
                          Customers are responsible for managing login
                          credentials and assigning access rights to
                          interviewers.<br></br>
                          Platform Functionality<br></br>
                          Generated reports/videos/audios are based on user
                          commands.<br></br>
                        </p>
                        <h5>User Eligibility</h5>
                        <p>
                          Our platform is designed for use by adults and is not
                          intended for minors.<br></br>
                          Content Standards<br></br>
                          Users agree not to publish any harmful, misleading, or
                          offensive materials on our platform.<br></br>
                        </p>
                        <h5>User Profiles</h5>

                        <p>
                          Account Creation: You might need to register for an
                          account to use certain of our services. You guarantee
                          that the information you submit during the
                          registration procedure is true, up-to-date, and full.
                          <br></br>
                          <br></br>
                          Account Security: You bear the responsibility for any
                          activity that takes place under your account and for
                          keeping your login information secret. You promise to
                          let us know right away if your account is used without
                          authorization.
                        </p>
                        <h5>User Details</h5>
                        <p>
                          Customers must provide accurate details while signing
                          up.
                        </p>
                        <h5>Security and Risk</h5>
                        <p>
                          We provide robust security measures but are not liable
                          for high-tech interventions by third parties.
                        </p>
                        <h5>User Conduct</h5>
                        <p>
                          Users must not upload offensive, illegal, or
                          inappropriate content.<br></br>
                          The platform should not be used for illegal
                          activities.
                        </p>
                        <h5>Liability and Disputes</h5>
                        <p>
                          Users are responsible for the security of their login
                          credentials.<br></br>
                          The platform is not responsible for disputes,
                          offensive behaviour, or content used in interviews.
                        </p>

                        <h5>Intellectual Assets</h5>

                        <p>
                          Ownership: The Company or its licensors own all
                          content on the Site, including but not limited to
                          text, graphics, logos, and software, and that
                          ownership is safeguarded by intellectual property
                          laws.<br></br>
                          License: You are granted the right to access and use
                          the Site and Services for your personal use, provided
                          that it is restricted, non-exclusive,
                          non-transferable, and revocable. The right to resell
                          our services or any information found on the site is
                          not granted by this license.
                        </p>

                        <h5>Output Accuracy</h5>
                        <p>
                          The platform's output depends on the accuracy and
                          clarity of inputs provided by users.<br></br>
                          Policy Updates<br></br>
                          We reserve the right to modify the terms of use.
                        </p>
                        <h5>Privacy Policy</h5>
                        <p>
                          Our Privacy Policy details data collection, usage, and
                          availability on the platform.
                        </p>

                        <h5>GOVERNING LAW AND JURISDICTION</h5>
                        <p>
                          This Agreement follows Indian laws, excluding conflict
                          of law rules. Disputes concerning the Platform or
                          Services will be settled in courts in Bangalore,
                          Karnataka, India. By agreeing to these terms, you
                          accept the jurisdiction of these courts.
                        </p>

                        <h5>SPAM POLICY</h5>
                        <p>
                          We don't allow spam, unsolicited emails, or bulk
                          emailing. Accounts found sending spam will be
                          terminated. Spamming can lead to account suspension,
                          and the sender may be charged a clean-up fee.
                        </p>

                        <h5>SERVICE AVAILABILITY</h5>
                        <p>
                          We aim for 24/7 service, but occasional downtime due
                          to maintenance, technical issues, or external factors
                          may occur. We'll notify you of scheduled interruptions
                          and aren't liable for the resulting consequences.
                        </p>

                        <h5>Changes to this Privacy Policy</h5>
                        <p>
                          We update our Privacy Policy occasionally. You'll be
                          notified through email or a notice on our service
                          before changes take effect.
                        </p>

                        <h5>Contact Us</h5>
                        <p>
                          For queries about this Privacy Policy, reach out.
                          We're here to assist you with any concerns.<br></br>
                          <br></br>
                          Email:{" "}
                          <a href="mailto:hr@ralainfotech.com">
                            hr@ralainfotech.com
                          </a>
                        </p>
                      </Modal.Body>
                    </Modal>
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default InterviewerAccountScreen;
