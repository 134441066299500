
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FaFileAlt, FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { FiMessageCircle } from "react-icons/fi";
import Loader from "react-js-loader";
import { useNavigate, useParams } from "react-router-dom";
import { ReactTags } from 'react-tag-autocomplete';
import { API_URL, PROPOSED_INTERVIEWER_STATUS_APPROVE, PROPOSED_INTERVIEWER_STATUS_PENDING, PROPOSED_INTERVIEWER_STATUS_REJECT, getAuthUserFromSessionStorage, proposedInterviewerStatuslabel } from '../../common/constant';
import { EMPLOYER_SUB_ADMIN, EMPLOYER_SUPER_ADMIN, SUB_ADMIN, SUPER_ADMIN } from '../../common/roles';
import HeaderScreen from '../HeaderScreen/HeaderScreen';
import "./ProposedInterviewScreen.css";

const ProposedInterviewScreen = () => {
    const [userDetails, setUserType] = useState(getAuthUserFromSessionStorage());
    const [isLoading, setIsLoading] = useState(false);
    const [jobDetails, setJobDetails] = useState(null);
    const [skillList, setSkillList] = useState(null);
    const [positionList, setPositionList] = useState(null);
    const [interviewList, setInterviewList] = useState(null);

    const [initialInterviewList, setInitialInterviewList] = useState(null);

    const navigate = useNavigate();
    const { id: jobId } = useParams();
    const toast = useRef(null);

    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getJobDetail(false);
            } catch (error) {
                console.error(error);
            }
        };

        const fetchDataAndRepeat = async () => {
            // Call fetchData once
            await fetchData();
            // Set up an interval to call fetchData every 10 seconds
            // const intervalId = setInterval(fetchData, 15000);
            // // Clean up the interval when the component unmounts
            // return () => clearInterval(intervalId);
        };

        fetchDataAndRepeat();

        getSkills();
        getPositions();

    }, []);

    useEffect(() => {
        if (jobDetails !== undefined && jobDetails !== null) {
            getAvailableInterviewers();
        }
    }, [jobDetails])

    const getJobDetail = async (isShowLoader = true) => {
        if (isShowLoader) {
            setIsLoading(true)
        }
        try {
            const apiResponse = await fetch(`${API_URL}api/jobs/${jobId}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                }
            }
            );
            const response = await apiResponse.json();
            setJobDetails(response.data);
            if (isShowLoader) {
                setIsLoading(false)
            }
        }
        catch (err) {
            if (isShowLoader) {
                setIsLoading(false)
            }
            console.log(err)
            setJobDetails([]);
        }
    }

    const [selectedSkill, setSelectedSkill] = useState([]);
    const [selectedPosition, setSelectedPosition] = useState([]);

    const getPositions = () => {
        fetch(`${API_URL}api/getPositions`)
            .then((response) => response.json())
            .then((response) => {
                var positions = [];
                response.data.map((item) => {
                    positions.push(
                        {
                            label: item.name,
                            value: item.name,
                            name: item.name
                        }
                    );
                })
                setPositionList(positions)
            })
            .catch((error) => {
                setPositionList([])
            });
    }

    const getSkills = () => {
        fetch(`${API_URL}api/getSkills`)
            .then((response) => response.json())
            .then((response) => {
                var skills = [];
                response.data.map((item) => {
                    skills.push(
                        {
                            label: item.name,
                            value: item.name,
                            name: item.name,
                        }
                    );
                })
                setSkillList(skills)
            })
            .catch((error) => {
                setSkillList([])
            });
    }

    function hasCommonElement(array1, array2) {
        for (let i = 0; i < array1.length; i++) {
            for (let j = 0; j < array2.length; j++) {
                if (array1[i] === array2[j]) {
                    return true;
                }
            }
        }
        return false;
    }

    const getAvailableInterviewers = async () => {
        try {
            const apiResponse = await fetch(`${API_URL}api/getAvailableInterviewers`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                }
            }
            );
            const response = await apiResponse.json();
            const rejectedInterviewIds = jobDetails.proposedinterviewers.filter((item) => item.status === PROPOSED_INTERVIEWER_STATUS_REJECT).map((item) => item.interviewer_id)

            var interviewers = [];
            for (var index = 0; index < response.data.length; index++) {
                if (!rejectedInterviewIds.includes(response.data[index].id)) {
                    interviewers.push({
                        interviewer_id: response.data[index].id,
                        interviewer_name: response.data[index].first_name + " " + response.data[index].last_name,
                        resume: response.data[index].resume,
                        primary_skill: response.data[index].primary_skill,
                        designation: response.data[index].designation
                    });
                }
            }
            setSelectAll(false);
            setSelectedRows([])
            setInterviewList(interviewers)
            setInitialInterviewList(interviewers)
        }
        catch (err) {
            console.log(err)
            setSelectAll(false);
            setSelectedRows([])
            setInterviewList([])
        }
    }

    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const renderCheckbox = (rowData) => {
        return (
            <input
                type="checkbox"
                checked={selectAll || selectedRows.includes(rowData)}
                onChange={(e) => handleCheckboxChange(e, rowData)}
            />
        );
    };

    const handleCheckboxChange = (e, rowData) => {
        const checked = e.target.checked;

        if (rowData) {
            // Individual checkbox clicked
            if (checked) {
                setSelectedRows((prevSelected) => [...prevSelected, rowData]);
            } else {
                setSelectedRows((prevSelected) => prevSelected.filter((row) => row !== rowData));
            }
        } else {
            // Header checkbox clicked
            setSelectAll(checked);
            setSelectedRows(checked ? interviewList : []);
        }
    };

    useEffect(() => {
        filterInterviews();
    }, [selectedSkill, selectedPosition])

    const filterInterviews = () => {
        if (selectedSkill.length == 0 && selectedPosition.length == 0) {
            setSelectAll(false);
            setSelectedRows([]);
            setInterviewList(initialInterviewList)
        } else {
            const items = [];
            initialInterviewList.map((item) => {
                const isSkillExist = selectedSkill.length == 0 || hasCommonElement(item.primary_skill, selectedSkill.map((item) => item.value));
                const isPositionExist = selectedPosition.length == 0 || (selectedPosition.map((item) => item.value)).join(",").toLowerCase().includes(item.designation.toLowerCase());
                if (isSkillExist && isPositionExist) {
                    items.push(item);
                }
            })
            setInterviewList(items);
            setSelectAll(false);
            setSelectedRows([]);
        }
    }

    const proposedInterviewsApiCall = () => {
        if (selectedRows && selectedRows.length > 0) {
            const interviewerIds = selectedRows.map(interview => interview.interviewer_id);
            const params = {
                "proposedInterviews": interviewerIds,
                "jobId": jobId
            }
            fetch(`${API_URL}api/proposedInterviewers`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    toast.current.show({ severity: 'success', summary: 'Interviewers proposed successfully', detail: response.data.message, life: 3000 });
                    getJobDetail();
                    setSelectAll(false);
                    setSelectedRows([]);
                    setShowDialog(false)
                })
                .catch((error) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                });
        }
    }

    const [isShowResumeDialog, setIsShowResumeDialog] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null);
    const browserResume = (rowData) => {
        if (rowData.resume == "") {
            return (
                <div></div>
            );
        } else {
            return (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Interviewer Resume</Tooltip>}
                >
                    <a style={{ color: 'black' }} className="cursor-pointer" onClick={() => {
                        setSelectedItem(rowData)
                        setIsShowResumeDialog(true)
                    }}><FaFileAlt /></a>
                </OverlayTrigger>
            );
        }
    }

    const [isShowRemarkDialog, setIsShowRemarkDialog] = useState(false)
    const [proposedinterviewerId, setProposedinterviewerId] = useState(null);
    const [isUroposedinterviewer, setIsUroposedinterviewer] = useState(null);
    const [proposedinterviewerBody, setProposedinterviewerBody] = useState({
        status: "",
        remark: ""
    });

    const requestApproval = (proposedinterviewerId) => {
        setIsLoading(true)
        fetch(`${API_URL}api/requestProposedInterviewersApproval/${proposedinterviewerId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                setIsLoading(false)
                getJobDetail();
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
            })
            .catch((error) => {
                setIsLoading(false)
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            });
    }

    const proposedInterviewerStatus = (rowData) => {
        return (userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN) ? (
            (
                <Form.Select style={{ width: '100px' }}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (value !== PROPOSED_INTERVIEWER_STATUS_REJECT) {
                            setProposedinterviewerBody({
                                status: value ? PROPOSED_INTERVIEWER_STATUS_APPROVE : PROPOSED_INTERVIEWER_STATUS_REJECT,
                                remark: rowData.remark
                            })
                            setJobDetails({
                                ...jobDetails,
                                proposedinterviewers: jobDetails.proposedinterviewers.map((interviewer) =>
                                    interviewer._id === rowData._id
                                        ? { ...interviewer, status: value ? PROPOSED_INTERVIEWER_STATUS_APPROVE : PROPOSED_INTERVIEWER_STATUS_REJECT }
                                        : interviewer
                                ),
                            })
                            setProposedinterviewerId(rowData._id)
                            setIsUroposedinterviewer(true);
                        } else {
                            setIsShowRemarkDialog(true);
                            setProposedinterviewerId(rowData._id)
                        }
                    }}
                >
                    <option value={PROPOSED_INTERVIEWER_STATUS_PENDING}>Actions</option>
                    <option style={{ color: 'green' }} selected={rowData.status === PROPOSED_INTERVIEWER_STATUS_APPROVE} value={PROPOSED_INTERVIEWER_STATUS_APPROVE}>Accept</option>
                    <option style={{ color: 'red' }} selected={rowData.status === PROPOSED_INTERVIEWER_STATUS_REJECT} value={PROPOSED_INTERVIEWER_STATUS_REJECT}>Reject</option>
                </Form.Select>
            )
        ) : (
            rowData.status ? (
                <div className={rowData.status === PROPOSED_INTERVIEWER_STATUS_APPROVE ? 'text-success approved' : 'text-danger rejected'}>{proposedInterviewerStatuslabel[rowData.status]}</div>
            ) : (
                userDetails.account_type === SUPER_ADMIN ? (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Request Approval</Tooltip>}
                    >
                        <Button variant="outline-primary" className='btn btn-outline-primary btn-sm shadow-none' label="Request Approval" style={{

                        }} raised onClick={() => requestApproval(rowData._id)} />
                    </OverlayTrigger>
                ) : <div></div>
            )
        )
    }

    useEffect(() => {
        if (isUroposedinterviewer) {
            updateProposedInterviewers();
        }
    }, [isUroposedinterviewer]);

    const updateProposedInterviewers = () => {
        fetch(`${API_URL}api/updateProposedInterviewers/${proposedinterviewerId}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(proposedinterviewerBody)
        })
            .then((response) => response.json())
            .then((response) => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                setIsShowRemarkDialog(false);
                getJobDetail();
                setIsUroposedinterviewer(false);
            })
            .catch((error) => {
                console.log(error)
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                setIsShowRemarkDialog(false);
                setIsUroposedinterviewer(false);
            });
    }

    const remarkBody = (rowData) => {
        if (userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN) {
            return <div><FiMessageCircle size={30} onClick={() => {
                setProposedinterviewerId(rowData._id)
                setIsShowRemarkDialog(true);
            }} /></div>
        }
        return (<div>{rowData.remark}</div>);
    }

    const reviewDialogfooterContent = (
        <div style={{ marginTop: '30px', marginBottom: '40px' }}>
            <Button className="btn btn-primary" onClick={() => {
                setProposedinterviewerBody({
                    ...proposedinterviewerBody,
                    status: PROPOSED_INTERVIEWER_STATUS_REJECT
                });
                setIsUroposedinterviewer(true);
            }} >Yes</Button> {" "}{" "}
            <Button className="btn btn-outline-primary" onClick={() => setIsShowRemarkDialog(false)}>Cancel</Button>
        </div>
    );

    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });

    const onSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        sortData(jobDetails ? jobDetails.proposedinterviewers : [], sortConfig.key, sortConfig.direction);
    }, [sortConfig])

    const sortData = (list, key, direction) => {
        const sortedData = list.sort((a, b) => {
            var value1 = a[key];
            var value2 = b[key];

            if (typeof value1 === 'string' && typeof value2 === 'string') {
                if (direction === 'asc') {
                    return value1.localeCompare(value2);
                } else {
                    return value2.localeCompare(value1);
                }
            }

            if (value1 < value2) return direction === 'asc' ? -1 : 1;
            if (value1 > value2) return direction === 'asc' ? 1 : -1;

            return 0;
        });

        setJobDetails({
            ...jobDetails,
            proposedinterviewers: sortedData
        })
    }

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) {
            return <FaSort className="cursor-pointer" />;
        }
        if (sortConfig.direction === 'desc') {
            return <FaSortUp className="cursor-pointer" />;
        }
        return <FaSortDown className="cursor-pointer" />;
    };

    return (

        <Fragment>
            <Toast ref={toast} />
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <div className="container-fluid employer-dashboard-container fixed-padding">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 employer-dashboard-title pageTitle_fixed">
                        <div className="row mb-2">
                            <div className="col-12">
                                <p className="employer-dashboard-jobs-posted">
                                    {
                                        (userDetails.account_type === SUPER_ADMIN || userDetails.account_type == SUB_ADMIN) ? "Proposed interviewers" : "Assign interviewers"
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="">
                            <div className="card">

                                <div className='card-body'>
                                    <p className="employer-dashboard-jobs-posted m-0">
                                        <div className="row">
                                            <div className="col-md-6">

                                            </div>
                                            <div className="col-md-6 d-flex justify-content-end">
                                                {
                                                    (userDetails.account_type == SUPER_ADMIN || userDetails.account_type == SUB_ADMIN) && (
                                                        <Button className='btn btn-primary' label="Add" icon="pi pi-plus" raised onClick={() => setShowDialog(true)} />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </p>
                                    <div className="row">
                                        <div className="col-12 employer-dashboard-title">
                                            <Table bordered striped hover size="lg" responsive className="history-feednback-completed">
                                                <thead>
                                                    <tr>
                                                        <th onClick={() => onSort('interviewer_name')}>Interviewer Name {getSortIcon('interviewer_name')}</th>
                                                        <th onClick={() => onSort('resume')}>Resume {getSortIcon('resume')}</th>
                                                        <th onClick={() => onSort('status')}>Approve Or Reject {getSortIcon('status')}</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        jobDetails && jobDetails.proposedinterviewers.map((item) => {
                                                            return <tr>
                                                                <td className='text-start'>{item.interviewer_name}</td>
                                                                <td className='text-start'>{browserResume(item)}</td>
                                                                <td className='text-start'>{proposedInterviewerStatus(item)}</td>
                                                                <td className='text-start'>{remarkBody(item)}</td>
                                                            </tr>
                                                        })
                                                    }

                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog header="Proposed Interviewers" visible={showDialog} style={{ width: '60vw' }} onHide={() => setShowDialog(false)} dismissableMask="true">
                <div className="row" style={{
                    alignItems: 'center',
                    marginBottom: '14px'
                }}>
                    {/* Left Side - Text */}
                    <div className="col-md-2">
                        <span>Primary Skills: </span>
                    </div>
                    <div className="col-md-3">
                        <ReactTags
                            labelText="Select Primary Skills"
                            selected={selectedSkill}
                            suggestions={skillList}
                            allowNew={true}
                            onAdd={(selectedList) => {
                                setSelectedSkill([
                                    ...selectedSkill,
                                    selectedList
                                ]);
                            }}
                            onDelete={(selectedList) => {
                                setSelectedSkill(selectedSkill.filter((_, i) => i !== selectedList));
                            }}
                            placeholderText="Add new skill"
                            noOptionsText="No matching skill"
                        />
                    </div>

                    <div className="col-md-2">
                        <span>Job positions: </span>
                    </div>

                    <div className="col-md-3 justify-content-center">
                        <ReactTags
                            labelText="Select Primary Skills"
                            selected={selectedPosition}
                            suggestions={positionList}
                            allowNew={false}
                            onAdd={(selectedList) => {
                                setSelectedPosition([
                                    ...selectedPosition,
                                    selectedList
                                ]);
                            }}
                            onDelete={(selectedList) => {
                                setSelectedPosition(selectedPosition.filter((_, i) => i !== selectedList));
                            }}
                            placeholderText="Select an position"
                            noOptionsText="No matching skill"
                        />
                    </div>

                    {/* Right Side - Add Button */}
                    <div className="col-md-2 pr-3 d-flex justify-content-end">
                        <Button variant="primary" className='btn btn-primary fw-medium' label="Save" style={{

                        }} raised onClick={() => proposedInterviewsApiCall()} />
                    </div>
                </div>

                <DataTable value={interviewList} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="interviewer_name" header="Interviewr Name"></Column>
                    <Column header="Resume" body={browserResume}></Column>
                    <Column
                        header={<input type="checkbox" checked={selectAll} onChange={(e) => handleCheckboxChange(e)} />}
                        body={renderCheckbox}
                    ></Column>
                </DataTable>
            </Dialog>

            <Dialog header="Candidate Resume" visible={isShowResumeDialog} style={{ width: '50vw', height: '80vh' }} onHide={() => setIsShowResumeDialog(false)} dismissableMask="true">
                {
                    selectedItem && selectedItem.resume &&
                    (
                        selectedItem.resume.includes('.doc') ?
                            <iframe title="resume" key="iframe-doc" style={{
                                width: '100%',
                                height: '100%'
                            }} src={`https://docs.google.com/gview?url=${selectedItem.resume}&embedded=true`}></iframe> :
                            <object title="resume" key="object-other" data={selectedItem.resume} style={{
                                width: '100%',
                                height: '100%'
                            }}></object>
                    )
                }
            </Dialog>

            <Dialog header="Add remark" footer={reviewDialogfooterContent} visible={isShowRemarkDialog} style={{ width: '50vw' }} onHide={() => setIsShowRemarkDialog(false)} dismissableMask="true">
                <InputTextarea style={{
                    width: '100%'
                }} rows={5} cols={30} onChange={(e) => {
                    setProposedinterviewerBody({
                        ...proposedinterviewerBody,
                        remark: e.target.value
                    })
                }} />
            </Dialog>


        </Fragment>

    );
}

export default ProposedInterviewScreen;