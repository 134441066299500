// Local
// export const API_URL = 'http://localhost:3301/';

import moment from "moment";
import { SUB_ADMIN, SUPER_ADMIN } from "./roles";

// production
export const API_URL = 'http://18.236.155.96:3301/';

// job status
export const JOB_STATUS_OPEN = 'Open';
export const JOB_STATUS_ON_HOLD = 'On Hold';
export const JOB_STATUS_CLOSED = 'Closed';

// this is interviewer status it will be update by employee to accept and reject interviewer
export const PROPOSED_INTERVIEWER_STATUS_PENDING = "";
export const PROPOSED_INTERVIEWER_STATUS_APPROVE = "APPROVE";
export const PROPOSED_INTERVIEWER_STATUS_REJECT = "REJECT";

// interview schedule status its schedule or now
export const SCHEDULER_STATUS_PENDING = 'SCHEDULER_STATUS_PENDING';
export const SCHEDULER_STATUS_DONE = 'SCHEDULER_STATUS_DONE';
export const SCHEDULER_STATUS_CANCELLED = 'SCHEDULER_STATUS_CANCELLED';

// candidate is selected or not
export const INTERVIEW_FINAL_STATUS_SELECTED = 'INTERVIEW_FINAL_STATUS_SELECTED';
export const INTERVIEW_FINAL_STATUS_REJECTED = 'INTERVIEW_FINAL_STATUS_REJECTED';

// qc status from superadmin or ralaadmin
export const QC_STATUS_PASS = 'QC_STATUS_PASS';
export const QC_STATUS_FAIL = 'QC_STATUS_FAIL';

// INCOMPLETE_OPTIONS
export const PROXY = "PROXY";
export const BANDWIDTH_ISSUE_CANDIDATE_END = "BANDWIDTH_ISSUE_CANDIDATE_END";
export const BANDWIDTH_ISSUE_INTERVIEWER_END = "BANDWIDTH_ISSUE_INTERVIEWER_END";
export const HARDWARE_ISSUE_CANDIDATE_END = "HARDWARE_ISSUE_CANDIDATE_END";
export const HARDWARE_ISSUE_INTERVIEWER_END = "HARDWARE_ISSUE_INTERVIEWER_END";
export const NO_SHOW_BY_INTERVIWER = "NO_SHOW_BY_INTERVIWER";
export const NO_SHOW_BY_CANDIDATE = "NO_SHOW_BY_CANDIDATE";


// user status
export const USER_STATUS_PENDING = 'PENDING';
export const USER_STATUS_APPROVE = 'APPROVE';
export const USER_STATUS_REJECT = 'REJECT';
export const USER_STATUS_ON_HOLD = 'ON_HOLD';

export const getUserStatus = (status) => {
    return status.replaceAll("_", " ").replace(/\w+/g,
        function (w) { return w[0].toUpperCase() + w.slice(1).toLowerCase(); });
}

// pagination
export const ITEMS_PER_PAGE = 20;


export const getInterviewFinalStatus = (status) => {
    return status === INTERVIEW_FINAL_STATUS_SELECTED ? "Selected" : ((status === INTERVIEW_FINAL_STATUS_REJECTED) ? 'Rejected' : "")
}

export const getQCStatuslabel = (status) => {
    return status === "" ? "Pending" : (status === QC_STATUS_PASS ? "Qc Pass" : "Qc Fail");
}

export const getincompleteInterview = (status) => {
    return status.replaceAll("_", " ").replace(/(\w)(\w*)/g,
        function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); })
}
//previous_scheduled_on
export const getSchedulerStatusLabel = (item) => {
    return item.scheduler_status === SCHEDULER_STATUS_PENDING ? "Pending" : (
        item.scheduler_status === SCHEDULER_STATUS_DONE ?
            (item.previous_scheduled_on !== null ? 'Rescheduled' : 'Scheduled') :
            'Cancelled'
    );
}

export const pascalCase = (words) => {
    return words.replace(/(\w)(\w*)/g, function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
}

export const updateStatusLabel = (status) => {
    return status === "APPROVE" ? "Approved" : (status === "REJECT" ? "Rejected" : (status === "ON_HOLD" ? "On Hold" : "Pending"));
}

export const commonDateFormat = (date, format = "") => {
    if (format === "") {
        return moment(date).format("DD/MM/YYYY h:mm A");
    } else {
        return moment(date).format(format);
    }
}

export const addAuthUserToSessionStorage = (user) => {
    sessionStorage.setItem(`user`, JSON.stringify(user));
    sessionStorage.setItem(`userToken`, user.token);
}

export const removeAuthUserFromSessionStorage = () => {
    sessionStorage.clear();
}

export const getAuthUserFromSessionStorage = () => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
}

export const dayDifference = (dateString) => {
    const currentIstDate = moment().tz('Asia/Kolkata');
    const givenIstDate = moment.utc(dateString).tz('Asia/Kolkata');
    const differenceInDays = currentIstDate.diff(givenIstDate, 'days');
    return differenceInDays;
}

export const isToday = (dateString) => {
    const currentIstDate = moment().tz('Asia/Kolkata').startOf('day');
    const givenIstDate = moment.utc(dateString).tz('Asia/Kolkata').startOf('day');
    return currentIstDate.isSame(givenIstDate, 'day');
}

export const isFutureDate = (dateString) => {
    const currentIstDate = moment().tz('Asia/Kolkata').startOf('day');
    const givenIstDate = moment.utc(dateString).tz('Asia/Kolkata').startOf('day');
    const differenceInDays = givenIstDate.diff(currentIstDate, 'days');
    return differenceInDays > 0;
}

export const isDateGreaterThanToday = (dateString) => {
    const currentIstDate = moment().tz('Asia/Kolkata');
    const givenIstDate = moment.utc(dateString).tz('Asia/Kolkata');
    return givenIstDate.isAfter(currentIstDate);
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getRoleLabel = (role) => {
    return role === SUPER_ADMIN ? "Rala Super Admin" : role === SUB_ADMIN ? "Rala Sub Admin" : role.replaceAll("_", " ").replace(/\w+/g,
        function (w) { return w[0].toUpperCase() + w.slice(1).toLowerCase(); });
}

export const proposedInterviewerStatuslabel = {
    "APPROVE": "Approved",
    "REJECT": "Rejected",
    "": "Pending"
}


export const allowedResumeTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

export const resumeFileTypeErrorMessage = "Please upload only pdf, doc, docx file";

export const allowedImageTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/webp',
    'image/svg+xml',
    'image/tiff'
];

export const imageFileTypeErrorMessage = "The selected file type is not allowed. Please select an image file.";

export const hasNumber = (myString) => {
    return /\d/.test(myString);
}

export const fileName = () => {
    return moment().tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss');
}


const getRatingStatus = (rating) => {
    const ratingNumer = rating ? parseInt(rating) : 0;
    var ratingStatus = "";
    switch (ratingNumer) {
        case 0:
            ratingStatus = "poor";
            break;
        case 1:
            ratingStatus = "poor";
            break;
        case 2:
            ratingStatus = "below-average";
            break;
        case 3:
            ratingStatus = "average";
            break;
        case 4:
            ratingStatus = "good";
            break;
        case 5:
            ratingStatus = "very good";
            break;
        default:
            break;
    }
    return ratingStatus;
}

const StarRating = (totalStars, filledStars) => {
    var startHtmlContent = "";
    for (var index = 0; index < totalStars; index++) {
        const starValue = index + 1;
        if (starValue <= filledStars) {
            startHtmlContent += `
                    <span style="color: gold; fontSize: 2em">★</span>
                `;
        } else {
            startHtmlContent += `
                    <span style="color: grey; fontSize: 2em">★</span>
                `;
        }
    }
    return startHtmlContent;
};

const getPrimarySkillsUI = (interviewState) => {
    var UIHtmlContent = "";
    for (var index = 0; index < interviewState.primary_skill_feedback.length; index++) {
        var item = interviewState.primary_skill_feedback[index];
        var startHtmlContent = StarRating(5, item.rating);
        UIHtmlContent +=
            `<ul>
                <li>${interviewState ? interviewState.job.primary_skill[index] : ""}</li>
                <li style="color: #0FB600;">${getRatingStatus(item.rating)}</li>
                <li>${startHtmlContent} ${getRatingStatus(item.rating)}</li>
            </ul>
            <p>${item.review}</p>`;
    }
    return UIHtmlContent;
}

const getSecondarySkillsUI = (interviewState) => {
    var UIHtmlContent = "";
    for (var index = 0; index < interviewState.secondary_skill_feedback.length; index++) {
        var item = interviewState.secondary_skill_feedback[index];
        var startHtmlContent = StarRating(5, item.rating);
        UIHtmlContent +=
            `<ul>
                <li>${interviewState ? interviewState.job.secondary_skill[index] : ""}</li>
                <li style="color: #0FB600;">${getRatingStatus(item.rating)}</li>
                <li>${startHtmlContent} ${getRatingStatus(item.rating)}</li>
            </ul>
            <p>${item.review}</p>`;
    }
    return UIHtmlContent;
}

const getGoodToHaveSkillsUI = (interviewState) => {
    var UIHtmlContent = "";
    var startHtmlContent = StarRating(5, interviewState.good_to_have_skill_feedback_rating);
    UIHtmlContent +=
        `<ul>
            <li>Good to have Skills</li>
            <li style="color: #0FB600;">${getRatingStatus(interviewState.good_to_have_skill_feedback_rating)}</li>
            <li>${startHtmlContent} ${getRatingStatus(interviewState.good_to_have_skill_feedback_rating)}</li>
        </ul>
        <p>${interviewState.good_to_have_skill_feedback}</p>`;
    return UIHtmlContent;
}

const getCodingSkillsUI = (interviewState) => {
    var UIHtmlContent = "";
    var startHtmlContent = StarRating(5, interviewState.coding_skill_feedback_rating);
    UIHtmlContent +=
        `<ul>
            <li>Coding Skill</li>
            <li style="color: #0FB600;">${getRatingStatus(interviewState.coding_skill_feedback_rating)}</li>
            <li>${startHtmlContent} ${getRatingStatus(interviewState.coding_skill_feedback_rating)}</li>
        </ul>
        <p>${interviewState.coding_skill_feedback}</p>`;
    return UIHtmlContent;
}

const getProfesstionalSkillsUI = (interviewState) => {
    var UIHtmlContent = "";
    for (var index in interviewState.professional_skill) {
        var item = interviewState.professional_skill[index];
        var startHtmlContent = StarRating(5, item.rating);
        UIHtmlContent +=
            `<ul>
                <li>${interviewState ? item.label : ""}</li>
                <li style="color: #0FB600;">${getRatingStatus(item.rating)}</li>
                <li>${startHtmlContent} ${getRatingStatus(item.rating)}</li>
            </ul>
            <p>${item.feedback}</p>`;
    }
    return UIHtmlContent;
}

const getUploadedScreenshotUI = (interviewState) => {
    var UIHtmlContent = "";
    for (var index = 0; index < interviewState.uploadedScreenshots.length; index++) {
        UIHtmlContent +=
            `<img src=${interviewState.uploadedScreenshots[index]} alt="Uploaded Screenshots">`;
    }
    return UIHtmlContent;
}

const getCodingUI = (interviewState) => {
    var codeByCandidate = "";
    if (interviewState && interviewState.code_by_candidate) {
        codeByCandidate = `<SyntaxHighlighter style={solarizedlight}>${interviewState ? interviewState.code_by_candidate : ""}</SyntaxHighlighter>`
    }
    var codeOutputByCandidate = "";
    if (interviewState && interviewState.code_output_by_candidate) {
        codeOutputByCandidate = `<SyntaxHighlighter style={solarizedlight}>${interviewState ? interviewState.code_output_by_candidate : ""}</SyntaxHighlighter>`
    }
    return `<div class="coding_box">
            <div class="coding_child" style="background-color: #fdf6e3;">${codeByCandidate}</div>
            <div class="coding_child" style="background-color: #4d4033;">${codeOutputByCandidate}</div>
        </div>`;
}

export const generateContent = (interviewState) => {
    return `<!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Final Interview Report</title>
                <style>
                    body{
                        font-family: "Roboto", sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 1.6;
                        margin: 0;
                        color: #000000;
                        background-color: #ffffff;
                        -webkit-font-smoothing: antialiased;
                        letter-spacing: 0;
                    }
                    a, a:hover, a:focus, button, button:hover, button:focus {
                        color: #746D6F;
                        text-decoration: none;
                        cursor: pointer;
                    }
                    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, b, strong {
                        font-family: "Roboto", sans-serif;
                        color: #000000;
                        font-weight: 600;
                        line-height: 1.1;
                    }
                    img {
                        max-width: 100%;
                    }
                    ul, ol {
                        margin: 0;
                        padding: 0;
                    }
                    li {
                        list-style: none;
                    }
                    .fir_header_wrap{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 84%;
                        margin: 0 auto;
                        padding: 40px 0 50px 0;
                        flex-wrap: wrap;
                    }
                    .fir_header_left h3{
                        margin: 0 0 5px 0;
                        font-size: 22px;
                    }
                    .fir_header_left ul{
                        display: flex;
                        column-gap: 30px;
                    }
                    .fir_header_left ul li{
                        font-size: 14px;
                    }
                    .fir_header_right{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                    }
                    .fir_header_right button{
                        background-color: #00970F;
                        display: flex;
                        align-items: center;
                        height: 32px;
                        width: 83px;
                        border: 0;
                        justify-content: center;
                        border-radius: 50px;
                        color: #ffffff;
                    }
                    .fir_header_right p{
                        margin: 10px 0 0 0;
                        font-size: 12px;
                    }
                    .fir_header_right p span{
                        color: #F0DC96;
                    }
                    .fir_heading_wrap{
                        position: relative;
                        z-index: 1;
                        display: flex;
                        width: 100%;
                        font-size: 12px;
                        margin: 20px 0;
                    }
                    .fir_heading_wrap span{
                        background-color: #ffffff;
                        position: relative;
                        z-index: 1;
                        padding-right: 10px;
                    }
                    .fir_heading_wrap::after{
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: 50%;
                        background-color: #746D6F;
                        height: 2px;
                        width: 100%;
                    }
                    .fir_header_wrap .fir_heading_wrap{
                        margin-top: 15px;
                    }
                    .fir_main_wrap{
                        display: flex;
                        width: 68%;
                        margin: 0 auto;
                        flex-direction: column;
                        padding: 0 0 50px 0;
                    }
                    .fir_main_wrap h2{
                        font-size: 22px;
                        margin: 0 0 25px 0;
                    }
                    .fir_section_wrap{
                        display: flex;
                        width: 100%;
                        flex-direction: column;
                        margin-bottom: 25px;
                    }
                    .fir_section_wrap ul{
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        margin-bottom: 15px;
                        font-size: 16px;
                        align-items: center;
                    }
                    .fir_section_wrap p{
                        margin: 0 0 10px 0;
                    }
                    .fir_footer_wrap{
                        display: flex;
                        width: 100%;
                    }
                    .fir_footer_wrap ul{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        font-size: 14px;
                    }
                    .fir_footer_wrap ul li span{
                        color: #746D6F;
                    }
                    .fir_skills_rating{
                        display: flex;
                        width: 100%;
                        margin: 0 0 30px 0;
                    }
                    .fir_skills_rating ul{
                        display: flex;
                        width: 100%;
                        justify-content: space-around;
                        align-items: center;
                        column-gap: 20px;
                    }
                    .fir_skills_rating ul li{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        flex: 1;
                    }
                    .fir_rating_box{
                        display: flex;
                        width: 100%;
                        flex-direction: column;
                        align-items: center;
                        background-color: #FFEDEA;
                        border-radius: 16px;
                        padding: 25px 0;
                        row-gap: 20px;
                    }
                    .fir_rating_box p{
                        margin: 0;
                    }
                    .fir_rating_box span{
                        color: #0FB600;
                    }
                    .coding_box {
                        display: flex;
                    }
                    .coding_child {
                        flex: 1;
                    }
                </style>
            </head>
            <body>
                <div class="fir_header_wrap">
                    <div class="fir_header_left">
                        <h3>${interviewState.candidate_name}</h3>
                        <ul>
                            <li>${interviewState.job.primary_skill.join(', ')}</li>
                            <li>${interviewState.candidate_email}</li>
                            <li>${interviewState.candidate_mobile_number}</li>
                        </ul>
                    </div>
                    <div class="fir_header_right">
                        <button class="${interviewState ? (interviewState.final_status == INTERVIEW_FINAL_STATUS_SELECTED ? 'green' : (interviewState.final_status == INTERVIEW_FINAL_STATUS_REJECTED ? 'red' : '')) : ''}">${interviewState ? (interviewState.final_status == INTERVIEW_FINAL_STATUS_SELECTED ? 'Selected' : 'Rejected') : ""}</button>
                        <p><span>${interviewState && interviewState.report_date ? 'Report Sent : ' : ''}</span>${interviewState.report_date ? commonDateFormat(interviewState.report_date) : ""}</p>
                    </div>
                    <div class="fir_heading_wrap"><span>RATINGS</span></div>
                </div>
                <div class="fir_main_wrap">
                    <h2>Mandatory Skills</h2>
                    <div class="fir_section_wrap">
                        ${getPrimarySkillsUI(interviewState)}
                    </div>
                    <div class="fir_section_wrap">
                        ${getSecondarySkillsUI(interviewState)}
                    </div>
                    <h2>Good to have Skills</h2>
                    <div class="fir_section_wrap">
                        ${getGoodToHaveSkillsUI(interviewState)}
                    </div>
                    <h2>Coding Skills</h2>
                    <div class="fir_section_wrap">
                        ${getCodingSkillsUI(interviewState)}
                    </div>
                    <div class="fir_section_wrap">
                        ${getProfesstionalSkillsUI(interviewState)}
                    </div>
                    <div class="fir_skills_rating">
                        <ul>
                            <li>
                                <div class="fir_rating_box">
                                    <p>${StarRating(5, interviewState ? interviewState.averagePrimarySkillsRating : 0)} ${interviewState ? interviewState.averagePrimarySkillsRating : 0}</p>
                                    <span>${getRatingStatus(interviewState ? interviewState.averagePrimarySkillsRating : 0)}</span>
                                </div>
                                <p>Primary Skills</p>
                            </li>
                            <li>
                                <div class="fir_rating_box">
                                    <p>${StarRating(5, interviewState ? interviewState.averageSecondarySkillsRating : 0)} ${interviewState ? interviewState.averageSecondarySkillsRating : 0}</p>
                                    <span>${getRatingStatus(interviewState ? interviewState.averageSecondarySkillsRating : 0)}</span>
                                </div>
                                <p>Secondary Skills</p>
                            </li>
                            <li>
                                <div class="fir_rating_box">
                                    <p>${StarRating(5, interviewState ? interviewState.coding_skill_feedback_rating : 0)} ${interviewState ? interviewState.coding_skill_feedback_rating : 0}</p>
                                    <span>${getRatingStatus(interviewState ? interviewState.coding_skill_feedback_rating : 0)}</span>
                                </div>
                                <p>Coding</p>
                            </li>
                            <li>
                                <div class="fir_rating_box">
                                    <p>${StarRating(5, interviewState ? interviewState.overall_rating : 0)} ${interviewState ? interviewState.overall_rating : 0}</p>
                                    <span>${getRatingStatus(interviewState ? interviewState.overall_rating : 0)}</span>
                                </div>
                                <p>Overall</p>
                            </li>
                        </ul>
                    </div>
                    <h2>Final Recommendation</h2>
                    <div class="fir_section_wrap">
                        ${interviewState.final_recommendation}
                    </div>
                    <div class="fir_heading_wrap"><span>Coding Test</span></div>
                    ${getCodingUI(interviewState)}
                    ${getUploadedScreenshotUI(interviewState)}
                    <div class="fir_heading_wrap"><span>BASIC INFORMATION</span></div>
                    <div class="fir_footer_wrap">
                        <ul>
                            <li><span>Interviewer:</span> ${interviewState ? interviewState.interviewInfo.first_name : ""}</li>
                            <li><span>Scheduled Date :</span> ${interviewState ? commonDateFormat(interviewState.scheduled_on) : "not scheduled yet"}</li>
                            <li><span>mandatory Skills :</span>  ${interviewState ? interviewState.job.primary_skill.join(', ') : ""}</li>
                        </ul>
                    </div>
                </div>
            </body>
            </html>`;
}