import React, { useState, useEffect, Fragment, useRef } from "react";
import _ from 'lodash';
import Loader from "react-js-loader";
import "./CalendarScreen.css";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import { RxCountdownTimer } from "react-icons/rx";
import { Card } from 'react-bootstrap';
import { Calendar, momentLocalizer } from 'react-big-calendar';
// import dayjs from 'dayjs'
import moment from 'moment'
import 'moment-timezone';
import { Toast } from 'primereact/toast';
import { API_URL, getAuthUserFromSessionStorage, isDateGreaterThanToday } from "../../common/constant";
import CustomWeekView from "./CustomWeekView";

const CalendarScreen = () => {
    // moment.tz.setDefault('Asia/Kolkata')

    const twoMonthsAgo = moment().subtract(2, 'months').toDate();
    const today = new Date();

    const toast = useRef(null);

    const localizer = momentLocalizer(moment);
    const [isLoading, setIsLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [userDetails, setUserDetails] = useState(getAuthUserFromSessionStorage());
    const [isDragging, setIsDragging] = useState(false);

    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 7);

    useEffect(() => {
        fetchBooking()
    }, [])

    const fetchBooking = () => {
        fetch(`${API_URL}api/getSlots/${userDetails.id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                const myEvents = [];
                response.map((item) => {
                    if (new Date(item.start) > twoMonthsAgo) {
                        myEvents.push(
                            {
                                id: item._id,
                                title: item.title,
                                start: new Date(item.start),
                                end: new Date(item.end)
                            }
                        )
                    }
                })
                setEvents(myEvents)
            })
            .catch((err) => {
                setEvents([]);
            });
    }
    const handleSelect = ({ start, end }) => {
        const timeDifference = end.getTime() - start.getTime();
        const minutesDifference = (timeDifference / 1000) / 60;

        // Specified date
        let specifiedDate = new Date(start);
        // Current date
        let currentDate = new Date();
        // Calculate the difference in milliseconds
        let differenceInMilliseconds = specifiedDate - currentDate;
        // Convert the difference to days, hours, minutes, and seconds
        let differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
        let differenceInMinutes = Math.floor(differenceInSeconds / 60);
        let differenceInHours = Math.floor(differenceInMinutes / 60);
        let differenceInDays = Math.floor(differenceInHours / 24);

        if (differenceInDays <= 7) {
            if (new Date(start).getTime() < new Date().getTime()) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'You can not book past date slots', life: 3000 });
                setIsDragging(false);
            } else {
                const title = "Available";
                if (isDragging) {
                    if (minutesDifference >= 30 && minutesDifference <= 45) {
                        const newEvent = {
                            start: start,
                            end: end,
                            title: title,
                            userId: userDetails.id
                        };
                        bookSlot(newEvent)
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please note that you can only book a slot between 30 to 45 minutes. Ensure your selected time falls within this rang', life: 3000 });
                    }
                    // if (minutesDifference >= 45) {
                    //     const newEvent = {
                    //         start: start,
                    //         end: end,
                    //         title: title,
                    //         userId: userDetails.id
                    //     };
                    //     bookSlot(newEvent)
                    // } else {
                    //     toast.current.show({ severity: 'error', summary: 'Error', detail: 'minmum slot time must be 45 min', life: 3000 });
                    // }
                    setIsDragging(false);
                } else {
                    const addedEndTimeData = new Date(start.getTime() + 45 * 60000);
                    const newEvent = {
                        start: start,
                        end: addedEndTimeData,
                        title: title,
                        userId: userDetails.id
                    };
                    bookSlot(newEvent)
                    setIsDragging(false);
                }
            }
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Sorry, you can\'t book a slot for more than 7 days ahead.', life: 3000 });
        }
    };

    const bookSlot = (newEvent) => {
        fetch(`${API_URL}api/bookSlots`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(newEvent)
        })
            .then((response) => response.json())
            .then((response) => {
                fetchBooking()
            })
            .catch((err) => {

            });
    }

    const handleSelectSlot = (event) => {
        if (event.title === "Available" && isDateGreaterThanToday(event.start)) {
            const del = window.confirm(`Delete Slot ?`);
            if (del) {
                const index = events.findIndex((e) => e.id === event.id);
                if (index >= 0) {
                    setEvents([...events.slice(0, index), ...events.slice(index + 1)]);
                    deleteSlot(events[index].id)
                }
            }
        }
    };

    const deleteSlot = (id) => {
        fetch(`${API_URL}api/deleteSlot/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                fetchBooking()
            })
            .catch((err) => {

            });
    }

    const handleDragStart = () => {
        setIsDragging(true);
    };

    const dayPropGetter = (date) => {
        if (date < twoMonthsAgo) {
            return {
                className: 'disabled-date',
                style: {
                    backgroundColor: '#f0f0f0',
                    color: '#d0d0d0',
                    pointerEvents: 'none',
                },
            };
        }
        return {};
    };

    return (
        <Fragment>
            <Toast ref={toast} />
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <div className="container-fluid calendar-container fixed-padding">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 my-auto calendar-title pageTitle_fixed">
                        <RxCountdownTimer size={20} className="header-icon" />CALENDAR
                    </div>
                </div>
                <div className="row calendar-feednback-row">

                    <div className="col-12">
                        <Card>
                            <Card.Body>
                                <Calendar
                                    // views={["day", "work_week", "month"]}
                                    views={{ day: true, work_week: CustomWeekView, month: true }}
                                    selectable
                                    localizer={localizer}
                                    step={15}
                                    formats={{
                                        dayFormat: "EEEE"
                                    }}
                                    defaultDate={new Date()}
                                    defaultView="day"
                                    events={events}
                                    style={{ height: "100vh" }}
                                    onSelectEvent={handleSelectSlot}
                                    onSelectSlot={handleSelect}
                                    onSelecting={handleDragStart}
                                    dayPropGetter={dayPropGetter}
                                />
                            </Card.Body>
                        </Card>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}
export default CalendarScreen;



