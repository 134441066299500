import html2pdf from 'html2pdf.js';
import JSZip from 'jszip';
import _ from 'lodash';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import React, { Fragment, forwardRef, useEffect, useRef, useState } from "react";
import { Alert, Badge, Button, Card, Col, Container, DropdownButton, FloatingLabel, Form, InputGroup, ListGroup, Modal, Nav, OverlayTrigger, Row, Tab, Table, Tooltip } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiMessageRounded, BiSolidBookAdd, BiSolidVideos } from "react-icons/bi";
import { CiFilter } from "react-icons/ci";
import { FaFileExcel } from "react-icons/fa";
import { BiSolidReport } from "react-icons/bi";
import { FaDownload, FaFileAlt, FaHistory, FaMailBulk, FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import Loader from "react-js-loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API_URL, INTERVIEW_FINAL_STATUS_REJECTED, INTERVIEW_FINAL_STATUS_SELECTED, ITEMS_PER_PAGE, PROXY, QC_STATUS_FAIL, QC_STATUS_PASS, commonDateFormat, dayDifference, fileName, generateContent, getAuthUserFromSessionStorage, getInterviewFinalStatus, getQCStatuslabel, getincompleteInterview } from "../../common/constant";
import PaginationComponent from "../../common/PaginationComponent/PaginationComponent";
import { EMPLOYER_SUB_ADMIN, EMPLOYER_SUPER_ADMIN, SUB_ADMIN, SUPER_ADMIN } from "../../common/roles";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import "./ReportScreen.css";

const ReportScreen = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [userDetails, setUserType] = useState(getAuthUserFromSessionStorage());
    const [initialData, setInitialData] = useState([]);
    const [interviewList, setInterviewList] = useState([]);
    const [navType, setNavType] = useState("Pending");
    const { id: jobId } = useParams();
    const [breadJobTitle, setBreadJobTitle] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);
    const [selecteAll, setSelecteAll] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [isShowSuggestion, setIsShowSuggestion] = useState(false);

    const [isShowQCRemark, setIsShowQCRemark] = useState(false)
    const [updatedInterviewId, setUpdatedInterviewId] = useState("");
    const [interviewerBody, setInterviewerBody] = useState({
        qc_status: "",
        qc_remark: ""
    });

    const [interviewIdForQcRemark, setInterviewIdForQcRemark] = useState("");
    const [qcRemark, setQcRemark] = useState("");

    const [isShowHistoryModel, setIsShowHistoryModel] = useState(false)
    const [candidateData, setCandidateData] = useState(null)
    const [addedAttempts, setAddedAttempts] = useState([]);
    const toast = useRef(null);


    const [buCode, setBuCode] = useState([]);
    const [jobIds, setJobIds] = useState([]);
    const [jobTitles, setJobTitles] = useState([]);
    const [recruiter, setRecruiter] = useState([]);
    const [inititalRecruiter, setInititalRecruiter] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [selectedFields, setSelectedFields] = useState({
        company: [],
        recruiter: [],
        jobTitle: [],
        jobId: [],
        rating: [],
        status: [],
        buCode: [],
    });

    const [editedId, setEditedId] = useState("");

    const [reportPageNumber, setReportPageNumber] = useState(1);
    const [reportPageSize, setReportPageSize] = useState(ITEMS_PER_PAGE);

    const updateReportPagination = (pageNumber, pageSize = 20) => {
        setReportPageNumber(pageNumber)
        setReportPageSize(pageSize);
    }

    useEffect(() => {
        if (!_.isUndefined(interviewList) && !_.isNull(interviewList) && (interviewList.length === 0)) {
            getinterviewList();
        }
        if (!_.isUndefined(companyList) && !_.isNull(companyList) && (companyList.length === 0)) {
            getCompanyList();
        }
        if (!_.isUndefined(recruiter) && !_.isNull(recruiter) && (recruiter.length === 0)) {
            getRalaAdminList();
        }
    }, []);

    useEffect(() => {
        if (updatedInterviewId != "") {
            updateCandidateProfile(updatedInterviewId, interviewerBody);
        }
    }, [updatedInterviewId])

    useEffect(() => {
        if (searchText) {
            setFilteredSuggestions(suggestions.filter((item) => item.toLowerCase().includes(searchText.toLowerCase())))
        } else {
            setFilteredSuggestions(suggestions)
        }
        doSearch();
    }, [searchText, navType, selectedFields]);

    const getRalaAdminList = () => {
        setSearchText("")
        fetch(`${API_URL}api/ralaAdminUsers`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && (response.data.length > 0)) {
                    setRecruiter(response.data);
                    setInititalRecruiter(response.data);
                }
            })
            .catch((err) => {
                setRecruiter([]);
            });
    }

    const getSingleJobInterviews = () => {
        const apiUrl = `${API_URL}api/jobs/${jobId}`
        fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            }
        }
        )
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data)) {
                    const interviewerData = [];
                    const buCodes = [];
                    const allJobIds = [];
                    const allJobTitles = [];
                    var condidateSuggestions = [];

                    const job = response.data;
                    // if (userDetails.account_type == SUPER_ADMIN || userDetails.account_type === SUB_ADMIN || ( job.qc_status != null && job.qc_status != "")
                    // ) {
                    if (job.candidates && job.candidates.length > 0) {
                        job.candidates.map((function (item) {
                            // if (item.scheduler_status === SCHEDULER_STATUS_DONE) {
                            item.jobTitle = job.job_title;
                            item.finalStatus = getInterviewFinalStatus(item.final_status);
                            item.incompleteInterview = getincompleteInterview(item.incomplete_interview);
                            item.schedulerFirstName = item.scheduler ? item.scheduler.first_name : "";
                            item.schedulerMobileNumber = item.scheduler ? item.scheduler.mobile_number : "";
                            item.interviewrFirstName = item.interview ? item.interview.first_name : "";
                            item.interviewMobileNumber = item.interview ? item.interview.mobile_number : "";
                            item.jobBuCode = job.bu_code;
                            item.interviewCreatedAt = commonDateFormat(item.createdAt);
                            item.scheduledOn = commonDateFormat(item.scheduled_on);
                            item.qcStatus = qcStatus(item);


                            if (!condidateSuggestions.includes(item.candidate_name.trim())) {
                                condidateSuggestions.push(item.candidate_name.trim())
                            }

                            interviewerData.push({
                                ...item,
                                job: job
                            });

                            // }
                        }));
                    }
                    if (job.bu_code != "" && job.bu_code != null) {
                        buCodes.push(job.bu_code)
                    }
                    allJobIds.push(job._id);
                    allJobTitles.push(job.job_title);
                    // }

                    setSuggestions(condidateSuggestions)
                    setFilteredSuggestions(condidateSuggestions)

                    setBuCode(buCodes);
                    setJobIds(allJobIds)
                    setJobTitles(allJobTitles)
                    setInterviewList(interviewerData);
                    setInitialData(interviewerData)
                    setUpdatedInterviewId("")

                    setBreadJobTitle(allJobTitles.length ? allJobTitles[0] : "")
                }
            })
            .catch((err) => {
                setInterviewList([]);
            });
    }

    const getinterviewList = () => {
        if (jobId != "" && jobId != undefined && jobId != null) {
            getSingleJobInterviews()
        } else {
            setIsLoading(true)
            const apiUrl = `${API_URL}api/jobs`;
            fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify({
                    status: ""
                })
            }
            )
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && (response.data.length > 0)) {
                        const interviewerData = [];
                        const buCodes = [];
                        const allJobIds = [];
                        const allJobTitles = [];
                        var condidateSuggestions = [];
                        response.data.map(function (job) {
                            // if (userDetails.account_type == SUPER_ADMIN || userDetails.account_type === SUB_ADMIN || ( job.qc_status != null && job.qc_status != "")
                            // ) {
                            if (job.candidates && job.candidates.length > 0) {
                                job.candidates.map((function (item) {
                                    // if (item.scheduler_status === SCHEDULER_STATUS_DONE) {
                                    item.jobTitle = job.job_title;
                                    item.finalStatus = getInterviewFinalStatus(item.final_status);
                                    item.incompleteInterview = getincompleteInterview(item.incomplete_interview);
                                    item.schedulerFirstName = item.scheduler ? item.scheduler.first_name : "";
                                    item.schedulerMobileNumber = item.scheduler ? item.scheduler.mobile_number : "";
                                    item.interviewrFirstName = item.interview ? item.interview.first_name : "";
                                    item.interviewMobileNumber = item.interview ? item.interview.mobile_number : "";
                                    item.jobBuCode = job.bu_code;
                                    item.interviewCreatedAt = commonDateFormat(item.createdAt);
                                    item.scheduledOn = commonDateFormat(item.scheduled_on);
                                    item.qcStatus = qcStatus(item);


                                    if (!condidateSuggestions.includes(item.candidate_name.trim())) {
                                        condidateSuggestions.push(item.candidate_name.trim())
                                    }

                                    interviewerData.push({
                                        ...item,
                                        job: job
                                    });

                                    // }
                                }));
                            }
                            if (job.bu_code != "" && job.bu_code != null) {
                                buCodes.push(job.bu_code)
                            }
                            allJobIds.push(job._id);
                            allJobTitles.push(job.job_title);
                            // }
                        })

                        setSuggestions(condidateSuggestions)
                        setFilteredSuggestions(condidateSuggestions)

                        setBuCode(buCodes);
                        setJobIds(allJobIds)
                        setJobTitles(allJobTitles)
                        setInterviewList(interviewerData);
                        setInitialData(interviewerData)
                        setUpdatedInterviewId("")
                    }
                    setIsLoading(false)
                })
                .catch((err) => {
                    setInterviewList([]);
                    setIsLoading(false)
                });
        }

    }

    const getCompanyList = () => {
        fetch(`${API_URL}api/companies`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && (response.data.length > 0)) {
                    setCompanyList(response.data);
                }
            })
            .catch((err) => {
                setCompanyList([]);
            });
    }

    const doSearch = () => {

        let companyMemberIds = [];
        if (selectedFields.company.length > 0) {
            const filterCompanies = companyList.filter((item) => selectedFields.company.includes(item.id));
            const companyMembers = filterCompanies.map((item) => item.members);
            for (let index = 0; index < companyMembers.length; index++) {
                for (let memberIndex = 0; memberIndex < companyMembers[index].length; memberIndex++) {
                    companyMemberIds.push(companyMembers[index][memberIndex].id)
                }
            }
            companyMemberIds = companyMemberIds.concat(selectedFields.company)
        }

        let ralaSubAdminIds = [];
        if (selectedFields.recruiter.length > 0) {
            const filterRalaAdmins = recruiter.filter((item) => selectedFields.recruiter.includes(item.id));
            for (let index = 0; index < filterRalaAdmins.length; index++) {
                const filterCompanies = filterRalaAdmins[index].companies;
                for (let index1 = 0; index1 < filterCompanies.length; index1++) {
                    ralaSubAdminIds.push(filterCompanies[index1].id)
                    const companyMembers = filterCompanies[index1].members;
                    for (let memberIndex = 0; memberIndex < companyMembers.length; memberIndex++) {
                        ralaSubAdminIds.push(companyMembers[memberIndex].id)
                    }
                }
                ralaSubAdminIds.push(filterRalaAdmins[index].id)
            }
        }

        var filtered = initialData.filter(item => {
            // Filter based on search text if provided
            const modifiedItem = [
                item.job_id,
                item._id,
                item.candidate_name,
                item.candidate_email,
                item.candidate_mobile_number,
                item.jobTitle,
                getInterviewFinalStatus(item.final_status),
                getincompleteInterview(item.incomplete_interview),
                item.overall_rating,
                item.scheduler ? item.scheduler.first_name : "",
                item.scheduler ? item.scheduler.mobile_number : "",
                item.interview ? item.interview.first_name : "",
                item.interview ? item.interview.mobile_number : "",
                item.jobBuCode,
                commonDateFormat(item.createdAt),
                item.scheduled_on ? commonDateFormat(item.scheduled_on) : "",
                item.video,
                item.feedback_sheet,
                getQCStatuslabel(item.qc_status),
                item.follow_up_attempts.length
            ];
            const matchesSearchText = !searchText || modifiedItem.join(",").toLowerCase().includes(searchText.toLowerCase());
            // Filter based on date range if dates are selected
            const uploadedDate = new Date(item.createdAt);
            // Adjust the date to the user's local time zone
            const adjustedStartDate = fromDate ? new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000) : null;
            const adjustedEndDate = toDate ? new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000) : null;
            var isCompanyExist = true;
            if (selectedFields.company.length > 0) {
                isCompanyExist = companyMemberIds.includes(item.job.job_created_by_id);
            }
            let isRecruiterExist = true;
            if (selectedFields.recruiter.length > 0) {
                isRecruiterExist = ralaSubAdminIds.includes(item.job.job_created_by_id);
            }
            const isJobTitleExist = selectedFields.jobTitle.length === 0 || selectedFields.jobTitle.includes(item.jobTitle);
            const isJobExist = selectedFields.jobId.length === 0 || selectedFields.jobId.includes(item.job._id);
            const isRatingExist = selectedFields.rating.length === 0 || selectedFields.rating.includes(item.overall_rating);
            const isStatusExist = selectedFields.status.length === 0 || selectedFields.status.includes(item.final_status) || selectedFields.status.some(element => item.incomplete_interview.includes(element));
            const isBuCodeExist = selectedFields.buCode.length === 0 || selectedFields.buCode.includes(item.jobBuCode);
            // Check if the date is within the selected range
            const isWithinDateRange = (!adjustedStartDate || uploadedDate >= getStartDate(adjustedStartDate)) && (!adjustedEndDate || uploadedDate <= getEndDate(adjustedEndDate));
            return matchesSearchText && isWithinDateRange && isCompanyExist && isRecruiterExist && isJobTitleExist && isJobExist && isRatingExist && isBuCodeExist && isStatusExist;
        });

        setInterviewList(filtered);
    }

    // don't remove this
    useEffect(() => {
        // console.log("interviewList", interviewList)
    }, [interviewList])


    const getStartDate = (date) => {
        const originalDate = new Date(date);
        originalDate.setHours(0, 0, 0, 0);
        return originalDate;
    }

    const getEndDate = (date) => {
        const originalDate = new Date(date);
        originalDate.setHours(23, 59, 59, 999);
        return originalDate;
    }

    const downloadCSV = () => {
        if (interviewList.length > 0) {
            const csvContent = convertToCsv(interviewList);
            const blob = new Blob([csvContent], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName() + '.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.current.show({ severity: 'success', summary: 'Success', detail: "Feedback downloaded successfully", life: 3000 });
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Currently, there are no records available for download.', life: 3000 });
        }
    };

    async function imageToBase64(url) {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise((resolve, reject) => {
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
            });
        } catch (error) {
            console.error('Error converting image to base64:', error);
            return null;
        }
    }

    const getInterviewDetails = async (id) => {
        try {
            const response = await fetch(`${API_URL}api/getInterview/${id}`, {
                headers: {
                    'Authorization': `Bearer ${userDetails.token}`,
                }
            });

            const responseData = await response.json();
            const interviewData = responseData.data;
            var averagePrimarySkillsRating = 0;
            interviewData.primary_skill_feedback.map((item) => {
                averagePrimarySkillsRating += Number(item.rating);
            });
            interviewData.averagePrimarySkillsRating = Math.floor(averagePrimarySkillsRating / interviewData.primary_skill_feedback.length);
            var averageSecondarySkillsRating = 0;
            interviewData && interviewData.secondary_skill_feedback.map((item) => {
                averageSecondarySkillsRating += Number(item.rating);
            });
            interviewData.averageSecondarySkillsRating = Math.floor(averageSecondarySkillsRating / interviewData.primary_skill_feedback.length);

            const uploadedScreenshots = [];
            for (var j = 0; j < interviewData.code_of_screenshot.length; j++) {
                const base64 = await imageToBase64(interviewData.code_of_screenshot[j]);
                uploadedScreenshots.push(base64);
            }

            interviewData.uploadedScreenshots = uploadedScreenshots;

            return interviewData;
        } catch (error) {
            console.log("error", error)
            return null;
        }
    }

    const downloadSingleReport = async (interviewId) => {
        setIsLoading(true);
        const contents = [];
        const interviewDetail = await getInterviewDetails(interviewId);
        const htmlContent = generateContent(interviewDetail);
        contents.push(htmlContent);
        const pdfBlob = await html2pdf().from(htmlContent).toPdf().output('blob');

        const link = document.createElement('a');
        link.href = URL.createObjectURL(pdfBlob);
        link.download = 'report.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setIsLoading(false);
    }

    const downloadZIP = async () => {
        setIsLoading(true);
        const contents = [];
        for (const item of interviewList) {
            if (item.is_feedback_given) {
                if (item.feedback_sheet === "") {
                    const interviewDetail = await getInterviewDetails(item._id);
                    if (interviewDetail) {
                        const htmlContent = generateContent(interviewDetail);
                        contents.push({ candidateId: item._id, type: 'html', content: htmlContent });
                    }
                } else {
                    contents.push({ candidateId: item._id, type: 'file', content: item.feedback_sheet, fileName: `file_${item._id}` });
                }
            }
        }

        if (contents.length > 0) {
            const zip = new JSZip();
            await Promise.all(contents.map(async (item, index) => {
                if (item.type === 'html') {
                    const element = document.createElement('div');
                    element.innerHTML = item.content;

                    // Improved PDF generation with higher quality and pagebreak handling
                    const opt = {
                        margin: [0.5, 0.5, 0.5, 0.5], // Top, Right, Bottom, Left margins
                        filename: `file_${index + 1}.pdf`,
                        image: { type: 'jpeg', quality: 0.98 },
                        html2canvas: { scale: 2 },
                        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                        pagebreak: { mode: ['css', 'legacy'] }
                    };

                    const pdfBlob = await html2pdf().set(opt).from(element).toPdf().output('blob');

                    zip.file(`pdf_${item.candidateId}.pdf`, pdfBlob);
                } else {
                    const response = await fetch(item.content); // Assuming item.content is the URL of the file
                    const fileBlob = await response.blob();
                    const fileExtension = item.content.split('.').pop(); // Get file extension from URL
                    zip.file(`${item.fileName}.${fileExtension}`, fileBlob);
                }
            }));

            zip.generateAsync({ type: 'blob' }).then((content) => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(content);
                link.download = 'files.zip';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });

            setIsLoading(false);
            toast.current.show({ severity: 'success', summary: 'Success', detail: "Feedback downloaded successfully", life: 3000 });
        } else {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Currently, there are no records available for download.', life: 3000 });
        }
    }

    const reportData = () => {
        selectedItems.length = 0;
        if (interviewList && interviewList.length > 0) {
            for (var index = 0; index < interviewList.length; index++) {
                if (!interviewList[index].is_sent_to_employeer) {
                    selectedItems.push(interviewList[index]._id);
                }
            }
            sentToEmployeer();
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Currently, there are no records to send report.', life: 3000 });
        }
    }

    const convertToCsv = (data) => {
        const header = "Job ID,Candidate ID,Candidate Name,Email ID,Mobile Number,Job Title,Status,Incomplete Interview,Rating,Scheduler Name,Scheduler Number,Interviewer Name,Interviewer Number,BU Code,Profile Uploaded,Interview Scheduled,Video,QC Status,QC Remark,Follow Up Attempts,History"
        const rows = data.map((row) => {
            const followUpData = row.follow_up_attempts.map((item) => {
                return `${commonDateFormat(item.follow_up_date)} ${item.follow_up_reason.replace(",", "")} ${item.upload_by.replace(",", "")}`;
            }).join('        ');
            return [
                row.job_id,
                row._id,
                row.candidate_name,
                row.candidate_email,
                row.candidate_mobile_number,
                row.jobTitle,
                getInterviewFinalStatus(row.final_status),
                getincompleteInterview(row.incomplete_interview),
                row.overall_rating,
                row.scheduler ? row.scheduler.first_name : "",
                row.scheduler ? row.scheduler.mobile_number : "",
                row.interview ? row.interview.first_name : "",
                row.interview ? row.interview.mobile_number : "",
                row.jobBuCode,
                commonDateFormat(row.createdAt),
                row.scheduled_on ? commonDateFormat(row.scheduled_on) : "",
                row.video,
                getQCStatuslabel(row.qc_status),
                row.qc_remark.replace(",", ""),
                row.follow_up_attempts.length,
                followUpData
            ];
        });
        const csvContent = `${header}\n${rows.map(row => row.join(',')).join('\n')}`;

        return csvContent;
    };

    const qcStatus = (rowData) => {
        return ((userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && editedId === rowData._id) ? (
            <Form.Select style={{ width: '100px' }}
                onChange={(e) => {
                    const value = e.target.value;
                    const a = interviewList.map((interviewer) =>
                        interviewer._id === rowData._id
                            ? { ...interviewer, qc_status: value ? QC_STATUS_PASS : QC_STATUS_FAIL }
                            : interviewer
                    );
                    setInterviewList(a)
                    setInterviewerBody({
                        ...interviewerBody,
                        qc_status: value,
                        qc_remark: rowData.qc_remark
                    })
                    if (value === QC_STATUS_FAIL) {
                        setInterviewIdForQcRemark(rowData._id);
                        setIsShowQCRemark(true);
                    } else {
                        setUpdatedInterviewId(rowData._id);
                    }
                }}
            >
                <option value="">Actions</option>
                <option style={{ color: 'green' }} selected={rowData.qc_status === QC_STATUS_PASS} value={QC_STATUS_PASS}>Pass</option>
                <option style={{ color: 'red' }} selected={rowData.qc_status === QC_STATUS_FAIL} value={QC_STATUS_FAIL}>Fail</option>
            </Form.Select>
        ) : (
            <div style={{ color: rowData.qc_status === QC_STATUS_PASS ? 'green' : (rowData.qc_status === QC_STATUS_FAIL ? 'red' : '') }}>{getQCStatuslabel(rowData.qc_status)}</div>
        )
    }

    const canEditStatus = (rowData) => {
        const canEdit = (dayDifference(rowData.scheduled_on) <= 1 || (rowData.is_super_admin_given_edit_permission && dayDifference(rowData.super_admin_given_edit_permission_date) <= 1))
        if (editedId === rowData._id) {
            return <Form.Select style={{ width: '100px' }}
                onChange={(e) => {
                    const value = e.target.value;
                    const a = interviewList.map((interviewer) =>
                        interviewer._id === rowData._id
                            ? { ...interviewer, is_super_admin_given_edit_permission: value }
                            : interviewer
                    );
                    setInterviewList(a)
                    setInterviewerBody({
                        ...interviewerBody,
                        is_super_admin_given_edit_permission: value,
                        super_admin_given_edit_permission_date: new Date()
                    })
                    setUpdatedInterviewId(rowData._id);
                }}
            >
                <option selected={canEdit === true} value={true}>Yes</option>
                <option selected={canEdit === false} value={false}>No</option>
            </Form.Select>
        } else if (canEdit) {
            return <Badge bg="success">Yes</Badge>;
        } else {
            return <Badge bg="danger">No</Badge>;
        }
    }

    const updateCandidateProfile = (candidateId, body, isCallApi = true, message = "") => {
        fetch(`${API_URL}api/updateCandidateProfileData/${candidateId}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((response) => {
                if (isCallApi) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: message ? message : "Report updated successfully", life: 3000 });
                    getinterviewList()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            });
    }

    const sentInterviewtoEmployeer = async (candidateId, body) => {
        try {
            const response = await fetch(`${API_URL}api/updateCandidateProfileData/${candidateId}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(body)
            });
            const responseData = await response.json();
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
        }
    };

    const location = useLocation();
    const navigate = useNavigate();

    const sentToEmployeer = async () => {
        if (selectedItems.length > 0) {
            setIsLoading(true)
            for (const item of selectedItems) {
                await sentInterviewtoEmployeer(item, { is_sent_to_employeer: true }, false);
            }
            setIsLoading(false)
            toast.current.show({ severity: 'success', summary: 'Success', detail: "Feedback sent successfully", life: 3000 });
            navigate(location.pathname, { replace: true });
            getinterviewList();
            setSelectedItems([]);
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Currently, there are no records to send report.', life: 3000 });
        }
    }

    const handleCheckboxChange = (fieldType, value) => {
        setSelectedFields((prevSelectedFields) => {
            const isSelected = prevSelectedFields[fieldType].includes(value);
            let updatedFields = {
                ...prevSelectedFields,
                [fieldType]: isSelected
                    ? prevSelectedFields[fieldType].filter((item) => item !== value)
                    : [...prevSelectedFields[fieldType], value],
            };
            if (fieldType === "company") {
                updatedFields['recruiter'] = [];
                if (updatedFields.company.length === 0) {
                    setRecruiter(inititalRecruiter)
                } else {
                    const recruiterIds = (companyList.filter((item) => updatedFields.company.includes(item.id))).map((item) => item.assigned_rala_sub_admin)
                    const filteredRecruiters = inititalRecruiter.filter((item) => recruiterIds.includes(item.id))
                    setRecruiter(filteredRecruiters)
                }
            }
            return updatedFields;
        });
    };

    const toggleAll = (checked) => {
        if (checked) {
            var items = [];
            interviewList.map((item) => {
                if (item.is_feedback_given && !item.is_sent_to_employeer) {
                    items.push(item._id);
                }
            })
            setSelectedItems(items)
        } else {
            setSelectedItems([])
        }
    }

    const [isShowVideoModal, setIsShowVideoModal] = useState(false);
    const [videoLinkError, setVideoLinkError] = useState("");
    const [videoLink, setVideoLink] = useState('');

    const handleClear = (name) => {
        if (name == 'toDate') {
            setToDate(null);
        } else {
            setFromDate(null);
        }
    };

    const clearSearch = () => {
        setSearchText("")
    }

    const CustomInput = forwardRef(({ value, onClick, onClear }, ref) => (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <input
                value={value}
                onClick={onClick}
                ref={ref}
                readOnly
                style={{ width: '100%' }}
                placeholder="dd/mm/yyyy"
                className="form-control" // Assuming you're using Bootstrap for styling
            />
            {value && (
                <button
                    type="button"
                    onClick={onClear}
                    style={{
                        position: 'absolute',
                        right: '5px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        background: 'none',
                        border: 'none',
                        fontSize: '16px',
                        color: '#d9534f' // Bootstrap danger color
                    }}
                >
                    &#x2715; {/* Unicode character for an "X" mark */}
                </button>
            )}
        </div>
    ));

    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'asc' });

    const onSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        sortData(interviewList, sortConfig.key, sortConfig.direction);
    }, [sortConfig])

    const sortData = ([...list], key, direction) => {
        const sortedData = list ? list.sort((a, b) => {
            var value1 = a[key];
            var value2 = b[key];

            if (key === 'scheduler_name') {
                value1 = a['scheduler'] ? a['scheduler']['first_name'] : "";
                value2 = b['scheduler'] ? b['scheduler']['first_name'] : "";
            }

            if (key === 'scheduler_mobile_number') {
                value1 = a['scheduler'] ? a['scheduler']['mobile_number'] : "";
                value2 = b['scheduler'] ? b['scheduler']['mobile_number'] : "";
            }

            if (key === 'interview_name') {
                value1 = a['interview'] ? a['interview']['first_name'] : "";
                value2 = b['interview'] ? b['interview']['first_name'] : "";
            }

            if (key === 'interview_mobile_number') {
                value1 = a['interview'] ? a['interview']['mobile_number'] : "";
                value2 = b['interview'] ? b['interview']['mobile_number'] : "";
            }

            if (key === "scheduled_on" || key === "createdAt") {
                value1 = new Date(a[key]);
                value2 = new Date(b[key]);
            }

            if (typeof value1 === 'string' && typeof value2 === 'string') {
                if (direction === 'asc') {
                    return value1.localeCompare(value2);
                } else {
                    return value2.localeCompare(value1);
                }
            }

            if (typeof value1 === 'object' && typeof value2 === 'object') {
                if (direction === 'asc') {
                    return value1 - value2;
                } else {
                    return value2 - value1;
                }
            }

            if (value1 < value2) return direction === 'asc' ? -1 : 1;
            if (value1 > value2) return direction === 'asc' ? 1 : -1;

            return 0;
        }) : [];

        setInterviewList(sortedData)

    }

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) {
            return <FaSort />;
        }
        if (sortConfig.direction === 'asc') {
            return <FaSortUp />;
        }
        return <FaSortDown />;
    };

    const clearFilter = () => {
        setRecruiter(inititalRecruiter)
        setSelectedFields({
            company: [],
            recruiter: [],
            jobTitle: [],
            jobId: [],
            rating: [],
            status: [],
            buCode: [],
        })
    }

    return (
        <Fragment>
            <Toast ref={toast} />
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <div className="container-fluid history-container fixed-padding-3">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 pageTitle_fixed">
                        <p className="employer-dashboard-jobs-posted mb-0">
                            <div className='d-flex align-items-center gap-2'>
                                <BiSolidReport size={18} />
                                {
                                    (jobId != undefined && jobId != null && jobId != "") ? (
                                        breadJobTitle + " / " + jobId.substring(0, 5) + "..."
                                    ) : "REPORT"
                                }
                            </div>
                        </p>
                    </div>
                </div>
                <div className="row mb-3 date_filter">
                    <div className='col-12 col-md-12 col-lg-4 mb-2'>
                        <div className='d-flex gap-2'>
                            <Button variant="outline-primary" onClick={downloadCSV}>
                                <FaFileExcel /> {" "} CVC
                            </Button>
                            <Button variant="outline-primary" onClick={reportData}>
                                <FaMailBulk /> {" "} Report
                            </Button>
                            <Button variant="outline-primary" onClick={downloadZIP}>
                                <FaDownload /> {" "} Feedback
                            </Button>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 col-lg-5 mb-2'>
                        <div className='row'>
                            <div className='col-12 col-md-4 col-lg-4'>
                                <DatePicker className="form-select w-100 " maxDate={toDate ? new Date(toDate) : null} onChange={(date) => {
                                    setFromDate(date);
                                    handleClear('toDate');
                                }} selected={fromDate} placeholderText="dd/mm/yyyy" dateFormat="dd/MM/yyyy" customInput={<CustomInput onClear={() => handleClear('fromDate')} />} />
                                <small className="last-one-month text-danger mt-2" style={{ float: 'left' }}>Available for last one month</small>
                            </div>
                            <div className='col-12 col-md-4 col-lg-4 mb-2 mb-md-0'>
                                <DatePicker className="form-select w-100 " minDate={fromDate ? new Date(fromDate) : null} maxDate={fromDate ? new Date(moment(fromDate).add(30, 'days')) : null} onChange={(date) => setToDate(date)} selected={toDate} placeholderText="dd/mm/yyyy" dateFormat="dd/MM/yyyy" customInput={<CustomInput onClear={() => handleClear('toDate')} />} />
                            </div>
                            <div className='col-12 col-md-4 col-lg-4'>
                                <Button className="history-search-button w-100" onClick={doSearch} style={{ width: '120px' }} >Search</Button>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 col-lg-3 mb-2'>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                id="Search"
                                onChange={(e) => {
                                    setSearchText(e.target.value.trim());
                                }}
                                name="Search"
                                value={searchText}
                                placeholder="Search"
                                maxLength={150}
                                onFocus={() => {
                                    if (userDetails && userDetails.account_type === SUPER_ADMIN) {
                                        setIsShowSuggestion(true)
                                    }
                                }}
                                onBlur={() => {
                                    if (userDetails && userDetails.account_type === SUPER_ADMIN) {
                                        setTimeout(() => {
                                            setIsShowSuggestion(false);
                                        }, 100);
                                    }
                                }}
                                autoComplete="off"
                            />
                            {
                                searchText && (
                                    <InputGroup.Text onClick={clearSearch} className="search-clear cursor-pointer">&#x2715;</InputGroup.Text>
                                )
                            }
                        </InputGroup>
                        {
                            isShowSuggestion &&
                            (
                                <ListGroup
                                    className="position-absolute"
                                    style={{
                                        position: 'absolute',
                                        zIndex: 999,
                                        maxHeight: '400px',
                                        width: '100%',
                                        overflow: 'auto',
                                        width: '90%'
                                    }}>
                                    {filteredSuggestions.map((item, index) => (
                                        <ListGroup.Item
                                            className="cursor-pointer"
                                            key={index}
                                            action
                                            onMouseDown={() => setSearchText(item)}
                                        >
                                            {item}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            )
                        }
                    </div>

                </div>
                <div className="row history-feednback-row">
                    <div className="col-12">
                        {
                            ((userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN || userDetails.account_type === SUB_ADMIN) && jobId != undefined && jobId != "" && jobId != null) && (
                                <Tab.Container defaultActiveKey="first">
                                    <Nav fill variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first" onClick={() => {
                                                setNavType('Pending');
                                                setReportPageNumber(1);
                                            }} >Pending</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second" onClick={() => {
                                                setNavType('Sent');
                                                setReportPageNumber(1);
                                            }}>Sent</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Tab.Container>
                            )
                        }
                        <Card>
                            <Card.Body>
                                <div className="row">
                                    {
                                        (userDetails && (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN || userDetails.account_type === SUB_ADMIN)) && (
                                            <div className="col-1 report-filter-section" style={{ width: '50px' }}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip>Filters</Tooltip>}
                                                >
                                                    <DropdownButton id="report-filter" title={<CiFilter style={{ width: '25px', height: '25px' }} />} style={{ backgroundImage: 'none' }} >
                                                        {
                                                            userDetails && userDetails.account_type === SUPER_ADMIN && (
                                                                <DropdownButton className="nested-button" title={"Company" + (selectedFields.company.length > 0 ? ` (${selectedFields.company.length})` : "")}>
                                                                    {companyList &&
                                                                        companyList.map((item, index) => (
                                                                            <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }} key={item.id}>
                                                                                <span title={item.company_name} className="filter-item">{item.company_name}</span>
                                                                                <InputGroup.Checkbox
                                                                                    key={"company" + index}
                                                                                    aria-label="Checkbox for following text input"
                                                                                    onChange={() => handleCheckboxChange('company', item.id)}
                                                                                    checked={selectedFields.company.includes(item.id)}
                                                                                />
                                                                            </InputGroup>
                                                                        ))}
                                                                </DropdownButton>
                                                            )
                                                        }
                                                        {
                                                            userDetails && userDetails.account_type === SUPER_ADMIN && (
                                                                <DropdownButton className="nested-button" title={"Recruiter" + (selectedFields.recruiter.length > 0 ? ` (${selectedFields.recruiter.length})` : "")}>
                                                                    {recruiter &&
                                                                        recruiter.map((item, index) => (
                                                                            <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }} key={item.id}>
                                                                                <span title={item.first_name + " " + (item.last_name ?? "")} className="filter-item">{item.first_name + " " + (item.last_name ?? "")}</span>
                                                                                <InputGroup.Checkbox
                                                                                    key={"recruiter" + index}
                                                                                    aria-label="Checkbox for following text input"
                                                                                    onChange={() => handleCheckboxChange('recruiter', item.id)}
                                                                                    checked={selectedFields.recruiter.includes(item.id)}
                                                                                />
                                                                            </InputGroup>
                                                                        ))}
                                                                </DropdownButton>
                                                            )
                                                        }
                                                        <DropdownButton className="nested-button" title={"Job Title" + (selectedFields.jobTitle.length > 0 ? ` (${selectedFields.jobTitle.length})` : "")}>
                                                            {
                                                                jobTitles.map((item, index) => {
                                                                    return <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                        <span title={item} className="filter-item">{item}</span>
                                                                        <InputGroup.Checkbox
                                                                            key={"job_title" + index}
                                                                            aria-label="Checkbox for following text input"
                                                                            onChange={() => handleCheckboxChange('jobTitle', item)}
                                                                            checked={selectedFields.jobTitle.includes(item)}
                                                                        />
                                                                    </InputGroup>;
                                                                })
                                                            }
                                                        </DropdownButton>
                                                        <DropdownButton className="nested-button" title={"Job ID" + (selectedFields.jobId.length > 0 ? ` (${selectedFields.jobId.length})` : "")}>
                                                            {
                                                                jobIds.map((item, index) => {
                                                                    return <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                        <span title={item} className="filter-item">{item}</span>
                                                                        <InputGroup.Checkbox
                                                                            key={"job_id" + index}
                                                                            aria-label="Checkbox for following text input"
                                                                            onChange={() => handleCheckboxChange('jobId', item)}
                                                                            checked={selectedFields.jobId.includes(item)}
                                                                        />
                                                                    </InputGroup>;
                                                                })
                                                            }
                                                        </DropdownButton>
                                                        <DropdownButton className="nested-button" title={"Rating" + (selectedFields.rating.length > 0 ? ` (${selectedFields.rating.length})` : "")}>

                                                            {
                                                                [1, 2, 3, 4, 5].map((item, index) => {
                                                                    return <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                        <span title={item} className="filter-item">{item}</span>
                                                                        <InputGroup.Checkbox
                                                                            key={"job_id" + index}
                                                                            aria-label="Checkbox for following text input"
                                                                            onChange={() => handleCheckboxChange('rating', item)}
                                                                            checked={selectedFields.rating.includes(item)}
                                                                        />
                                                                    </InputGroup>;
                                                                })
                                                            }
                                                        </DropdownButton>
                                                        <DropdownButton className="nested-button" title={"Status" + (selectedFields.status.length > 0 ? ` (${selectedFields.status.length})` : "")}>
                                                            <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                {'Selcted'}
                                                                <InputGroup.Checkbox
                                                                    key={"status" + 0}
                                                                    aria-label="Checkbox for following text input"
                                                                    onChange={() => handleCheckboxChange('status', INTERVIEW_FINAL_STATUS_SELECTED)}
                                                                    checked={selectedFields.status.includes(INTERVIEW_FINAL_STATUS_SELECTED)}
                                                                />
                                                            </InputGroup>
                                                            <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                {'Rejected'}
                                                                <InputGroup.Checkbox
                                                                    key={"status" + 1}
                                                                    aria-label="Checkbox for following text input"
                                                                    onChange={() => handleCheckboxChange('status', INTERVIEW_FINAL_STATUS_REJECTED)}
                                                                    checked={selectedFields.status.includes(INTERVIEW_FINAL_STATUS_REJECTED)}
                                                                />
                                                            </InputGroup>
                                                            <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                {'No Show'}
                                                                <InputGroup.Checkbox
                                                                    key={"status" + 2}
                                                                    aria-label="Checkbox for following text input"
                                                                    onChange={() => handleCheckboxChange('status', 'NO_SHOW')}
                                                                    checked={selectedFields.status.includes('NO_SHOW')}
                                                                />
                                                            </InputGroup>
                                                            <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                {'Proxy'}
                                                                <InputGroup.Checkbox
                                                                    key={"status" + 3}
                                                                    aria-label="Checkbox for following text input"
                                                                    onChange={() => handleCheckboxChange('status', PROXY)}
                                                                    checked={selectedFields.status.includes(PROXY)}
                                                                />
                                                            </InputGroup>
                                                        </DropdownButton>
                                                        <DropdownButton className="nested-button" title={"BU Code" + (selectedFields.buCode.length > 0 ? ` (${selectedFields.buCode.length})` : "")}>
                                                            {
                                                                buCode.map((item, index) => {
                                                                    return <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                        <span title={item} className="filter-item">{item}</span>
                                                                        <InputGroup.Checkbox
                                                                            key={"job_id" + index}
                                                                            aria-label="Checkbox for following text input"
                                                                            onChange={() => handleCheckboxChange('buCode', item)}
                                                                            checked={selectedFields.buCode.includes(item)}
                                                                        />
                                                                    </InputGroup>;
                                                                })
                                                            }
                                                        </DropdownButton>
                                                        <div className="my-2" style={{ textAlign: 'center' }}>
                                                            <Button onClick={clearFilter} style={{
                                                                marginTop: '0px',
                                                                background: '#FFFBFE',
                                                                color: 'black',
                                                                border: 'none'
                                                            }} className="employer-dashboard-interview-join">
                                                                Reset
                                                            </Button>
                                                        </div>
                                                    </DropdownButton>
                                                </OverlayTrigger>
                                            </div>
                                        )
                                    }
                                    <div className="col-11 reporTable" style={{ flex: 1 }}>
                                        <Table bordered striped hover size="lg" responsive className="history-feednback-completed">
                                            <thead className='sticky-top'>
                                                <tr>
                                                    {
                                                        ((userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && jobId && navType === 'Pending') && (
                                                            <th>
                                                                <input onChange={(e) => toggleAll(e.target.checked)} type="checkbox" />
                                                            </th>
                                                        )
                                                    }
                                                    <th className='text-start' onClick={() => onSort('job_id')}>Job ID {getSortIcon('job_id')}</th>
                                                    <th className='text-start' onClick={() => onSort('_id')}>Candidate ID {getSortIcon('_id')}</th>
                                                    <th className='text-start' onClick={() => onSort('candidate_name')}>Candidate Name {getSortIcon('candidate_name')}</th>
                                                    <th className='text-start' onClick={() => onSort('candidate_email')}>Email ID {getSortIcon('candidate_email')}</th>
                                                    <th className='text-start' onClick={() => onSort('candidate_mobile_number')}>Mobile Number {getSortIcon('candidate_mobile_number')}</th>
                                                    <th className='text-start' onClick={() => onSort('jobTitle')}>Job Title {getSortIcon('jobTitle')}</th>
                                                    <th className='text-start' onClick={() => onSort('final_status')}>Status {getSortIcon('final_status')}</th>
                                                    <th className='text-start' onClick={() => onSort('incomplete_interview')}>Incomplete Interview {getSortIcon('incomplete_interview')}</th>
                                                    <th className='text-start' onClick={() => onSort('overall_rating')}>Rating {getSortIcon('overall_rating')}</th>
                                                    <th className='text-start' onClick={() => onSort('scheduler_name')}>Scheduler Name {getSortIcon('scheduler_name')}</th>
                                                    <th className='text-start' onClick={() => onSort('scheduler_mobile_number')}>Scheduler Number {getSortIcon('scheduler_mobile_number')}</th>
                                                    <th className='text-start' onClick={() => onSort('interview_name')}>Interviewer Name {getSortIcon('interview_name')}</th>
                                                    <th className='text-start' onClick={() => onSort('interview_mobile_number')}>Interviewer Number {getSortIcon('interview_mobile_number')}</th>
                                                    <th className='text-start' onClick={() => onSort('jobBuCode')}>BU Code {getSortIcon('jobBuCode')}</th>
                                                    <th className='text-start' onClick={() => onSort('createdAt')}>Profile Uploaded {getSortIcon('createdAt')}</th>
                                                    <th className='text-start' onClick={() => onSort('scheduled_on')}>Interview Scheduled {getSortIcon('scheduled_on')}</th>
                                                    <th>Video</th>
                                                    <th>Report</th>
                                                    {
                                                        userDetails && userDetails.account_type !== EMPLOYER_SUPER_ADMIN && userDetails.account_type !== EMPLOYER_SUB_ADMIN && (
                                                            <>
                                                                <th onClick={() => onSort('QCStatus')}>QC Status {getSortIcon('QCStatus')}</th>
                                                                <th>QC Remark</th>
                                                            </>
                                                        )
                                                    }
                                                    <th onClick={() => onSort('follow_up_attempts')}>Follow Up Attempts {getSortIcon('follow_up_attempts')}</th>
                                                    <th>History</th>
                                                    {
                                                        userDetails && userDetails.account_type === SUPER_ADMIN && (
                                                            <th>Can Edit</th>
                                                        )
                                                    }
                                                    {
                                                        // userDetails && userDetails.account_type !== EMPLOYER_SUPER_ADMIN && userDetails.account_type !== EMPLOYER_SUB_ADMIN 
                                                        userDetails
                                                        && (
                                                            <th>Edit</th>
                                                        )
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !_.isUndefined(interviewList) && !_.isNull(interviewList) &&
                                                    _.map(interviewList.filter((item) => {
                                                        if ((userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && jobId) {
                                                            if (navType === 'Pending') {
                                                                return !item.is_sent_to_employeer;
                                                            } else {
                                                                return item.is_sent_to_employeer;
                                                            }
                                                        }
                                                        return true;
                                                    }).slice((reportPageNumber - 1) * reportPageSize, ((reportPageNumber - 1) * reportPageSize) + reportPageSize)
                                                        , (interview, index) => {
                                                            return <tr key={"feednback-completed-" + index}>
                                                                {
                                                                    ((userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && jobId && navType === 'Pending' && interview.is_feedback_given) && (
                                                                        <td><input checked={selectedItems.includes(interview._id)} onChange={(e) => {
                                                                            const isChecked = e.target.checked;
                                                                            let updatedItems;
                                                                            if (isChecked) {
                                                                                updatedItems = [...selectedItems, interview._id];
                                                                            } else {
                                                                                updatedItems = selectedItems.filter((itemId) => itemId !== interview._id);
                                                                            }
                                                                            setSelectedItems(updatedItems);
                                                                        }} type="checkbox" /> </td>
                                                                    )
                                                                }
                                                                <td className="text-start">{interview.job_id}</td>
                                                                <td className="text-start">{interview._id}</td>
                                                                <td className="text-start fw-bold">{interview.candidate_name}</td>
                                                                <td className="text-start">{interview.candidate_email}</td>
                                                                <td className="text-start">{interview.candidate_mobile_number}</td>
                                                                <td className="text-start">{interview.jobTitle}</td>
                                                                <td className="text-start interviewSatus">
                                                                    <span className={interview.final_status === INTERVIEW_FINAL_STATUS_REJECTED ? 'rejected' : (interview.final_status === INTERVIEW_FINAL_STATUS_SELECTED ? 'selected' : '')} style={{
                                                                        color: interview.final_status === INTERVIEW_FINAL_STATUS_REJECTED ? '#840202' : (
                                                                            interview.final_status === INTERVIEW_FINAL_STATUS_SELECTED ? '#0d5c01' : ''
                                                                        )
                                                                    }}>{getInterviewFinalStatus(interview.final_status)}</span>
                                                                </td>
                                                                <td className="text-center">{getincompleteInterview(interview.incomplete_interview)}</td>
                                                                <td className="text-center">{interview.overall_rating}</td>
                                                                <td className="text-start">{interview.scheduler ? interview.scheduler.first_name : ""}</td>
                                                                <td className="text-start">{interview.scheduler ? interview.scheduler.mobile_number : ""}</td>
                                                                <td className="text-start">{interview.interview ? interview.interview.first_name : ""}</td>
                                                                <td className="text-start">{interview.interview ? interview.interview.mobile_number : ""}</td>
                                                                <td className="text-start">{interview.jobBuCode}</td>
                                                                <td className="text-start">{commonDateFormat(interview.createdAt)}</td>
                                                                <td className="text-start">{interview.scheduled_on ? commonDateFormat(interview.scheduled_on) : ""}</td>
                                                                <td className="text-center">
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip>{interview.video ? 'See Video Recording' : 'Add Video Recording'}</Tooltip>}
                                                                    >
                                                                        <button className="btn">
                                                                            {
                                                                                editedId === interview._id ?
                                                                                    <BiSolidBookAdd size={20} onClick={() => {
                                                                                        setVideoLink(interview.video);
                                                                                        setVideoLinkError("");
                                                                                        setIsShowVideoModal(true);
                                                                                    }} />
                                                                                    : (
                                                                                        interview.video && (
                                                                                            <BiSolidVideos size={20} onClick={() => {
                                                                                                window.open(interview.video, '_blank')
                                                                                            }} />
                                                                                        )
                                                                                    )
                                                                            }
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td className="text-center">
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={interview.is_feedback_given ? <Tooltip>Interview Report</Tooltip> : <></>}
                                                                    >
                                                                        <button className="btn">
                                                                            {
                                                                                (
                                                                                    (
                                                                                        (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && interview.is_feedback_given
                                                                                    ) ||
                                                                                    (
                                                                                        (userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN) && interview.is_sent_to_employeer
                                                                                    )
                                                                                ) && (
                                                                                    <FaFileAlt onClick={() => {
                                                                                        if (interview.feedback_sheet != "") {
                                                                                            window.open(interview.feedback_sheet, '_blank');
                                                                                        } else {
                                                                                            window.open(`/finalInterviewReport/${interview._id}`, '_blank')
                                                                                        }
                                                                                    }} />
                                                                                )
                                                                            }
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                {
                                                                    userDetails && userDetails.account_type !== EMPLOYER_SUPER_ADMIN && userDetails.account_type !== EMPLOYER_SUB_ADMIN && (
                                                                        <>
                                                                            <td className="text-center">
                                                                                {qcStatus(interview)}
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={<Tooltip>Add QC Remark</Tooltip>}
                                                                                >
                                                                                    <button className="btn">
                                                                                        <BiMessageRounded onClick={() => {
                                                                                            setInterviewIdForQcRemark(interview._id);
                                                                                            setIsShowQCRemark(true);
                                                                                        }} size={20} />
                                                                                    </button>
                                                                                </OverlayTrigger>
                                                                            </td>
                                                                        </>
                                                                    )
                                                                }
                                                                <td className="text-center">{interview.follow_up_attempts.length}</td>
                                                                <td className="text-center">
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip>Follow History</Tooltip>}
                                                                    >
                                                                        <button className="btn">
                                                                            <FaHistory onClick={() => {
                                                                                setInterviewIdForQcRemark(interview._id);
                                                                                setCandidateData(interview);
                                                                                setIsShowHistoryModel(true);
                                                                            }} size={20} />
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                {
                                                                    userDetails && userDetails.account_type === SUPER_ADMIN && (
                                                                        <td>
                                                                            {canEditStatus(interview)}
                                                                        </td>
                                                                    )
                                                                }
                                                                <td>
                                                                    {
                                                                        // userDetails && userDetails.account_type !== EMPLOYER_SUPER_ADMIN && userDetails.account_type !== EMPLOYER_SUB_ADMIN 
                                                                        userDetails
                                                                        && (
                                                                            <button

                                                                                className="btn btn-outline-primary btn-sm"
                                                                                // disabled={interview.feedback_date != null && dayDifference(interview.feedback_date) > 1}
                                                                                onClick={() => {
                                                                                    if (editedId === interview._id) {
                                                                                        setEditedId("")
                                                                                    } else {
                                                                                        setEditedId(interview._id)
                                                                                    }
                                                                                }}>
                                                                                {
                                                                                    editedId === interview._id ? 'Save' : 'Edit'
                                                                                }
                                                                            </button>
                                                                        )
                                                                    }
                                                                </td>

                                                            </tr>
                                                        })
                                                }
                                            </tbody>
                                        </Table>
                                        {
                                            ((userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && jobId != "" && jobId != undefined && jobId != null) && navType == 'Pending' && selectedItems.length > 0 && (
                                                <div className="col-12 my-auto" style={{ textAlign: 'right' }}>
                                                    <Button className="history-search-button" onClick={sentToEmployeer} >Send</Button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>


                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="employer-dashboard-title ">
                    {
                        interviewList && interviewList.length > 0 && (
                            <PaginationComponent data={interviewList.filter((item) => {
                                if ((userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && jobId) {
                                    if (navType === 'Pending') {
                                        return !item.is_sent_to_employeer;
                                    } else {
                                        return item.is_sent_to_employeer;
                                    }
                                }
                                return true;
                            })} updatePagination={updateReportPagination} />
                        )
                    }
                </div>
            </div>
            {
                isShowQCRemark && <Modal show={isShowQCRemark} centered>
                    <Modal.Body>
                        <Form>
                            <Form.Control
                                as="textarea"
                                placeholder="Leave a comment here"
                                style={{ height: '100px' }}
                                onChange={(e) => {
                                    setQcRemark(e.target.value)
                                }}
                            />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => { setIsShowQCRemark(false); }}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => {
                            updateCandidateProfile(interviewIdForQcRemark, {
                                ...interviewerBody,
                                qc_remark: qcRemark
                            });
                            setIsShowQCRemark(false)
                        }}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

            {
                isShowHistoryModel && <Modal show={isShowHistoryModel} centered>
                    <Modal.Body>
                        <Container>
                            <div style={{ textAlign: 'right' }}>
                                <Button onClick={() => {
                                    setAddedAttempts([
                                        ...addedAttempts,
                                        {
                                            follow_up_date: new Date(),
                                            follow_up_reason: 'follow_up_reason',
                                            upload_by: userDetails.first_name
                                        }
                                    ]);
                                }} >Add</Button>
                            </div>
                            <Row>
                                <Col>Candidate Name : </Col>
                                <Col><b>{candidateData ? candidateData.candidate_name : ""}</b></Col>
                            </Row>
                            <Row>
                                <Col>Email address : </Col>
                                <Col><b>{candidateData ? candidateData.candidate_email : ""}</b></Col>
                            </Row>
                            <Row>
                                <Col>Mobile number : </Col>
                                <Col><b>{candidateData ? candidateData.candidate_mobile_number : ""}</b></Col>
                            </Row>
                            <Row>
                                <p>Follow up attempts</p>
                            </Row>
                            <Row>
                                <Table bordered hover size="lg">
                                    <thead>
                                        <tr>
                                            <th>Follow Up Date</th>
                                            <th>Follow Up Reason</th>
                                            <th>Updated By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            candidateData && candidateData.follow_up_attempts.map((item) => {
                                                return (<tr>
                                                    <td>{commonDateFormat(item.follow_up_date)}</td>
                                                    <td>{item.follow_up_reason}</td>
                                                    <td>{item.upload_by}</td>
                                                </tr>);
                                            })
                                        }
                                        {
                                            addedAttempts && addedAttempts.map((item, index) => {
                                                return (<tr key={'attempts' + index}>
                                                    <td>
                                                        <DatePicker
                                                            onChange={(date) => {
                                                                setAddedAttempts((prevAttempts) =>
                                                                    prevAttempts.map((prevItem, i) =>
                                                                        i === index
                                                                            ? { ...prevItem, follow_up_date: date }
                                                                            : prevItem
                                                                    )
                                                                );
                                                            }}
                                                            selected={item.follow_up_date}
                                                            maxDate={new Date()}
                                                            className="form-control"
                                                        />
                                                    </td>
                                                    <td>
                                                        <Form.Control onChange={(e) => {
                                                            setAddedAttempts((prevAttempts) =>
                                                                prevAttempts.map((prevItem, i) =>
                                                                    i === index
                                                                        ? { ...prevItem, follow_up_reason: e.target.value }
                                                                        : prevItem
                                                                )
                                                            );
                                                        }} type="text" placeholder="Put Reason" />
                                                    </td>
                                                    <td>{item.upload_by}</td>
                                                </tr>);
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => { setIsShowHistoryModel(false); }}>
                            Close
                        </Button>
                        {
                            addedAttempts && addedAttempts.length > 0 && (
                                <Button variant="primary" onClick={() => {
                                    updateCandidateProfile(interviewIdForQcRemark, {
                                        follow_up_attempts: [
                                            ...candidateData.follow_up_attempts,
                                            ...addedAttempts
                                        ]
                                    });
                                    setIsShowHistoryModel(false)
                                    setAddedAttempts([])
                                }}>
                                    Submit
                                </Button>
                            )
                        }

                    </Modal.Footer>
                </Modal>
            }

            {
                isShowVideoModal &&
                <Modal show={isShowVideoModal} centered>
                    <Modal.Header>
                        <Modal.Title>Add Video Link</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="linkInput">
                            <Form.Label>Video Link</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Video link"
                                value={videoLink}
                                onChange={(e) => setVideoLink(e.target.value)}
                            />
                            {
                                !_.isUndefined(videoLinkError) && !_.isNull(videoLinkError) && (videoLinkError !== '') &&
                                <Alert variant="danger" className="mt-2">{videoLinkError}</Alert>
                            }
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setIsShowVideoModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={() => {
                            if (videoLink == "") {
                                setVideoLinkError("Video link can't be empty.");
                            } else {
                                var urlRegx = new RegExp('(docs.google.com|(http|https))(://[A-Za-z]+-my.sharepoint.com)?', 'i');
                                if (urlRegx.test(videoLink)) {
                                    updateCandidateProfile(editedId, { video: videoLink }, true, "Video added successfully for this interview.");
                                    setIsShowVideoModal(false);
                                } else {
                                    setVideoLinkError("Not a valid Google Drive link");
                                }
                            }
                        }}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

        </Fragment>
    )
}
export default ReportScreen;